import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomerSelect.styled';
import CustomAutoComplete from '../CustomAutoComplete';
import { MarketAccount } from 'types/api/marketAccount.types';

const CustomerSelect = (props: Props) => {
    const { account, onChange, marketAccounts, t, isLoadingMarketAccounts, errorMarketAccounts } =
        props;

    return (
        <CustomAutoComplete
            id="market-account-select"
            onChange={(e, value) => onChange(value)}
            options={marketAccounts ?? []}
            value={account ?? null}
            placeholder={t('market_account_select_placeholder') as string}
            isLoading={isLoadingMarketAccounts}
            error={errorMarketAccounts as string}
            disableClearable
            getOptionLabel={option =>
                `${option.name} ${option.last_name} - N° ${option.customerCode}`
            }
            renderOption={(props, option) => (
                <St.OptionContainer {...props} key={option.id}>
                    <St.OptionName variant="caption">
                        {option.name} {option.last_name}
                    </St.OptionName>
                    <St.OptionCustomerCode variant="body1">
                        N° {option.customerCode}
                    </St.OptionCustomerCode>
                </St.OptionContainer>
            )}
        />
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoadingMarketAccounts: PropTypes.bool.isRequired,
};

interface extraProps {
    account: MarketAccount | null;
    onChange: (value: MarketAccount) => void;
    marketAccounts: MarketAccount[] | null;
    errorMarketAccounts: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomerSelect.propTypes = propTypes;

export default CustomerSelect;
