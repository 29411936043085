import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TreasuryBankAccountsPage from './TreasuryBankAccountsPage';
import { useTranslation } from 'react-i18next';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { GetBankAccountsItem } from 'types/api/cashflow.types';
import { formatSingleDate } from 'utils/helpers/dateHelper';
import { Chip } from '@mui/material';
import { getColorByStatus } from 'utils/helpers/commonHelper';
import CustomIconCreator from 'components/common/CustomIconCreator';
import EyeIcon from 'assets/icons/eye-icon.svg?react';
import DeleteIcon from 'assets/icons/delete-bin-7-line.svg?react';
import {
    ACTIVE,
    BROKER,
    DELETED,
    EXTERIOR,
    ICON_CELL_SIZE,
    PENDING_ACCEPT,
    REJECTED,
} from 'constants/appConstants';
import CreateBankAccountModal from './CreateBankAccountModal';
import EditBankAccount from './EditBankAccount';
import BankAccountDetail from './BankAccountDetail';
import AddIcon from 'assets/icons/add-line.svg?react';
import EditIcon from 'assets/icons/pencil-line.svg?react';
import DeleteBankAccount from './DeleteBankAccount';
import { AuthContext } from 'context/auth.context';

const TreasuryBankAccountsPageContainer = (props: Props) => {
    const { t } = useTranslation();
    const { marketAccounts } = React.useContext(AuthContext);

    const columns: CommonColumn<GetBankAccountsItem>[] = [
        {
            id: 'createdAt',
            name: t('date_created'),
            selector: row => formatSingleDate(row.createdAt, 'DD-MM-YYYY'),
            sortable: true,
        },
        {
            id: 'customerCode',
            name: t('customer_code'),
            selector: row => row?.user?.marketAccounts[0].customerCode || '-',
            sortable: true,
        },
        {
            id: 'name',
            name: t('client'),
            selector: row => `${row?.user?.lastName}, ${row?.user?.name}`,
        },
        {
            id: 'type',
            name: t('type'),
            selector: row => (
                <Chip color="secondary" variant="outlined" sx={{ m: 0.625 }} label={t(row.type)} />
            ),
            sortable: true,
        },
        {
            id: 'currency',
            name: t('currency'),
            sortable: true,
        },
        {
            id: 'status',
            name: t('status'),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status)} />
            ),
        },
    ];

    const headAction: CommonAction<GetBankAccountsItem> = {
        id: 'create_bank_account',
        Icon: () => <CustomIconCreator icon={AddIcon} sx={{ ...ICON_CELL_SIZE, mt: 0.7 }} />,
        onClick: props => <CreateBankAccountModal {...props} />,
    };

    const typeToHideEditAndDelete = [EXTERIOR, BROKER];

    const actions: CommonAction<GetBankAccountsItem>[] = [
        {
            id: 'detail_bank_account',
            type: 'modal',
            Icon: () => <CustomIconCreator icon={EyeIcon} />,
            onClick: props => <BankAccountDetail {...props} />,
        },
        {
            id: 'edit_bank_account',
            type: 'modal',
            Icon: () => <CustomIconCreator icon={EditIcon} />,
            onClick: props => <EditBankAccount {...props} />,
            isHidden: row =>
                typeToHideEditAndDelete.includes(row.type) || row.status === PENDING_ACCEPT,
        },
        {
            id: 'delete_bank_account',
            type: 'modal',
            Icon: () => <CustomIconCreator icon={DeleteIcon} sx={{ color: 'error' }} />,
            onClick: props => <DeleteBankAccount {...props} />,
            isHidden: row => typeToHideEditAndDelete.includes(row.type),
        },
    ];

    const BankAccountStatusOptions = [
        { id: ACTIVE, name: t(ACTIVE) },
        { id: PENDING_ACCEPT, name: t(PENDING_ACCEPT) },
        { id: REJECTED, name: t(REJECTED) },
        { id: DELETED, name: t(DELETED) },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'status',
            label: t('status'),
            type: 'select',
            query: 'status',
            options: BankAccountStatusOptions,
        },
        {
            id: 'customerCode',
            label: t('customer_code'),
            type: 'select',
            query: 'customerCode',
            options:
                marketAccounts?.map(el => ({ id: el.customerCode, name: el.customerCode })) ?? [],
        },
        {
            id: 'client',
            label: t('client'),
            type: 'select',
            query: 'customerName',
            options: marketAccounts?.map(el => ({ id: el.name, name: el.name })) ?? [],
        },
        {
            id: 'from',
            label: t('from'),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'to',
            label: t('to'),
            type: 'date',
            query: 'dateTo',
        },
    ];

    const childProps = {
        ...props,
        columns,
        filters,
        actions,
        headAction,
        t,
    };

    return <TreasuryBankAccountsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TreasuryBankAccountsPageContainer.propTypes = propTypes;

export default TreasuryBankAccountsPageContainer;
