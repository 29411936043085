/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import GeneralMarketSearchBar from './GeneralMarketSearchBar';
import { TickerInfo } from 'types/api/instruments.types';
import { useTranslation } from 'react-i18next';
import { mainRoutesPaths } from 'router/routesPaths';
import { useGetInstruments } from 'hooks/api/instruments.hooks';
import { Currency } from 'types/common/general.types';

const GeneralMarketSearchBarContainer = (props: Props) => {
    const { currency = 'ARS' } = props;
    const { t } = useTranslation('market');
    const [searchBar, setSearchBar] = React.useState('');
    const [openSearchBar, setOpenSearchBar] = React.useState(false);

    const { data: searchBarData, isLoading: isLoadingSearchBar } = useGetInstruments(
        `&page=1&pageSize=20&q=${searchBar}&currency=${currency}`,
        searchBar.length > 2,
    );

    const redirectToTickerDetails = (tickers: TickerInfo[], type: string) => {
        localStorage.setItem('term', '24hs');
        window.location.replace(
            `${(type === 'FUND' ? mainRoutesPaths.fundDetail : mainRoutesPaths.instrumentDetail).replace(':ticker', `${tickers.find(el => el.currency === currency)?.ticker}`)}`,
        );
    };

    const boxRef = React.useRef<HTMLDivElement>(null);
    const closeSearchBarList = () => setOpenSearchBar(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
            closeSearchBarList();
        }
    };

    const handleOpenListIfRecordsExist = () => {
        if (searchBarData && !openSearchBar) return setOpenSearchBar(true);
    };

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    React.useEffect(() => {
        if (searchBar.length > 2) setOpenSearchBar(true);
    }, [searchBar]);

    const childProps = {
        ...props,
        boxRef,
        handleOpenListIfRecordsExist,
        redirectToTickerDetails,
        searchBar,
        setSearchBar,
        searchBarData,
        isLoadingSearchBar,
        openSearchBar,
        currency,
        t,
    };

    return <GeneralMarketSearchBar {...childProps} />;
};

const propTypes = {};

interface extraProps {
    currency?: Currency;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}

GeneralMarketSearchBarContainer.propTypes = propTypes;

export default GeneralMarketSearchBarContainer;
