import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentModal from './InstrumentModal';
import { useNavigate } from 'react-router-dom';

const InstrumentModalContainer = (props: Props) => {
    const { t, setOpenModal, selectedInstrument } = props;
    const navigate = useNavigate();

    const [term, setTerm] = React.useState({ id: 1, name: '24hs' });
    const [currency, setCurrency] = React.useState({ id: 1, name: 'ARS' });

    const [showUSDFields, setShowUSDFields] = React.useState(false);

    const handleClose = React.useCallback(() => {
        setShowUSDFields(false);
        setOpenModal(false);
    }, [setOpenModal]);

    const handleTermChange = (event: React.SyntheticEvent, newValue: any) => {
        setTerm(newValue);
    };

    const handleCurrencyChange = (event: React.SyntheticEvent, newValue: any) => {
        setCurrency(newValue);
    };

    const handleUSDButtonClick = () => {
        setShowUSDFields(prevShowUSDFields => !prevShowUSDFields);
    };

    const handleInstrumentViewSheet = () => {
        localStorage.setItem('term', term.name);
        navigate(
            `/market/${selectedInstrument?.type == 'FUND' ? 'fund' : 'instrument'}/${selectedInstrument?.ticker}`,
        );
    };

    const childProps = {
        ...props,
        t,
        handleClose,
        term,
        currency,
        handleTermChange,
        handleCurrencyChange,
        showUSDFields,
        handleUSDButtonClick,
        handleInstrumentViewSheet,
    };

    return <InstrumentModal {...childProps} />;
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    openModal: boolean;
    setOpenModal: (state: boolean) => void;
    selectedInstrument: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentModalContainer.propTypes = propTypes;

export default InstrumentModalContainer;
