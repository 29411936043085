import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './HomePage.styled';
import AccountStatus from 'components/common/AccountStatus';
import LearnToInvestCards from 'components/common/LearnToInvestCards';
import CurrencyQuotes from '../../common/CurrencyQuotes';
import RecommendedWallet from './RecommendedWallet';
import { Grid } from '@mui/material';
import HomeInvestmentProfile from './HomeInvestmentProfile';
import { pxToRem } from 'utils/helpers/stylesHelper';
import DdjjAction from './DDJJAction';

const HomePage = (props: Props) => {
    const { recommendedWalletRef } = props;
    return (
        <St.SectionWrapper>
            <DdjjAction />
            <AccountStatus />
            <CurrencyQuotes />
            <LearnToInvestCards />
            <Grid container gap={pxToRem(80)}>
                <Grid item xs={12} lg={4}>
                    <HomeInvestmentProfile />
                </Grid>
                <Grid item xs={12} lg={7}>
                    <div ref={recommendedWalletRef}>
                        <RecommendedWallet />
                    </div>
                </Grid>
            </Grid>
        </St.SectionWrapper>
    );
};

const propTypes = {};

interface extraProps {
    recommendedWalletRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HomePage.propTypes = propTypes;

export default HomePage;
