import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ReportsPage.styled';
import PageTitle from 'components/common/PageTitle';

const ReportsPage = (props: Props) => {
    const { t } = props;
    return (
        <>
            <St.SectionWrapper component={'section'} data-testid="ReportsPage-page">
                <PageTitle>{t('page_empty_header')}</PageTitle>
                <PageTitle>{t('page_empty_title')}</PageTitle>
                <St.ArticleWrapper
                    component={'article'}
                    sx={{ padding: { xs: '0', md: '1.25rem 2rem 1rem 0' } }}>
                    <St.ParagraphWrapper>{t('page_empty_description')}</St.ParagraphWrapper>
                </St.ArticleWrapper>
            </St.SectionWrapper>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsPage.propTypes = propTypes;
ReportsPage;

export default ReportsPage;
