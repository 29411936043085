import { Container, Paper, styled, Typography } from '@mui/material';

export default {
    StyledContainer: styled(Container)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: theme.spacing(4),
    })),
    StyledPaper: styled(Paper)(({ theme }) => ({
        padding: theme.spacing(4),
        width: '100%',
        maxWidth: 800,
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
        },
        boxShadow: 'none',
        backgroundColor: 'transparent',
    })),
    Title: styled(Typography)(({ theme }) => ({
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontSize: '68px',
        marginBottom: theme.spacing(4),
        color: theme.palette.primary.main,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '48px',
        },
    })),
};
