import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './Dropdown.styled';

const Dropdown = (props: Props) => {
    const { options, selectedValue, onSelect, label, minWidth, disableClearable } = props;
    return (
        <St.CustomAutoCompleteWrapper $minWidth={minWidth}>
            <St.CustomAutoComplete
                id={label}
                value={selectedValue}
                onChange={(e, value) => onSelect(value)}
                options={options ?? []}
                isLoading={false}
                label={label}
                disableClearable={disableClearable}
                renderOption={(props, option) => (
                    <St.NameWrapper {...props} key={option.id}>
                        {option.name}
                    </St.NameWrapper>
                )}
            />
        </St.CustomAutoCompleteWrapper>
    );
};

const propTypes = {};

interface extraProps {
    options: Array<{ id: string; name: string }>;
    selectedValue: { id: string; name: string } | null;
    onSelect: (value: { id: string; name: string }) => void;
    label: string;
    minWidth: string;
    disableClearable?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
Dropdown.propTypes = propTypes;

export default Dropdown;
