import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './AccountInformation.styled';
import CurrencyLabel from 'components/common/CurrencyLabel';
import {
    AccountPerformance,
    AvailableBalance,
    MultiCurrencyObj,
} from 'types/api/marketAccount.types';
import CustomRadioButton from 'components/common/CustomRadioButton';
import { Theme } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import { tCommon } from 'constants/appConstants';
import AumDetailModal from './AumDetailModal';
import AvailableBalanceDetailModal from './AvailableBalanceDetailModal';
import { NavigateFunction } from 'react-router-dom';
import { channelRoutesPaths, mainRoutesPaths } from 'router/routesPaths';
import CustomIconCreator from 'components/common/CustomIconCreator';
import PerformanceIcon from 'assets/icons/rentabilidad.svg?react';
import MoneyIcon from 'assets/icons/money.svg?react';
const AccountInformation = (props: Props) => {
    const {
        t,
        theme,
        isWallet,
        detailedAum,
        performance,
        aumCurrency,
        visualizeUSD,
        setVisualizeUSD,
        availableBalance,
        openAumDetailModal,
        performanceCurrency,
        setOpenAumDetailModal,
        openAvailableBalanceDetailModal,
        setOpenAvailableBalanceDetailModal,
        navigate,
        isUserApAc,
    } = props;

    return (
        <St.Container>
            <St.WalletSubtitle>{t('account_information_wallet_subtitle')}</St.WalletSubtitle>
            <St.RadioButtonContainer>
                <CustomRadioButton
                    checked={visualizeUSD}
                    name={t('visualize_usd')}
                    onChange={() => setVisualizeUSD(!visualizeUSD)}
                    text={t('visualize_usd')}
                    value={String(visualizeUSD)}
                />
            </St.RadioButtonContainer>
            <CurrencyLabel
                variant="h3"
                currency={visualizeUSD ? 'USD' : 'ARS'}
                value={aumCurrency}
            />
            <St.AumText typography="h6">{t('aum_text')}</St.AumText>
            {isWallet && (
                <St.ModalTextContainer onClick={() => setOpenAumDetailModal(true)}>
                    <St.InfoIcon />
                    <St.ModalText variant="caption">{t('see_aum_detailed_text')}</St.ModalText>
                </St.ModalTextContainer>
            )}
            <St.DottedDivider />
            {isUserApAc ? null : (
                <St.PerformanceContainer>
                    <St.AccountInformationSubtitle variant="subtitle1">
                        {t('account_information_performance_title')}
                    </St.AccountInformationSubtitle>
                    <St.PerformanceLabelContainer>
                        <CustomIconCreator
                            icon={PerformanceIcon}
                            sx={{
                                width: '2rem',
                                height: '2rem',
                                marginRight: '0.8rem',
                                overflow: 'visible',
                            }}
                        />
                        <CurrencyLabel
                            currency={visualizeUSD ? 'USD' : 'ARS'}
                            value={performanceCurrency}
                        />
                        {!!performance?.percentage && (
                            <St.PerformancePercentage
                                color={
                                    performance?.percentage >= 0
                                        ? theme.palette.custom.lightGreen
                                        : theme.palette.custom.lightRed
                                }>
                                {performance?.percentage.toFixed(2)}%
                            </St.PerformancePercentage>
                        )}
                    </St.PerformanceLabelContainer>
                </St.PerformanceContainer>
            )}
            {isWallet && (
                <St.AvailableBalanceContainer>
                    <St.AccountInformationSubtitle variant="subtitle1">
                        {t('account_information_withdraw_title')}
                    </St.AccountInformationSubtitle>
                    <St.MoneyLabelContainer>
                        <CustomIconCreator
                            icon={MoneyIcon}
                            sx={{
                                width: '1.7rem',
                                height: '1.7rem',
                                marginRight: '0.8rem',
                                overflow: 'visible',
                            }}
                        />
                        <CurrencyLabel currency="ARS" value={availableBalance?.withdraw.ars ?? 0} />
                    </St.MoneyLabelContainer>
                    <St.MoneyLabelContainer>
                        <CustomIconCreator
                            icon={MoneyIcon}
                            sx={{
                                width: '1.7rem',
                                height: '1.7rem',
                                marginRight: '0.8rem',
                                overflow: 'visible',
                            }}
                        />
                        <CurrencyLabel currency="USD" value={availableBalance?.withdraw.usd ?? 0} />
                    </St.MoneyLabelContainer>
                    <St.MoneyLabelContainer>
                        {(!availableBalance || availableBalance?.withdraw.usdc > 0) && (
                            <>
                                <CustomIconCreator
                                    icon={MoneyIcon}
                                    sx={{
                                        width: '1.7rem',
                                        height: '1.7rem',
                                        marginRight: '0.8rem',
                                        overflow: 'visible',
                                    }}
                                />
                                <CurrencyLabel
                                    currency="USD-C"
                                    value={availableBalance?.withdraw.usdc ?? 0}
                                />
                            </>
                        )}
                    </St.MoneyLabelContainer>
                </St.AvailableBalanceContainer>
            )}
            <St.AvailableBalanceContainer>
                <St.AccountInformationSubtitle variant="subtitle1">
                    {t('account_information_available_balance_title')}
                </St.AccountInformationSubtitle>
                <St.MoneyLabelContainer>
                    <CustomIconCreator
                        icon={MoneyIcon}
                        sx={{
                            width: '1.7rem',
                            height: '1.7rem',
                            marginRight: '0.8rem',
                            overflow: 'visible',
                        }}
                    />
                    <CurrencyLabel currency="ARS" value={availableBalance?.operate.t1.ars ?? 0} />
                </St.MoneyLabelContainer>
                <St.MoneyLabelContainer>
                    <CustomIconCreator
                        icon={MoneyIcon}
                        sx={{
                            width: '1.7rem',
                            height: '1.7rem',
                            marginRight: '0.8rem',
                            overflow: 'visible',
                        }}
                    />
                    <CurrencyLabel currency="USD" value={availableBalance?.operate.t1.usd ?? 0} />
                </St.MoneyLabelContainer>
                <St.MoneyLabelContainer>
                    {(!availableBalance || availableBalance?.operate.t1.usdc > 0) && (
                        <>
                            <CustomIconCreator
                                icon={MoneyIcon}
                                sx={{
                                    width: '1.7rem',
                                    height: '1.7rem',
                                    marginRight: '0.8rem',
                                    overflow: 'visible',
                                }}
                            />
                            <CurrencyLabel
                                currency="USD-C"
                                value={availableBalance?.operate.t1.usdc ?? 0}
                            />
                        </>
                    )}
                </St.MoneyLabelContainer>
            </St.AvailableBalanceContainer>
            {isWallet && (
                <>
                    <St.ModalTextContainer onClick={() => setOpenAvailableBalanceDetailModal(true)}>
                        <St.InfoIcon />
                        <St.ModalText variant="caption">
                            {t('see_available_detailed_text')}
                        </St.ModalText>
                    </St.ModalTextContainer>

                    <St.FooterButtons>
                        <ActionButton
                            variant="contained"
                            sx={{
                                backgroundColor: 'white',
                                color: 'primary.main',
                                ':hover': {
                                    color: 'white',
                                },
                            }}
                            onClick={() =>
                                isUserApAc
                                    ? navigate(channelRoutesPaths.treasuryOrders, {
                                          state: { tab: 'deposits' },
                                      })
                                    : navigate(mainRoutesPaths.deposit)
                            }>
                            {t('deposit', tCommon).toUpperCase()}
                        </ActionButton>
                        <ActionButton
                            variant="contained"
                            onClick={() =>
                                isUserApAc
                                    ? navigate(channelRoutesPaths.treasuryOrders)
                                    : navigate(mainRoutesPaths.withdrawal)
                            }>
                            {t('extract', tCommon).toUpperCase()}
                        </ActionButton>
                    </St.FooterButtons>
                    <AumDetailModal
                        aum={detailedAum}
                        open={openAumDetailModal}
                        close={() => setOpenAumDetailModal(false)}
                    />
                    <AvailableBalanceDetailModal
                        open={openAvailableBalanceDetailModal}
                        close={() => setOpenAvailableBalanceDetailModal(false)}
                        availableBalance={availableBalance}
                    />
                </>
            )}
        </St.Container>
    );
};

const propTypes = {
    isWallet: PropTypes.bool,
    t: PropTypes.func.isRequired,
    visualizeUSD: PropTypes.bool.isRequired,
    openAumDetailModal: PropTypes.bool.isRequired,
    openAvailableBalanceDetailModal: PropTypes.bool.isRequired,
    aumCurrency: PropTypes.number.isRequired,
    performanceCurrency: PropTypes.number.isRequired,
    openDetailModal: PropTypes.bool.isRequired,
    isUserApAc: PropTypes.bool.isRequired,
};

interface extraProps {
    theme: Theme;
    detailedAum?: MultiCurrencyObj;
    performance?: AccountPerformance;
    availableBalance?: AvailableBalance;
    setVisualizeUSD: (value: boolean) => void;
    setOpenAumDetailModal: (value: boolean) => void;
    setOpenAvailableBalanceDetailModal: (value: boolean) => void;
    setOpenDetailModal: (value: boolean) => void;
    navigate: NavigateFunction;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AccountInformation.propTypes = propTypes;

export default AccountInformation;
