import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomCarousel from './CustomCarousel';
import useBreakpoints from 'hooks/common/breakpoints.hooks';

const CustomCarouselContainer = (props: Props) => {
    // const {} = props;
    const { matchesMd } = useBreakpoints();

    const childProps = {
        ...props,
        matchesMd,
    };

    return <CustomCarousel {...childProps} />;
};

const propTypes = {
    width: PropTypes.string,
};

interface extraProps {
    children: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomCarouselContainer.propTypes = propTypes;

export default CustomCarouselContainer;
