import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingComplementaryDataPage from './OnboardingComplementaryDataPage';
import * as Yup from 'yup';
import { tErrorsContext, tRequiredFieldError } from 'constants/appConstants';
import React from 'react';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    useComplementaryDataPage,
    useComplementaryDataPost,
    useGetNationalities,
    useGetWorkActivities,
} from 'hooks/api/onboarding.hooks';
import { ComplementaryDataBody } from 'types/api/onboarding.types';
import i18n from 'utils/i18n';
import { useNavigate } from 'react-router-dom';
// ---------------------------------------------//
// ------------------- FORMIK ------------------//
// ---------------------------------------------//
const getInitialValues = () => ({
    PEP: false,
    isUIF: false,
    isUSA: false,
    OCDE: false,
    pepCharge: '',
    ssn: '',
    countryTaxResidence: null,
    taxCondition: null,
    earningsRegistration: null, // hay que validar si queda como string o bool
    activityId: null,
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            PEP: Yup.boolean(),
            pepCharge: Yup.string().when('PEP', {
                is: true,
                then: Yup.string().required(tRequiredFieldError),
                otherwise: Yup.string(),
            }),
            ssn: Yup.string().when('isUSA', {
                is: true,
                then: Yup.string()
                    .required(tRequiredFieldError)
                    .matches(/^\d{9}$/, i18n.t('ssn_error_message', tErrorsContext) as string),
                otherwise: Yup.string(),
            }),
            activityId: Yup.object().required(tRequiredFieldError),
            earningsRegistration: Yup.object().required(tRequiredFieldError),
        }),
    );
// ---------------------------------------------//
// ---------------------------------------------//
// ---------------------------------------------//

export const OnboardingComplementaryDataPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('onboarding');
    const navigate = useNavigate();
    const { itemsLi, VATConditionArticles, incomeRegistrationArticles } =
        useComplementaryDataPage(t);
    const { data: countriesList } = useGetNationalities();
    const { data: workActivities } = useGetWorkActivities();
    const { mutate, isLoading: isLoadingSubmit, errorMessage } = useComplementaryDataPost();

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const { OCDE, PEP, pepCharge, countryTaxResidence } = values;
            const formData = {
                ...values,
                customerType: `Persona Fisica ${
                    OCDE && countryTaxResidence.id === 7 ? 'Local' : 'Extranjera'
                }`,
                ssn: values.isUSA ? values.ssn : '',
                pepCharge: PEP ? pepCharge : '',
                countryTaxResidence: OCDE ? countryTaxResidence.name : '',
                taxCondition: values.taxCondition.name,
                earningsRegistration: values.earningsRegistration.name,
                activityId: values.activityId.id,
            } as ComplementaryDataBody;
            const config = {
                onSuccess: () => {
                    navigate('/');
                },
            };
            mutate(formData, config);
        },
        [mutate, navigate],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const handleDownload = () => {
        const fileUrl = '/assets/activities_detail.xlsx';
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = t('complementary_data_activities_detail');
        link.click();
    };

    const formik = useFormik(formikInitProps);
    const { values } = formik;
    const { activityId, taxCondition, earningsRegistration, OCDE, countryTaxResidence } = values;

    const childProps = {
        t,
        formik,
        enableButton:
            activityId &&
            taxCondition &&
            earningsRegistration &&
            (OCDE ? !!countryTaxResidence : true),
        itemsLi,
        VATConditionArticles,
        incomeRegistrationArticles,
        workActivities,
        countriesList,
        handleDownload,
        isLoadingSubmit,
        errorMessage,
    };
    return <OnboardingComplementaryDataPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingComplementaryDataPageContainer.propTypes = propTypes;

export default OnboardingComplementaryDataPageContainer;
