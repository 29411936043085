import React from 'react';
import { OnboardingContext } from 'context/onboarding.context';
import { useTranslation } from 'react-i18next';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingContactInfoPage from './OnboardingContactInfoPage';

export const OnboardingContactInfoContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('onboarding');
    const [phoneNumber, setPhoneNumber] = React.useState<string | null>();
    const { isLoadingLegalData, errorMessageLegalData } = React.useContext(OnboardingContext);

    const childProps = {
        t,
        isLoadingLegalData,
        errorMessageLegalData,
        phoneNumber,
        setPhoneNumber,
    };
    return <OnboardingContactInfoPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContactInfoContainer.propTypes = propTypes;

export default OnboardingContactInfoContainer;
