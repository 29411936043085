import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './BankAccountCard.styled';
import { Typography } from '@mui/material';
import DeleteIcon from '../../../../assets/icons/delete-icon.svg?react';
import EditIcon from '../../../../assets/icons/edit-icon.svg?react';
import {
    BankAccountStatusOptions,
    BankAccountTypeOptions,
    GetBankAccountsItem,
} from 'types/api/cashflow.types';
import { PENDING, PENDING_DELETE } from 'constants/appConstants';

const BankAccountCard = (props: Props) => {
    const {
        t,
        statusOptions,
        account,
        accountTypeOptions,
        setSelectedAccount,
        setIsEdit,
        setOpenDeleteModal,
        open,
    } = props;

    return (
        <St.CardContainer>
            <St.ContainerInfo>
                <Typography variant="h6" color="#307FDD">
                    {t('bank_account_nick_name_title')}: {account.description}
                </Typography>
                <Typography variant="h6" color="#307FDD">
                    {account.bankName}
                </Typography>
                <Typography variant="body2" color="#2e4665">
                    {account.currency === 'USD' ? 'US$ - Dólares' : 'AR$ - Pesos'}
                </Typography>
                <Typography variant="body2" color="#2e4665">
                    {accountTypeOptions[account.type]}
                </Typography>
                <Typography variant="body2" color="#2e4665">
                    {t('bank_account_account_number_title')}: {account.accountNumber}
                </Typography>
                <Typography
                    variant="body2"
                    color={statusOptions[account.status].color}
                    fontWeight={'bold'}>
                    {statusOptions[account.status].text}{' '}
                    {account.reason ? `${t('bank_account_for_reason')} ${account.reason}` : ''}
                </Typography>
            </St.ContainerInfo>
            <St.ContainerButtons>
                {account.status !== PENDING_DELETE && (
                    <>
                        <St.CustomIconButton
                            title={t('bank_account_edit')}
                            onClick={() => {
                                setIsEdit(true);
                                setSelectedAccount(account);
                                open();
                            }}>
                            <EditIcon fontSize={'15px'} color="#307FDD" />
                        </St.CustomIconButton>

                        <St.CustomIconButton
                            title={t('bank_account_delete_button')}
                            onClick={() => {
                                setSelectedAccount(account);
                                setOpenDeleteModal(true);
                            }}>
                            <DeleteIcon fontSize={'15px'} color="#307FDD" />
                        </St.CustomIconButton>
                    </>
                )}
            </St.ContainerButtons>
        </St.CardContainer>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    statusOptions: BankAccountStatusOptions;
    accountTypeOptions: BankAccountTypeOptions;
    account: GetBankAccountsItem;
    setSelectedAccount: React.Dispatch<React.SetStateAction<GetBankAccountsItem | null>>;
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
    open(): void;
    setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountCard.propTypes = propTypes;

export default BankAccountCard;
