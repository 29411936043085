import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomPieChart from './CustomPieChart';
import { ApexOptions } from 'apexcharts';
import { CHART_MAIN_COLORS } from 'constants/color.constants';
import { formatNumber } from 'utils/helpers/commonHelper';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import useBreakpoints from 'hooks/common/breakpoints.hooks';
import './CustomPieChart.css';

const CustomPieChartContainer = (props: Props) => {
    const { labels, total, extraOptions, colors, setInstrumentType, id = 'big-chart' } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const { matchesMd } = useBreakpoints();

    const options: ApexOptions = {
        labels,
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 0,
        },
        legend: {
            show: true,
            fontSize: '16px',
            formatter: function (val, opts) {
                return `<div class="legend-text">
                    <span class="legend-title">${val}</span>
                    <span class="legend-value">${opts.w.globals.series[opts.seriesIndex].toFixed(2)}%</span></div>`;
            },
            position: matchesMd ? 'bottom' : 'right',
        },
        colors: colors ?? CHART_MAIN_COLORS,
        plotOptions: {
            pie: {
                donut: {
                    size: '60%',
                    labels: {
                        show: !!total,
                        name: {
                            show: true,
                            fontWeight: '400',
                            offsetY: 30,
                        },
                        value: {
                            show: true,
                            fontWeight: '400',
                            fontSize: '32px',
                            color: theme.palette.primary.main,
                            formatter: function (opts: any) {
                                return `${Number(opts).toFixed(2)}%`;
                            },
                            offsetY: -20,
                        },
                        total: {
                            show: !!total,
                            color: theme.palette.primary.main,
                            fontWeight: '400',
                            fontSize: '20px',
                            label: t('total') as string,
                            formatter: function (w) {
                                if (total) return `${total}`;
                                return `${formatNumber(
                                    w.globals.seriesTotals.reduce(
                                        (a: number, b: number) => a + b,
                                        0,
                                    ),
                                    0,
                                    false,
                                )}%`;
                            },
                        },
                    },
                },
            },
        },
        chart: {
            events: {
                dataPointSelection: function (event, chartContext, config) {
                    const selectedLabel = labels[config.dataPointIndex];
                    const normalizedLabel = selectedLabel.toUpperCase();
                    const mappedValues: Record<string, string> = {
                        DIVISAS: 'CURRENCY',
                        ACCIONES: 'EQUITY',
                        BONOS: 'BOND',
                        'BONOS EN EL EXTERIOR': 'EXTERIOR_BOND',
                        'FONDOS EN EL EXTERIOR': 'EXTERIOR_FUND',
                        'OBLIGACIONES NEGOCIABLES EXTERIORES': 'EXTERIOR_ON',
                        EXTERIOR: 'EXTERIOR',
                        CEDEARS: 'CERTIFICATE',
                        FONDOS: 'FUND',
                        LETRAS: 'LETTER',
                        ADRS: 'ADR',
                        OTROS: 'OTHER',
                    };
                    if (setInstrumentType) {
                        setInstrumentType((prev: string) =>
                            prev === mappedValues[normalizedLabel]
                                ? ''
                                : mappedValues[normalizedLabel],
                        );
                    }
                },
            },
        },
        ...extraOptions,
    };

    const childProps = {
        ...props,
        options,
        matchesMd,
        id,
    };

    return <CustomPieChart {...childProps} />;
};

const propTypes = {
    total: PropTypes.string,
    id: PropTypes.string.isRequired,
};

interface extraProps {
    series: number[];
    labels: string[];
    colors?: string[];
    width?: string | number;
    extraOptions?: ApexOptions;
    height?: string | number;
    setInstrumentType?: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomPieChartContainer.propTypes = propTypes;

export default CustomPieChartContainer;
