import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ProfilePreferencesPage from './ProfilePreferencesPage';
import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth.context';
import {
    useGetPreferences,
    useRequestOtp,
    useDisableMfaOtp,
    useHandlePreferences,
} from 'hooks/api/auth.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';

const ProfilePreferencesPageContainer = (props: Props) => {
    // const {} = props;
    const [open, setOpen] = React.useState('');
    const handleClose = () => {
        setOpen('');
    };
    const { userData } = React.useContext(AuthContext);
    const { data: preferences } = useGetPreferences();
    const { t } = useTranslation();
    const { SnackBar, setSnackBarMessage } = useSnackBar();

    const { mutate: senEmailtoDisableMfa } = useRequestOtp();
    const {
        mutate: disableMfa,
        error: errorDisable,
        isLoading: isLoadingDisable,
    } = useDisableMfaOtp(setSnackBarMessage);
    const { mutate: handlePreferences } = useHandlePreferences(
        setSnackBarMessage,
        preferences?.pushNotifications ? 'Desactivaste' : 'Activaste',
    );
    const handleClick = (name: string, value: boolean) => {
        switch (name) {
            case 'mfa':
                if (userData?.mfaType === 'DISABLED' && value === true) {
                    setOpen('enableMfa');
                } else if (userData?.mfaType !== 'DISABLED' && value === false) {
                    setOpen('disableMfa');
                }
                break;
            case 'notifications':
                if (!preferences?.pushNotifications && value === true) {
                    handlePreferences({
                        emailNotifications: preferences?.emailNotifications,
                        pushNotifications: true,
                    });
                } else if (preferences?.pushNotifications && value === false) {
                    handlePreferences({
                        emailNotifications: preferences?.emailNotifications,
                        pushNotifications: false,
                    });
                }
                break;
            default:
                break;
        }
    };

    const functionUseEffectDisable = () => {
        senEmailtoDisableMfa(
            {},
            {
                onSuccess: () => {
                    setSnackBarMessage(t('send_email_mfa_success'), 'success');
                },
                onError: () => {
                    setSnackBarMessage(t('send_email_mfa_error'), 'error');
                },
            },
        );
    };
    const sendCodeDisableMfa = (otp: string) => {
        disableMfa({ otp });
    };

    const childProps = {
        ...props,
        t,
        handleClick,
        mfaType: userData?.mfaType ?? null,
        pushNotifications: preferences?.pushNotifications ?? null,
        open,
        handleClose,
        functionUseEffectDisable,
        sendCodeDisableMfa,
        SnackBar,
        errorDisable,
        isLoadingDisable,
        setSnackBarMessage,
    };

    return <ProfilePreferencesPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ProfilePreferencesPageContainer.propTypes = propTypes;

export default ProfilePreferencesPageContainer;
