import endpoints from 'api/cashflow/cashflow.api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';
import { useCommonGrid } from 'context/common/commonGrid.context';

export const useGetBankAccounts = ({ filterQueryString }: GridHookParams) => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['get-bank-accounts', filterQueryString],
        () => endpoints.getBankAccounts(filterQueryString),
        {
            select: data => data.data,
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        errorMessage: error ? errorResponseHelper(error) : null,
        refetch,
    };
};

export const useCreateBankAccount = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { setNeedRefresh } = useCommonGrid();
    const { t } = useTranslation('profile');
    const { mutate: createBankAccount, isLoading: createIsLoading } = useMutation(
        endpoints.createBankAccount,
        {
            onSuccess: () => {
                setSnackBarMessage(t('bank_account_create_success'));
                close();
                setNeedRefresh(true);
            },
            onError: (error: any) => {
                setSnackBarMessage(errorResponseHelper(error as any), 'error');
            },
        },
    );
    return {
        createBankAccount,
        createIsLoading,
    };
};
export const useUpdateBankAccount = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { setNeedRefresh } = useCommonGrid();
    const { t } = useTranslation('profile');

    const { mutate: updateBankAccount, isLoading: updateIsLoading } = useMutation(
        endpoints.updateBankAccount,
        {
            onSuccess: () => {
                setSnackBarMessage(t('bank_account_edit_success'));
                close();
                setNeedRefresh(true);
            },
            onError: error => {
                setSnackBarMessage(errorResponseHelper(error as any), 'error');
                close();
            },
        },
    );
    return {
        updateBankAccount,
        updateIsLoading,
    };
};

export const useDeleteBankAccount = (
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void,
    close: () => void,
) => {
    const { setNeedRefresh } = useCommonGrid();
    const { t } = useTranslation('profile');

    const { mutate, isLoading } = useMutation(endpoints.deleteBankAccounts, {
        onSuccess: () => {
            setSnackBarMessage(t('bank_account_delete_success'), 'success');
            setNeedRefresh(true);
            close();
        },
        onError: error => {
            setSnackBarMessage(errorResponseHelper(error));
            close();
        },
    });
    return {
        mutate,
        isLoading,
    };
};
export const useWithdrawal = (
    setSnackBarMessage?: (msj: string, sever?: AlertColor) => void,
    close?: () => void,
) => {
    const { t } = useTranslation();
    const { setNeedRefresh } = useCommonGrid();

    const {
        mutate: withdrawal,
        isLoading: isLoadingWithdrawal,
        error,
    } = useMutation(endpoints.createWithdrawal, {
        onSuccess: () => {
            setSnackBarMessage && setSnackBarMessage(t('create_order_success'));
            setNeedRefresh(true);
            close && close();
        },
    });
    return {
        withdrawal,
        isLoadingWithdrawal,
        error: error ? errorResponseHelper(error as any) : null,
    };
};

export const useCreateDeposit = () => {
    const { t } = useTranslation('errors');
    const { mutate: createDeposit, isLoading: createIsLoading } = useMutation(
        endpoints.createDeposit,
    );
    return {
        createDeposit,
        createIsLoading,
    };
};

export const useVerifyCbvu = () => {
    const { mutate, isLoading, error } = useMutation(endpoints.verifyCbvu);
    return {
        mutate,
        isLoading,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetWithdrawals = ({ filterQueryString }: GridHookParams) => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['get-withdrawals', filterQueryString],
        () => endpoints.getWithdrawals(filterQueryString),
        {
            select: data => data.data,
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};
export const useGetDeposits = ({ filterQueryString }: GridHookParams) => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['get-deposit', filterQueryString],
        () => endpoints.getDeposits(filterQueryString),
        {
            select: data => data.data,
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};
