import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box, IconButton, TableCell, Tooltip } from '@mui/material';
import CommonModal from '../CommonModal';
import { ICommonActions } from 'types/common/tableLayout.types';

const CommonTableActions = ({ row, actions, t }: Props) => (
    <TableCell align="center">
        <Box display="flex" flexDirection="row" alignItems="center">
            {actions.map(({ id, type, width, Icon, isHidden, onClick }) => {
                if (isHidden && isHidden(row)) return <></>;

                return (
                    <Fragment key={id}>
                        {type === 'modal' ? (
                            <CommonModal
                                id={id}
                                component={onClick}
                                row={row}
                                width={width}
                                Icon={Icon}
                            />
                        ) : (
                            <Tooltip key={id} title={t(id)}>
                                <IconButton onClick={() => onClick({ row } as any)}>
                                    {<Icon />}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Fragment>
                );
            })}
        </Box>
    </TableCell>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps extends ICommonActions {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonTableActions.propTypes = propTypes;

export default CommonTableActions;
