import { Box, styled, Typography } from '@mui/material';
import { Form } from 'formik';
import { LoadingButton } from '@mui/lab';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import ActionButton from 'components/common/ActionButton';
import CustomRadioButton from 'components/common/CustomRadioButton';
import CustomInput from 'components/common/CustomInput';
import CustomDatePicker from 'components/common/CustomDatePicker';
import CurrencyInput from 'components/common/CurrencyInput';

export default {
    Form: styled(Form)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        width: '100%',
        marginTop: '1rem',
    })) as typeof Box,
    CustomAutoComplete: styled(CustomAutoComplete)(() => ({})),
    NameWrapper: styled(Box)(() => ({})),
    BoxWrapper: styled(Box)(() => ({ display: 'flex', flexDirection: 'column', gap: '.4rem' })),
    Typography: styled(Typography)(() => ({ marginLeft: '.5rem' })),
    CurrencyInput: styled(CurrencyInput)(() => ({})),
    CustomDatePicker: styled(CustomDatePicker)(() => ({})),
    CustomInput: styled(CustomInput)(() => ({})),
    CustomRadioButtonWrapper: styled(Box)(() => ({
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
    })),
    CustomRadioButton: styled(CustomRadioButton)(() => ({})),
    ButtonsContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '.5rem', sm: '1rem' },
            button: { width: '100%', marginTop: 0 },
        }),
    ),
    ActionButton: styled(ActionButton)(() => ({})),
    LoadingButton: styled(LoadingButton)(({ theme }) => ({})) as typeof LoadingButton,
};
