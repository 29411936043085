import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomerSelect from './CustomerSelect';
import { AuthContext } from 'context/auth.context';
import { useTranslation } from 'react-i18next';
import { MarketAccount } from 'types/api/marketAccount.types';

const CustomerSelectContainer = (props: Props) => {
    // const {} = props;
    const {
        setSelectedCustomer,
        selectedCustomer,
        marketAccounts,
        isLoadingMarketAccounts,
        errorMarketAccounts,
    } = React.useContext(AuthContext);
    const { t } = useTranslation();
    const [account, setAccount] = React.useState<MarketAccount | null>(selectedCustomer);

    const onChange = (value: MarketAccount) => setSelectedCustomer(value);

    React.useEffect(() => {
        setAccount(selectedCustomer);
    }, [selectedCustomer]);

    const childProps = {
        ...props,
        t,
        account,
        onChange,
        marketAccounts,
        isLoadingMarketAccounts,
        errorMarketAccounts,
    };

    return <CustomerSelect {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomerSelectContainer.propTypes = propTypes;

export default CustomerSelectContainer;
