import { tErrorsContext } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import endpoints from 'api/wrapper/wrapper.api';

export const useGetBlogs = () => {
    const { t } = useTranslation();
    const { data, isLoading, error } = useQuery(['getBlogs'], () => endpoints.getBlogs(), {
        select: data => data.data,
    });
    return {
        data,
        isLoading,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const useGetLatestBlogs = () => {
    const { t } = useTranslation();
    const { data, isLoading, error } = useQuery(
        ['getLatestBlogs'],
        () => endpoints.getLatestBlogs(),
        {
            select: data => data.data,
        },
    );

    return {
        data,
        isLoading,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};
