import { Box, styled, Typography } from '@mui/material';

export default {
    WalletInstrumentWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'relative',
            width: '100%',
        }),
    ),
    WalletInstrumentContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            padding: '3.5rem',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                padding: '1rem',
                borderRadius: 0,
                overflow: 'auto',
            },
        }),
    ),
    DetailContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        }),
    ),
    Subtitle: styled(Typography)(({ theme }) => ({
        fontSize: '1.5rem',
        color: theme.palette.primary.dark,
    })),
};
