import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HomePage from './HomePage';
import { UserType } from 'types/common/users.types';
import { useEffect, useRef } from 'react';

const HomePageContainer = (props: Props) => {
    // const {} = props;
    const recommendedWalletRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (window.location.hash === '#recommended-wallet') {
            recommendedWalletRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const childProps = {
        ...props,
        recommendedWalletRef,
    };

    return <HomePage {...childProps} />;
};

const propTypes = {};

interface extraProps {
    userType: UserType | null;
    setSelectedInstrumentType: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
HomePageContainer.propTypes = propTypes;

export default HomePageContainer;
