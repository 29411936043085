import { Box, styled, Typography } from '@mui/material';
import { transientOptions } from 'utils/helpers/stylesHelper';

export default {
    Container: styled(
        Box,
        transientOptions,
    )<{ $isMobile: boolean }>(({ theme, $isMobile }) =>
        theme.unstable_sx({
            padding: $isMobile ? '1rem 0rem' : '1rem 10rem',
            borderBottom: `1px dashed ${theme.palette.custom.darkblue10}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
            flexDirection: $isMobile ? 'column' : 'row',
        }),
    ),
    CustomerSelectWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            maxWidth: '600px',
            width: '100%',
        }),
    ),
    TitleInfo: styled(Typography)(({ theme, fontSize = '1rem' }) =>
        theme.unstable_sx({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.primary.main,
            marginRight: 1,
            fontWeight: 'bold',
            fontSize,
            width: 'fit-content',
        }),
    ),
    ButtonContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row' },
        }),
    ),
};
