import { Tprop } from 'types/common/general.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    FundsTable,
    MarketTableFilters,
    WSFundTableKey,
    WSTable,
    WSTableKey,
} from 'types/context/prices.context.types';
import CustomLoader from '../../CustomLoader';
import { tCommon } from 'constants/appConstants';
import { TableRow } from 'hooks/common/CommonMarketTable/rows.hooks';
import St from './MarketInstrumentTable.styled';

const MarketInstrumentTable = (props: Props) => {
    const {
        t,
        columns,
        tableData,
        isLoading,
        filters,
        changeBackground,
        handleFavorite,
        isLoadingFavorite,
        rows,
        table,
    } = props;

    return (
        <St.TableContainerWrapper>
            {tableData && tableData.length > 0 ? (
                <St.TableContainer $funds={table === 'funds'}>
                    {isLoading ? (
                        <CustomLoader />
                    ) : (
                        <St.Table>
                            <St.TableHead>
                                <St.TableRow>
                                    <St.TableCellFavorite align="center"></St.TableCellFavorite>
                                    {columns.map(column => (
                                        <St.TableCell
                                            align={column.align ?? 'right'}
                                            key={column.key}>
                                            <span>{column.text}</span>
                                        </St.TableCell>
                                    ))}
                                </St.TableRow>
                            </St.TableHead>
                            <St.TableBody>
                                {!isLoading &&
                                    tableData &&
                                    tableData.length > 0 &&
                                    tableData.map(el => (
                                        <St.TableRow key={el.ticker}>
                                            <St.TableCellFavorite
                                                align="left"
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    handleFavorite(el.ticker, el.favorite)
                                                }>
                                                {el.favorite ? (
                                                    <St.StarWithBackground />
                                                ) : (
                                                    <St.BottomlessStar />
                                                )}
                                            </St.TableCellFavorite>
                                            {rows.map((row, index) => (
                                                <St.TableCell
                                                    key={index}
                                                    align={row.align ?? 'left'}
                                                    $bg={
                                                        row.bg
                                                            ? changeBackground(el.ticker, row.id)
                                                            : undefined
                                                    }
                                                    $color={row.color?.(el)}
                                                    sx={row.sx}
                                                    onClick={() =>
                                                        row.onClick ? row.onClick?.(el) : null
                                                    }>
                                                    <>{row.cell(el, filters.state24OrCI)}</>
                                                </St.TableCell>
                                            ))}
                                        </St.TableRow>
                                    ))}
                                {isLoadingFavorite && (
                                    <St.Modal open={true}>
                                        <St.ModalBox>
                                            <CustomLoader />
                                        </St.ModalBox>
                                    </St.Modal>
                                )}
                            </St.TableBody>
                        </St.Table>
                    )}
                </St.TableContainer>
            ) : (
                <St.AlerNoDataWrapper>
                    <St.AlertNoData title={t('no_data_was_found', tCommon)} />
                </St.AlerNoDataWrapper>
            )}
        </St.TableContainerWrapper>
    );
};

const propTypes = {};

interface extraProps extends Tprop {
    columns: Array<{ text: string; key: WSTableKey | WSFundTableKey; align?: 'left' | 'center' }>;
    changeBackground: (ticker: string, cell: WSTableKey | WSFundTableKey) => string;
    tableData?: Array<WSTable | FundsTable>;
    isLoading: boolean;
    filters: MarketTableFilters;
    handleFavorite: (ticker: string, isFavorite: boolean) => void;
    isLoadingFavorite: boolean;
    rows: Array<TableRow<WSTable | FundsTable>>;
    table: 'default' | 'funds';
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketInstrumentTable.propTypes = propTypes;

export default MarketInstrumentTable;
