import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import DeleteBankAccount from './DeleteBankAccount';
import { useTranslation } from 'react-i18next';
import { useDeleteBankAccount } from 'hooks/api/cahsflow.hooks';
import { GetBankAccountsItem } from 'types/api/cashflow.types';
import { AlertColor } from '@mui/material';

const DeleteBankAccountContainer = (props: Props) => {
    const { row, close, setSnackBarMessage } = props;
    const { t } = useTranslation();
    const { mutate, isLoading } = useDeleteBankAccount(setSnackBarMessage, close);

    const handleSubmit = () => mutate(row.id);

    const childProps = {
        ...props,
        isLoading,
        handleSubmit,
        t,
    };

    return <DeleteBankAccount {...childProps} />;
};

const propTypes = {};

interface extraProps {
    row: GetBankAccountsItem;
    setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
    close: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DeleteBankAccountContainer.propTypes = propTypes;

export default DeleteBankAccountContainer;
