import { Box, Typography, styled } from '@mui/material';

export default {
    Container: styled(Box)(() => ({
        display: 'flex',
        alignItems: 'center',
    })) as typeof Box,
    HeaderDesktopLogo: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: 'fit-content',
            height: 'inherit',
            cursor: 'pointer',
            paddingRight: '20px',
            display: 'flex',
            alignItems: 'center',
        }),
    ) as typeof Box,
    AccountInfo: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.primary.main,
            marginRight: 1,
            fontWeight: '700',
            fontSize: '1rem',
        }),
    ),
    AccountInfoSubtitile: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: theme.palette.primary.main,
            marginRight: 1,
            fontWeight: '400',
            fontSize: '13px',
        }),
    ),
};
