import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, keyframes, styled, Typography } from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import ErrorText from 'components/common/ErrorText';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

const slideIn = keyframes`
from {
  right: -50%;
}
to {
  right: 0;
}
`;

export default {
    SectionWrapper: styled(
        Box,
        transientOptions,
    )<{ $isOnboarding?: boolean; $isExpired: boolean }>(({ $isOnboarding, $isExpired, theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            maxWidth: $isExpired ? '100%' : pxToRem(1172),
            height: 'fit-content',
            padding: '5.938rem 0 1rem',
            width: $isOnboarding ? 'auto' : '100%',
            marginLeft: { lg: $isOnboarding ? 0 : '7rem' },
        }),
    ),
    HeaderExpiredWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: pxToRem(1172),
            height: '60vh',
        }),
    ),
    NumberWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'flex-start' },
            flexWrap: 'wrap',
            gap: '1.5rem',
            marginTop: '1.938rem',
            '.complete': {
                background: theme.palette.custom.darkblue10,
            },
        }),
    ),
    QuestionsAnswersWrapper: styled(Box)(() => ({
        display: 'flex',
        flexDirection: 'column',
        margin: '3.938rem 0 0',
        overflow: 'hidden',
        position: 'relative',
        animation: `${slideIn} .5s ease-out forwards`,
    })) as typeof Box,
    SlideInWrapper: styled(Box)(() => ({})) as typeof Box,
    ActionButtonWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { xs: '100%', sm: 'fit-content' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: '1rem', sm: '3.188rem' },
            margin: '6rem auto 0',
            button: {
                fontSize: '1.25rem',
                fontWeight: '500',
                lineHeight: '23.44px',
                letterSpacing: '0.0015em',
                padding: '8.5px 0',
                borderRadius: '44px',
                textTransform: 'uppercase',
                width: { xs: '100%', sm: '260px', md: '324px' },
                m: 0,
            },
        }),
    ),
    ActionButton: styled(ActionButton)(({ theme }) => theme.unstable_sx({})),
    LoadingButton: styled(LoadingButton)(({ theme }) =>
        theme.unstable_sx({
            '&:disabled': {
                background: theme.palette.custom.vibrantblue2,
                color: theme.palette.background.default,
                opacity: 0.5,
            },
            '&.MuiLoadingButton-loading': {
                color: 'transparent',
            },
        }),
    ),
    CustomCircularProgress: styled(CircularProgress)(({ theme }) =>
        theme.unstable_sx({ color: 'white', width: '25px !important', height: '25px!important' }),
    ),
    ErrorTextWrapper: styled(Box)(() => ({
        margin: '1.5rem 0 0 1rem',
    })),
    ErrorText: styled(ErrorText)(() => ({})),
    Subtitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontSize: '1.5rem',
            fontWeight: '500',
            lineHeight: '28.13px',
            margin: '3.438rem 0 2.438rem',
            color: theme.palette.custom.darkblue2,
        }),
    ),
    Title: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontSize: '3rem',
            fontWeight: '400',
            margin: '3.438rem 0 2.438rem',
            textAlign: 'center',
            color: theme.palette.primary.main,
        }),
    ),
    Ul: styled('ul')(({ theme }) =>
        theme.unstable_sx({
            margin: '0',
            padding: '0',
            color: theme.palette.custom.darkblue2,
        }),
    ),
    Li: styled('li')(() => ({
        fontSize: '2.125rem',
        fontWeight: 400,
        lineHeight: '39.84px',
        letterSpacing: '0.0025em',
        listStyleType: 'none',
    })),
};
