import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './BlogPage.styled';
import CustomCarousel from 'components/common/CustomCarousel';
import { Box, Typography } from '@mui/material';
import CircularSpinner from 'components/common/CircularSpinner';
import { BlogArticle } from 'types/api/wrapper.types';

const BlogPage = (props: Props) => {
    const { t, groupedData, isLoading, dataLatestArray, isLoadingLatest } = props;
    return (
        <>
            <St.SectionWrapper component={'section'} data-testid="BlogPage-page">
                {isLoadingLatest && isLoading ? (
                    <Box sx={{ marginTop: '10px' }}>
                        <CircularSpinner />
                    </Box>
                ) : (
                    <>
                        {dataLatestArray && (
                            <St.LatestSection>
                                <CustomCarousel width="100%">
                                    {dataLatestArray?.map((article: BlogArticle, index: number) => (
                                        <St.CarouselItemContainer key={index}>
                                            <St.LatestImageContainer
                                                key={article.id}
                                                onClick={() =>
                                                    window.open(article.mob_url, '_blank')
                                                }
                                                sx={{ cursor: 'pointer' }}>
                                                <St.Image
                                                    src={article.mob_imagen_grande_header}
                                                    alt={article.mob_titulo}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                                <St.TitleOverlay>
                                                    <St.LatestTitle>
                                                        {article.mob_titulo}
                                                    </St.LatestTitle>
                                                </St.TitleOverlay>
                                            </St.LatestImageContainer>
                                        </St.CarouselItemContainer>
                                    ))}
                                </CustomCarousel>
                            </St.LatestSection>
                        )}
                        {groupedData &&
                            Object.keys(groupedData).map(categoryName => (
                                <Box key={categoryName}>
                                    <Typography
                                        variant="h5"
                                        color="primary"
                                        sx={{ padding: '0px 10px;' }}>
                                        {categoryName}
                                    </Typography>
                                    <St.CarouselWrapper>
                                        <CustomCarousel width="700px">
                                            {groupedData[categoryName].map(
                                                (article: BlogArticle, index: number) => (
                                                    <St.CarouselItemContainer key={index}>
                                                        <St.ImageContainer
                                                            key={article.id}
                                                            onClick={() =>
                                                                window.open(
                                                                    article.mob_url,
                                                                    '_blank',
                                                                )
                                                            }
                                                            sx={{ cursor: 'pointer' }}>
                                                            <St.Image
                                                                src={
                                                                    article.mob_imagen_grande_header
                                                                }
                                                                alt={article.mob_titulo}
                                                            />
                                                            <St.TitleOverlay>
                                                                <St.AvailabeByText
                                                                    variant="subtitle1"
                                                                    sx={{ color: 'white' }}>
                                                                    {article.mob_titulo}
                                                                </St.AvailabeByText>
                                                            </St.TitleOverlay>
                                                        </St.ImageContainer>
                                                    </St.CarouselItemContainer>
                                                ),
                                            )}
                                        </CustomCarousel>
                                    </St.CarouselWrapper>
                                </Box>
                            ))}
                    </>
                )}
            </St.SectionWrapper>
        </>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    groupedData: PropTypes.object as PropTypes.Requireable<{ [key: string]: BlogArticle[] }>,
    isLoading: PropTypes.bool.isRequired,
    dataLatestArray: PropTypes.array as PropTypes.Requireable<BlogArticle[]>,
    isLoadingLatest: PropTypes.bool.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BlogPage.propTypes = propTypes;

export default BlogPage;
