import { axiosDataInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import {
    GetAccountStatusResponse,
    GetChannelAccountStatusResponse,
    GetChannelMarketAccountResponse,
    GetReportTicketsResponse,
    GetReportTransactionResponse,
} from 'types/api/marketAccount.types';

const apiName = 'market-account';
const MARKET_ACCOUNT_BASE_URL = '/market-account/v1_0';

export default {
    getAccountStatus: (customerCode: string, byLiquidation: boolean = false) =>
        withFakeData<GetAccountStatusResponse>(
            () =>
                axiosDataInstance.get(
                    `${MARKET_ACCOUNT_BASE_URL}/account-status/${customerCode}?by_liquidation=${byLiquidation}`,
                ),
            apiName,
            'market-account',
            false,
            500,
        ),
    getChannelAccountStatus: () =>
        withFakeData<GetChannelAccountStatusResponse>(
            () => axiosDataInstance.get(`${MARKET_ACCOUNT_BASE_URL}/account-status`),
            apiName,
            'market-account',
            false,
            500,
        ),
    getChannelMarketAccounts: (req: any) =>
        withFakeData<GetChannelMarketAccountResponse>(
            () => axiosDataInstance.get(`${MARKET_ACCOUNT_BASE_URL}/market-accounts`),
            apiName,
            'market-account',
            false,
            500,
        ),
    getChannelClients: (filterQueryString: string) =>
        withFakeData<GetChannelMarketAccountResponse>(
            () =>
                axiosDataInstance.get(
                    `${MARKET_ACCOUNT_BASE_URL}/market-accounts?${filterQueryString}`,
                ),
            apiName,
            'market-account',
            false,
            500,
        ),
    getReportTickets: (filterQueryString: string, customerCode: string) =>
        withFakeData<GetReportTicketsResponse>(
            () =>
                axiosDataInstance.get(
                    `${MARKET_ACCOUNT_BASE_URL}/report/${customerCode}/tickets?${filterQueryString}`,
                ),
            apiName,
            'getReportTickets',
            false,
            500,
        ),
    getReportTransactions: (filterQueryString: string, customerCode: string) =>
        withFakeData<GetReportTransactionResponse>(
            () =>
                axiosDataInstance.get(
                    `${MARKET_ACCOUNT_BASE_URL}/report/${customerCode}/transactions?${filterQueryString}`,
                ),
            apiName,
            'getReportTransactions',
            false,
            500,
        ),
};
