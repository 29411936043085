import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { GetCollateralOrderItem } from 'types/api/orders.types';
import { tCommon } from 'constants/appConstants';
import TableLayout from 'components/layouts/TableLayout';
import { useGetCollateralOrders } from 'hooks/api/orders.hooks';

const MarketCollateralsPage = (props: Props) => {
    const { columns, filters, actions, headAction, t } = props;

    return (
        <TableLayout
            title={t('layout_main_navigation_collaterals', tCommon)}
            filters={filters}
            columns={columns}
            actions={actions}
            createAction={headAction}
            fetchData={useGetCollateralOrders}
            defaultSortFieldId="createdAt"
        />
    );
};

const propTypes = { t: PropTypes.func.isRequired };

interface extraProps {
    columns: CommonColumn<GetCollateralOrderItem>[];
    filters: CommonFilter[];
    actions: CommonAction<GetCollateralOrderItem>[];
    headAction: CommonAction<GetCollateralOrderItem>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketCollateralsPage.propTypes = propTypes;

export default MarketCollateralsPage;
