import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketInstrumentDetailPage from './MarketInstrumentDetailPage';
import { useLocation } from 'react-router-dom';
import { useGetInstrumentDetail } from 'hooks/ws/prices.hooks';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';
import { LimitPriceStateInfo } from 'types/pages/marketInstrumentDetail.types';
import { BUYSELL } from 'types/api/orders.types';
import { InstrumentType } from 'types/api/instruments.types';
import { mainRoutesPaths } from 'router/routesPaths';
import { MarketContext } from 'context/market.context';
import { getCurrentTime } from 'utils/helpers/dateHelper';

const MarketInstrumentDetailPageContainer = (props: Props) => {
    // const {} = props;
    const location = useLocation();
    const ticker = location.pathname.split('/')[3];
    const [limitIndex, setLimitIndex] = React.useState<LimitPriceStateInfo | null>(null);
    const [buySell, setBuySell] = React.useState<BUYSELL>('BUY');
    const [term, setTerm] = React.useState(
        location?.state?.term ?? localStorage.getItem('term') ?? '24hs',
    );
    const {
        rules,
        currency,
        favorite,
        isLoading,
        instrument,
        currencies,
        isBond,
        tickerPriceInfo,
        requiresDeclaration,
    } = useGetInstrumentDetail(term, ticker.toUpperCase());
    const { data, isLoading: isLoadingAccountStatus } = useGetAccountStatus();
    const { marketsTimes } = React.useContext(MarketContext);

    const getRouteByInstrumentType = (value: InstrumentType | null) => {
        switch (value) {
            case 'BOND':
                return mainRoutesPaths.bonds;
            case 'EQUITY':
                return mainRoutesPaths.equity;
            case 'CERTIFICATE':
                return mainRoutesPaths.cedears;
            default:
                return mainRoutesPaths.preferred;
        }
    };

    const currentMarket = React.useMemo(() => {
        if (!marketsTimes || !instrument) return null;
        const instrumentMarket = marketsTimes.find(m => m.name === instrument?.market);
        if (!instrumentMarket) return null;
        if (term === '24hs')
            return {
                openTime: instrumentMarket?.t1OpenTime,
                closeTime: instrumentMarket?.t1CloseTime,
            };
        return { openTime: instrumentMarket?.t0OpenTime, closeTime: instrumentMarket?.t0CloseTime };
    }, [marketsTimes, instrument, term]);

    const isMarketClosed = React.useMemo(() => {
        const openTimeSplitted = currentMarket?.openTime.split(':');
        const closeTimeSplitted = currentMarket?.closeTime.split(':');
        if (!openTimeSplitted || !closeTimeSplitted) return true;
        const now = getCurrentTime(new Date().toISOString()).split(':');
        for (let index = 0; index < 3; index++) {
            const openElement = openTimeSplitted[index];
            const closeElement = closeTimeSplitted[index];
            const nowElement = now[index];
            if (nowElement < openElement && nowElement > closeElement) return true;
        }
        return false;
    }, [currentMarket]);

    const childProps = {
        ...props,
        rules,
        instrument,
        isLoading: isLoading || isLoadingAccountStatus,
        ticker,
        tickerPriceInfo,
        term,
        currency,
        favorite,
        accountStatus: data,
        isBond,
        setTerm,
        currencies,
        limitIndex,
        setLimitIndex,
        buySell,
        setBuySell,
        requiresDeclaration,
        getRouteByInstrumentType,
        currentMarket,
        isMarketClosed,
    };

    return <MarketInstrumentDetailPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketInstrumentDetailPageContainer.propTypes = propTypes;

export default MarketInstrumentDetailPageContainer;
