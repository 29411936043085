import moment from 'moment';

export const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const checkIfInvestorProfileIsExpired = (expirationDate: string) => {
    const currentDate = new Date();
    const investorProfileExpirationDate = new Date(expirationDate);
    return currentDate > investorProfileExpirationDate;
};
export const formatTimestampToDate = (milliseconds: number) => {
    // Crear un objeto Date a partir de los milisegundos
    const date = new Date(milliseconds);

    // Convertir la fecha a string con las opciones de formato
    const formattedDate = date.toLocaleDateString('es-ES', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    // Remover comas en la fecha formateada
    return formattedDate.replace(',', '');
};

// convert date to format
export const formatSingleDate = (date?: string, format?: string) =>
    date ? moment(date).format(format || 'DD/MM/YYYY') : '-';

// convert date to format DD/MM/YYYY, HH:MM:SS
export const formatDateWithHour = (date: string) =>
    date ? moment(date).format('DD/MM/YYYY HH:mm') : '-';

export const getCurrentTime = (date: string) => (date ? moment(date).format('HH:mm:ss') : '-');
