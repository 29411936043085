import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ToolsList from './ToolsList';
import { useTranslation } from 'react-i18next';
import { useLogout } from 'hooks/api/auth.hooks';
import { mainRoutesPaths, publicSubroutesPaths } from 'router/routesPaths';
import { AuthContext } from 'context/auth.context';

const ToolsListContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const { mutate: onLogout } = useLogout();
    const [openProfile, setOpenProfile] = React.useState<boolean>(false);
    const { userData, userType } = React.useContext(AuthContext);

    const prospectProfileOptions = [
        {
            title: t('layout_main_navigation_preferences'),
            path: mainRoutesPaths.preferences,
        },
        {
            title: t('layout_main_navigation_delete_account'),
            path: mainRoutesPaths.deleteAccount,
        },
        // {
        //     title: t('layout_main_navigation_replace_password'),
        //     path: mainRoutesPaths.replacePassword,
        // },
        {
            title: t('layout_main_navigation_help'),
            path: publicSubroutesPaths.help,
        },
        {
            title: t('layout_main_navigation_logout'),
            handleClick: () => onLogout(),
        },
    ];
    const clientProfileOptions = [
        {
            title: t('layout_main_navigation_personal_data'),
            path: mainRoutesPaths.profilePersonalData,
        },
        {
            title: t('layout_main_navigation_bank_accounts'),
            path: mainRoutesPaths.bankAccounts,
        },
        {
            title: t('layout_main_navigation_investor_profile'),
            path: mainRoutesPaths.investmentTest,
        },
        ...prospectProfileOptions,
    ];
    const channelProfileOptions = [
        {
            title: t('layout_main_navigation_personal_data'),
            path: mainRoutesPaths.profilePersonalData,
        },
        // {
        //     title: t('layout_main_navigation_replace_password'),
        //     path: mainRoutesPaths.replacePassword,
        // },
        {
            title: t('layout_main_navigation_help'),
            path: publicSubroutesPaths.help,
        },
        {
            title: t('layout_main_navigation_logout'),
            handleClick: () => onLogout(),
        },
    ];

    const profileOptions = React.useMemo(() => {
        if (userType === 'CHANNEL') return channelProfileOptions;
        return userData?.onboardingPending ? prospectProfileOptions : clientProfileOptions;
    }, [userData?.onboardingPending, userType]);

    const childProps = {
        ...props,
        t,
        openProfile,
        setOpenProfile,
        profileOptions,
    };

    return <ToolsList {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ToolsListContainer.propTypes = propTypes;

export default ToolsListContainer;
