import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { useTranslation } from 'react-i18next';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { formatDate } from 'utils/helpers/dateHelper';
import { tCommon } from 'constants/appConstants';
import ReportsTicketsPage from './ReportsTicketsPage';
import { ReportTicket } from 'types/pages/reports.types';

const ReportsTicketsPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('reports');
    const tBase = 'reports_tickets_page_';

    const columns: CommonColumn<ReportTicket>[] = [
        {
            id: 'typeSpecies',
            name: t(`${tBase}column_instrument_type`),
            selector: row => row.typeSpecies,
            exportOption: row => row.typeSpecies ?? '-',
        },
        {
            id: 'instrumentDescription',
            name: t(`${tBase}column_instrument_name`),
            selector: row => row.instrumentDescription,
            exportOption: row => row.instrumentDescription,
        },
        {
            id: 'ticketNumber',
            name: t(`${tBase}column_ticket_number`),
            selector: row => row.ticketOperationNumber,
            exportOption: row => row.ticketOperationNumber,
        },
        {
            id: 'agreementDate',
            name: t(`${tBase}column_concertation_date`),
            selector: ({ agreementDate }) => (agreementDate ? formatDate(agreementDate) : '-'),
            exportOption: ({ agreementDate }) => (agreementDate ? formatDate(agreementDate) : '-'),
        },
        {
            id: 'liquidationDate',
            name: t(`${tBase}column_liquidation_date`),
            selector: ({ liquidationDate }) =>
                liquidationDate ? formatDate(liquidationDate) : '-',
            exportOption: ({ liquidationDate }) =>
                liquidationDate ? formatDate(liquidationDate) : '-',
        },
        {
            id: 'operationType',
            name: t(`${tBase}column_operation_type`),
            selector: row => row.operationType,
            exportOption: row => row.operationType,
        },
        {
            id: 'amount',
            name: t('quantity', tCommon),
            selector: ({ quantity }) =>
                quantity === null ? '-' : String(quantity).replace('-', ''),
            exportOption: ({ quantity }) =>
                quantity === null ? '-' : String(quantity).replace('-', ''),
        },
        {
            id: 'price',
            name: t('price', tCommon),
            selector: ({ currency, price }) =>
                price === null ? '-' : `${currency} ${String(price).replace('-', '')}`,
            exportOption: ({ currency, price }) =>
                price === null ? '-' : `${currency} ${String(price).replace('-', '')}`,
        },
        {
            id: 'gross',
            name: t(`${tBase}column_gross`),
            selector: ({ currency, gross }) => `${currency} ${String(gross).replace('-', '')}`,
            exportOption: ({ currency, gross }) => `${currency} ${String(gross).replace('-', '')}`,
        },
        {
            id: 'tariff',
            name: t(`${tBase}column_tariff`),
            selector: ({ tariffCurrency, tariff, tariffVat }) =>
                tariff === null ? '-' : `${tariffCurrency} ${tariff - tariffVat}`,
            exportOption: ({ tariffCurrency, tariff, tariffVat }) =>
                tariff === null ? '-' : `${tariffCurrency} ${tariff - tariffVat}`,
        },
        {
            id: 'tariffVat',
            name: t(`${tBase}column_tariff_vat`),
            selector: ({ tariffCurrency, tariffVat }) =>
                `${tariffCurrency} ${String(tariffVat).replace('-', '')}`,
            exportOption: ({ tariffCurrency, tariffVat }) =>
                `${tariffCurrency} ${String(tariffVat).replace('-', '')}`,
        },
        {
            id: 'market',
            name: t(`${tBase}column_market`),
            selector: ({ feeCurrency, marketFee, feeVat }) =>
                `${feeCurrency} ${String(marketFee - feeVat).replace('-', '')}`,
            exportOption: ({ feeCurrency, marketFee, feeVat }) =>
                `${feeCurrency} ${String(marketFee - feeVat).replace('-', '')}`,
        },
        {
            id: 'net',
            name: t(`${tBase}column_net`),
            selector: ({ currency, net }) => `${currency} ${String(net).replace('-', '')}`,
            exportOption: ({ currency, net }) => `${currency} ${String(net).replace('-', '')}`,
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateType',
            label: t(`${tBase}date_type`),
            type: 'select',
            query: 'byLiquidation',
            options: [
                { id: 'true', name: t(`${tBase}for_liquidation`) },
                { id: 'false', name: t(`${tBase}for_concertation`) },
            ],
        },
        {
            id: 'dateFrom',
            label: t('from', tCommon),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tCommon),
            type: 'date',
            query: 'dateTo',
        },
    ];

    const childProps = {
        ...props,
        columns,
        filters,
        t,
    };

    return <ReportsTicketsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsTicketsPageContainer.propTypes = propTypes;

export default ReportsTicketsPageContainer;
