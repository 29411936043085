import * as React from 'react';
import St from './WalletInstrument.styled';
import { Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentDetails from './InstrumentDetails';
import InstrumentModal from './InstrumentModal';
import { ProcessedData } from 'types/api/instruments.types';

const WalletInstrument = (props: Props) => {
    const {
        instrumentsAmount,
        t,
        openInstrumentModal,
        setOpenInstrumentModal,
        handleInstrumentClick,
        processedData,
    } = props;

    return (
        <St.WalletInstrumentWrapper>
            <St.WalletInstrumentContainer>
                <Grid container spacing={2} alignItems="center">
                    <St.DetailContainer>
                        <St.Subtitle variant="subtitle1" color="black !important">
                            {t('wallet_instruments', { instrumentsAmount }) as string}
                        </St.Subtitle>

                        {processedData.map((instrument, index) => (
                            <Box key={index} marginBottom="20px">
                                <St.Subtitle variant="h5">
                                    {instrument.quantity} {t(instrument.type)}
                                </St.Subtitle>

                                {instrument.info.map((data, key) => (
                                    <div
                                        key={key}
                                        onClick={() => {
                                            handleInstrumentClick({
                                                ...data,
                                                type: instrument.type,
                                            });
                                        }}
                                        style={{ cursor: 'pointer' }}>
                                        <InstrumentDetails
                                            t={t}
                                            details={data}
                                            type={instrument.type}
                                        />
                                    </div>
                                ))}
                            </Box>
                        ))}
                    </St.DetailContainer>
                </Grid>
                <InstrumentModal
                    t={t}
                    openModal={openInstrumentModal}
                    setOpenModal={setOpenInstrumentModal}
                    selectedInstrument={props.selectedInstrument}
                />
            </St.WalletInstrumentContainer>
        </St.WalletInstrumentWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    instrumentsAmount: number;
    openInstrumentModal: boolean;
    setOpenInstrumentModal: (x: boolean) => void;
    handleInstrumentClick: (instrument: any) => void;
    selectedInstrument: any;
    selectedInstrumentType: string;
    processedData: ProcessedData[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WalletInstrument.propTypes = propTypes;

export default WalletInstrument;
