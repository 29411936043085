import { Box, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    TagsContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        gap: pxToRem(20),
        marginTop: pxToRem(5),
        marginBottom: pxToRem(15),
    })),
    Tag: styled(Typography)(({ theme }) => ({
        padding: pxToRem(5),
        // color: theme.palette.primary.main,
        color: theme.palette.custom.darkblue6,
        // backgroundColor: theme.palette.primary.main,
        width: pxToRem(80),
        textAlign: 'center',
        fontWeight: 500,
        lineHeight: '16.41px',
    })),
    InstrumentContentWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'relative',
            left: { xs: 0, lg: 0, xl: 0 },
            width: 'auto',
            padding: '2rem',
            backgroundColor: theme.palette.custom.vibrantblue10,
            borderBottomRightRadius: pxToRem(20),
            borderTopRightRadius: pxToRem(20),
            borderBottomLeftRadius: { xl: pxToRem(20), xs: 0 },
            borderTopLeftRadius: { xl: pxToRem(20), xs: 0 },
        }),
    ),
    MarketStatusContainer: styled(Box)(({ theme }) => ({
        marginTop: pxToRem(10),
    })),
    GrayText: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue6,
        fontWeight: 500,
    })),
    PriceValue: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue,
        marginTop: '1rem',
        marginBottom: '1rem',
    })),
};
