import { useMutation, useQuery } from '@tanstack/react-query';
import endpoints from 'api/instruments/instruments.api';
import {
    GetQuotationsResponse,
    QuotationItem,
    GetInstrumentsResponse,
    GetFundDetailBody,
    GetHistoricalPrices,
    GetFundsResponse,
    GetInstrumentsByTickerResponse,
    InstrumentRule,
} from 'types/api/instruments.types';
import { errorResponseHelper } from 'utils/helpers/errorHelper';
import { MarketContext } from 'context/market.context';
import React, { useContext } from 'react';
import { fundForMarketTableAdapter } from 'adapters/api/instruments.adapter';
import { AuthContext } from 'context/auth.context';
import { useCommonGrid } from 'context/common/commonGrid.context';

export const useGetInstruments = (filterQueryString: string, enabledFetch: boolean = true) => {
    const { selectedCustomer } = React.useContext(AuthContext);
    const queryString = `${filterQueryString}&userType=${selectedCustomer?.user_type}`;
    const { data, isLoading, isFetching, isRefetching, refetch, error, isSuccess } = useQuery(
        ['instruments', queryString],
        () => endpoints.getInstruments(queryString),
        {
            select: ({ data }: { data: GetInstrumentsResponse }) => ({
                items: data.items.map(el => ({ ...el, favorite: el.tickers[0].favorite })),
                total: data.total,
            }),
            enabled: enabledFetch,
        },
    );

    return {
        data: data?.items,
        totalSize: data?.total,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        isSuccess,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};
export const useGetInstrumentByTicker = (ticker: string) => {
    const { data, isLoading, isFetching, isRefetching, refetch, error, isSuccess } = useQuery(
        ['instruments', ticker],
        () => endpoints.getInstrumentByticker(ticker),
        {
            select: ({ data }: { data: GetInstrumentsByTickerResponse }) => {
                const { instrument, rules } = data;
                return {
                    instrument,
                    rules: rules.map(
                        r =>
                            ({
                                action: r.action,
                                reason: r.reason,
                                conditions: r.conditions.map(c => ({
                                    currencies: c.currencies,
                                    terms: c.terms.map(t => {
                                        if (t === '24') return '24hs';
                                        return t;
                                    }),
                                })),
                            }) as InstrumentRule,
                    ),
                };
            },
        },
    );

    return {
        instrument: data?.instrument,
        rules: data?.rules,
        isLoading: (isLoading && isFetching) || isRefetching,
        refetch,
        isSuccess,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetQuotations = () => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['getQuotations'],
        () => endpoints.getQuotations(),
        {
            // select: data => data.data,
            select: ({ data }: { data: GetQuotationsResponse }) => data as QuotationItem[],
        },
    );

    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useAddFavorites = () => {
    const { setNeedRefresh } = useCommonGrid();
    const { mutate, isLoading, error, isError } = useMutation(endpoints.postFavorites, {
        onSuccess: () => {
            setNeedRefresh(true);
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};

export const useRemoveFavorites = () => {
    const { setNeedRefresh } = useCommonGrid();
    const { mutate, isLoading, error, isError } = useMutation(endpoints.deleteFavorites, {
        onSuccess: () => {
            setNeedRefresh(true);
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};

export const useGetInstrumentsForFund = ({
    typeInstrument = '',
    currency,
    filteredByType = '',
    favorite = false,
    ticker = '',
    name,
    liquidity,
    classOfFunds,
}: {
    typeInstrument?: string;
    currency?: string;
    filteredByType?: string;
    favorite?: boolean;
    ticker?: string;
    name: string;
    liquidity: string;
    classOfFunds: string;
}) => {
    const { funds } = React.useContext(MarketContext);
    const { selectedCustomer } = React.useContext(AuthContext);
    const filterQueryString = `&ticker=${ticker}&currency=${currency}&type=${typeInstrument}&name=${name}&tags=${filteredByType}&favoritesOnly=${favorite}${classOfFunds ? `&fundClass=${classOfFunds}` : ''}&userType=${selectedCustomer?.user_type}`;

    const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(
        ['instrumentsForFund', filterQueryString],
        () => endpoints.getInstruments(filterQueryString),
        {
            select: ({ data }: { data: GetInstrumentsResponse }) => {
                const tickersArray = data.items.map(el => ({
                    ticker: el.tickers[0].ticker,
                    favorite: el.tickers[0].favorite,
                    currency: el.tickers[0].currency,
                }));

                return {
                    total: funds && funds?.length > 0 ? data.total : 0,
                    data:
                        funds && funds?.length > 0
                            ? fundForMarketTableAdapter(
                                  tickersArray
                                      .map(({ ticker, favorite }) => {
                                          const fund = funds.find(fund => fund.ticker === ticker);
                                          return fund ? { ...fund, favorite } : undefined;
                                      })
                                      .filter(Boolean) as GetFundsResponse[],
                              ).filter(el =>
                                  liquidity === 'all' ? true : el.liquiditySummary === liquidity,
                              )
                            : [],
                };
            },
        },
    );

    return {
        tableData: data?.data,
        isLoading: (isLoading && isFetching) || isRefetching,
        changeValues: {},
        totalSize: data?.total || 0,
        refetch,
    };
};

export const useGetMarkets = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const { data, isLoading, error, isError, refetch, isRefetching } = useQuery(
        ['get-markets', isAuthenticated],
        () => endpoints.getMarketsTimes(),
        {
            select: data => data.data,
            enabled: isAuthenticated,
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};

export const useGetFunds = () => {
    const { isAuthenticated } = useContext(AuthContext);
    const { data, isLoading, error, isError, refetch, isRefetching } = useQuery(
        ['get-funds', isAuthenticated],
        () => endpoints.getFunds(),
        {
            select: data => data.data,
            enabled: isAuthenticated,
        },
    );
    return {
        funds: data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};

export const useGetFundDetails = (id: GetFundDetailBody) => {
    const { data, isLoading } = useQuery(['fundDetails', id], () => endpoints.fundDetail(id), {
        select: data => data.data,
    });

    return {
        data,
        isLoading,
    };
};

export const useGetHistoricalPrices = (params: GetHistoricalPrices) => {
    const filterQueryString = `&term=${params.term}&timeSpan=${params.timeSpan}&dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`;

    const { data, isLoading, isError, error, refetch, isRefetching } = useQuery(
        ['historicalPrices', params.id, filterQueryString],
        () => endpoints.historicalPrices(params.id, filterQueryString),
        {
            select: data => data.data,
        },
    );

    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};
