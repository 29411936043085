import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Currency, Tprop } from 'types/common/general.types';
import {
    calculateDifference,
    convertCurrencyFormat,
    formatNumber,
} from 'utils/helpers/commonHelper';
import BuySellSwitch from '../../../common/BuySellSwitch';
import { FundInfo, InstrumentRule } from 'types/api/instruments.types';
import ErrorText from 'components/common/ErrorText';
import BuySellPrevisualizeModal from './BuySellPrevisualizeModal';
import { CreateFundOrderRequest } from 'types/api/orders.types';
import CurrencyInput from 'components/common/CurrencyInput';
import { InvestmentTestValues } from 'types/common/users.types';
import ActionButton from 'components/common/ActionButton';
import St from './InstrumentBuySell.styled';
import CustomInput from 'components/common/CustomInput';
import { MarketAccount } from 'types/api/marketAccount.types';
import { REGEX_0_TO_9 } from 'utils/helpers/constants';

const InstrumentBuySell = (props: Props) => {
    const {
        isRescue,
        setIsRescue,
        currency,
        available,
        amount,
        disableBtn,
        estimatedPrice,
        isTotal,
        setIsTotal,
        managementRegulations,
        setManagementRegulations,
        availableTenure,
        fundInfo,
        handleChangePrice,
        isError,
        openPrevisualizeModal,
        setOpenPrevisualizeModal,
        isAPOrAC,
        bodyToCreateOrder,
        fundPreview,
        simpleSubscription,
        setSimpleSubscription,
        t,
        errorMessageDetail,
        isLoadingInstrumentDetail,
        blockBuyRules,
        blockSellRules,
        marketClosed,
        setMarketClosed,
        marketClosingTime,
        clientCantOperate,
        shareHolderCode,
        setShareHolderCode,
        shareHolderCodeError,
        selectedCustomer,
        handleOpenPreview,
    } = props;

    return (
        <St.Stack
            spacing={2}
            $textHover={t('no_possession_to_rescue')}
            $disableBtn={!availableTenure}>
            <BuySellSwitch
                active={isRescue}
                setActive={setIsRescue}
                buyText={t('subscribe_text')}
                sellText={t('rescue_text')}
                disableBtn2={!availableTenure}
            />
            <St.Typography variant="body1">
                {t(`${isRescue ? '' : 'ci_'}available_amount_text`)}
                <St.Typography component="span" color="primary.main" fontWeight={700}>
                    {convertCurrencyFormat(available || 0, currency)}
                </St.Typography>
            </St.Typography>
            <St.CuotaparteWrapper>
                <St.CuotaparteCurrent>
                    {convertCurrencyFormat(fundInfo.performanceVcp, currency, 7)}
                </St.CuotaparteCurrent>
                <St.CuotapartePrevious>
                    {`${convertCurrencyFormat(calculateDifference(fundInfo.performanceVcp, fundInfo.performanceVcpPercentage), currency)} (${formatNumber(fundInfo.performanceVcpPercentage)}%)`}
                </St.CuotapartePrevious>
            </St.CuotaparteWrapper>
            {!isRescue && (
                <St.Typography variant="body2">{t('market_fund_detail_text_1')}</St.Typography>
            )}
            {isRescue && (
                <St.CustomRadioButtonWrapper>
                    <St.CustomRadioButton
                        checked={!isTotal}
                        name={t('partial')}
                        onChange={() => setIsTotal(false)}
                        text={t('partial')}
                        value={String(!isTotal)}
                    />
                    <St.CustomRadioButton
                        checked={isTotal}
                        name={t('total')}
                        onChange={() => setIsTotal(true)}
                        text={t('total')}
                        value={String(isTotal)}
                    />
                </St.CustomRadioButtonWrapper>
            )}
            <St.InputContainer mt={isRescue ? '0 !important' : '.5rem'}>
                <St.Typography variant="body2" fontWeight={700}>
                    {t('amount', { extraInfo: isTotal && isRescue ? t('estimated') : '' })}
                </St.Typography>
                <CurrencyInput
                    currency={currency}
                    handleChange={value => handleChangePrice(value)}
                    sx={{ width: '50%' }}
                    value={amount}
                    decimalScale={2}
                    disabled={isTotal && isRescue}
                />
            </St.InputContainer>
            <St.AvailableContainer>
                <St.EstimatedPriceText variant="body1">
                    {t(`${!isTotal || !isRescue ? 'estimated_' : ''}cuotapartes`)}
                </St.EstimatedPriceText>
                <St.EstimatedPriceValue variant="body1" color="primary.main">
                    {estimatedPrice ?? '0'}
                </St.EstimatedPriceValue>
            </St.AvailableContainer>
            {isAPOrAC && fundInfo.allowsMixedOrder && (
                <>
                    <St.CustomRadioButtonWrapper>
                        <St.CustomRadioButton
                            checked={simpleSubscription}
                            name={t('simple_subscription')}
                            onChange={() => setSimpleSubscription(!simpleSubscription)}
                            text={t('simple_subscription')}
                            value={String(simpleSubscription)}
                        />
                    </St.CustomRadioButtonWrapper>
                    {isAPOrAC && simpleSubscription && (
                        <CustomInput
                            field="shareHolderCode"
                            label={t('cuotapartista_number')}
                            placeholder={t('cuotapartista_number')}
                            handleChange={val => setShareHolderCode(val)}
                            values={{ shareHolderCode }}
                            errors={{ shareHolderCode: shareHolderCodeError }}
                            disabled={!!selectedCustomer?.shareholderCode}
                            maxLength={7}
                            regex={REGEX_0_TO_9}
                            required
                        />
                    )}
                </>
            )}
            {!isRescue && (
                <>
                    <St.FormControlLabel
                        onChange={() => setManagementRegulations(!managementRegulations)}
                        control={<St.Checkbox checked={managementRegulations} />}
                        label={t('market_fund_detail_management_regulations_text_1')}
                    />
                    <St.SeeManagementRegulations
                        href={fundInfo.literatureRegulation}
                        target="_blank">
                        {t('market_fund_detail_management_regulations_text_2')}
                    </St.SeeManagementRegulations>
                </>
            )}
            <St.Typography variant="body2">{t('market_fund_detail_text_2')}</St.Typography>
            {!isTotal && isRescue && (
                <St.Typography variant="body2">{t('market_fund_detail_text_3')}</St.Typography>
            )}
            <St.ActionButtonWrapper>
                <St.ActionButton
                    type="button"
                    variant="contained"
                    disabled={
                        disableBtn ||
                        isLoadingInstrumentDetail ||
                        !!clientCantOperate ||
                        (isRescue === false && !!blockBuyRules?.length) ||
                        (isRescue === true && !!blockSellRules?.length)
                    }
                    onClick={handleOpenPreview}>
                    {t('previsualize_button_text')}
                </St.ActionButton>
            </St.ActionButtonWrapper>
            {clientCantOperate && (
                <ErrorText text={clientCantOperate} sx={{ marginBottom: '0.5rem' }} />
            )}
            {errorMessageDetail && <ErrorText text={errorMessageDetail} />}
            {isError && <ErrorText text={isError} />}
            {blockBuyRules?.length
                ? blockBuyRules.map(r => (
                      <ErrorText
                          sx={{ marginBottom: '0.5rem' }}
                          key={r.reason}
                          text={t('rules_error', { reason: r.reason })}
                      />
                  ))
                : null}
            {blockSellRules?.length
                ? blockSellRules.map(r => (
                      <ErrorText
                          sx={{ marginBottom: '0.5rem' }}
                          key={r.reason}
                          text={t('rules_error', { reason: r.reason })}
                      />
                  ))
                : null}
            {marketClosed && (
                <St.Dialog open={marketClosed} onClose={() => setMarketClosed(false)}>
                    <St.DialogTitle>{t('important_notice')}</St.DialogTitle>
                    <St.DialogContent>
                        {t('tooltip_market_fund', {
                            closeTime: marketClosingTime,
                        })}
                    </St.DialogContent>
                    <St.DialogActions>
                        <ActionButton
                            onClick={() => {
                                setMarketClosed(false);
                                setOpenPrevisualizeModal(true);
                            }}
                            variant="contained">
                            {t('btn_continue')}
                        </ActionButton>
                    </St.DialogActions>
                </St.Dialog>
            )}
            {fundPreview && openPrevisualizeModal && (
                <BuySellPrevisualizeModal
                    open={openPrevisualizeModal}
                    fundPreview={fundPreview}
                    close={() => setOpenPrevisualizeModal(false)}
                    bodyToCreateOrder={bodyToCreateOrder}
                />
            )}
        </St.Stack>
    );
};

const propTypes = {
    isTotal: PropTypes.bool.isRequired,
    isRescue: PropTypes.bool.isRequired,
    setIsRescue: PropTypes.func.isRequired,
    setIsTotal: PropTypes.func.isRequired,
    available: PropTypes.number.isRequired,
    setAmount: PropTypes.func.isRequired,
    managementRegulations: PropTypes.bool.isRequired,
    setManagementRegulations: PropTypes.func.isRequired,
    disableBtn: PropTypes.bool.isRequired,
    setOpenPrevisualizeModal: PropTypes.func.isRequired,
    openPrevisualizeModal: PropTypes.bool.isRequired,
    simpleSubscription: PropTypes.bool.isRequired,
    setSimpleSubscription: PropTypes.func.isRequired,
    isLoadingInstrumentDetail: PropTypes.bool.isRequired,
    marketClosed: PropTypes.bool.isRequired,
    setMarketClosed: PropTypes.func.isRequired,
    setShareHolderCode: PropTypes.func.isRequired,
};

interface extraProps extends Tprop {
    currency: Currency;
    amount: number;
    estimatedPrice?: string;
    fundInfo: FundInfo;
    availableTenure: number | undefined;
    handleChangePrice: (val: number | undefined) => void;
    isError: string | null;
    bodyToCreateOrder: CreateFundOrderRequest;
    fundPreview: {
        ticker: string;
        name: string;
        class: string;
        currency: Currency;
        priceToOperate: number;
        cuotaparte: string;
        profile?: InvestmentTestValues;
    };
    isAPOrAC: boolean;
    blockBuyRules?: InstrumentRule[];
    blockSellRules?: InstrumentRule[];
    errorMessageDetail: string | null;
    marketClosingTime: string;
    clientCantOperate: string | null;
    shareHolderCode: string;
    shareHolderCodeError: string | undefined;
    selectedCustomer: MarketAccount | null;
    handleOpenPreview: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentBuySell.propTypes = propTypes;

export default InstrumentBuySell;
