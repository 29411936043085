import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './UserInfo.styled';
import desktopLogo from 'assets/img/general/main_logo.svg';
import { NavigateFunction } from 'react-router-dom';

const UserInfo = (props: Props) => {
    const { userFullName, customerCode, isUF, navigate } = props;
    return (
        <St.Container>
            <St.HeaderDesktopLogo onClick={() => navigate('/')}>
                <img src={desktopLogo} alt="logo" />
            </St.HeaderDesktopLogo>
            {isUF && customerCode && (
                <div>
                    <St.AccountInfo>{userFullName}</St.AccountInfo>
                    <St.AccountInfoSubtitile>N° {customerCode}</St.AccountInfoSubtitile>
                </div>
            )}
        </St.Container>
    );
};

const propTypes = {
    userFullName: PropTypes.string.isRequired,
    isUF: PropTypes.bool.isRequired,
};

interface extraProps {
    navigate: NavigateFunction;
    customerCode: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserInfo.propTypes = propTypes;

export default UserInfo;
