import { GetFundsResponse } from 'types/api/instruments.types';
import { FundsTable } from 'types/context/prices.context.types';

export const fundForMarketTableAdapter = (data: GetFundsResponse[]) => {
    return data.map(
        fund =>
            ({
                ticker: fund.ticker,
                favorite: fund.favorite,
                name: `${fund?.fundInfo?.shortName?.startsWith('Consultatio') ? fund.fundInfo.shortName.replace('Consultatio', '') : fund?.fundInfo?.shortName} - ${fund.fundInfo.fundClass}`,
                yieldVcp: `${fund.fundInfo.performanceVcp ?? '-'}`,
                yieldVcpPercentage: `${fund.fundInfo.performanceVcpPercentage ?? '-'}`,
                yieldMtd: `${fund.fundInfo.performanceMtd ?? '-'}`,
                yieldQtd: `${fund.fundInfo.performanceQtd ?? '-'}`,
                yieldYtd: `${fund.fundInfo.performanceYtd ?? '-'}`,
                patrimonySummary: `${fund.fundInfo.summaryAssets ?? '-'}`,
                currencyTypeSummary: `${fund.currency ?? '-'}`,
                profile: `${fund.fundInfo.fundProfile ?? '-'}`,
                liquiditySummary: `${fund.fundInfo.summaryLiquidity ?? '-'}`,
            }) as FundsTable,
    );
};
