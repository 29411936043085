import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { ReportTicket } from 'types/pages/reports.types';
import TableLayout from 'components/layouts/TableLayout';
import { useGetReportTickets } from 'hooks/api/marketAccount.hooks';
import { tCommon } from 'constants/appConstants';

const ReportsTicketsPage = ({ columns, filters, t }: Props) => (
    <TableLayout
        title={t('layout_main_navigation_reports_tickets', tCommon)}
        fetchData={useGetReportTickets}
        columns={columns}
        filters={filters}
        exportTitle={t('layout_main_navigation_reports_tickets', tCommon)}
        canExport
    />
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<ReportTicket>[];
    filters: CommonFilter[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsTicketsPage.propTypes = propTypes;

export default ReportsTicketsPage;
