import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Grid, Typography } from '@mui/material';
import { GetMarketOrderItem } from 'types/api/orders.types';
import { convertCurrencyFormat } from 'utils/helpers/commonHelper';

const OrderDetail = ({
    row: {
        quantity,
        executed,
        gross,
        net,
        amount,
        isManual,
        user,
        isMarketPrice,
        operator,
        price,
        ticker,
    },
    t,
}: Props) => (
    <Grid container flexDirection="column" spacing={2}>
        {user && (
            <Grid item xs display="flex">
                <Typography fontSize={14} fontWeight={600} ml={1.25}>
                    {t('client')}:
                </Typography>
                <Typography fontSize={14} ml={0.625}>
                    {`${user.name} ${user.lastName}`}
                </Typography>
            </Grid>
        )}
        {isManual ? (
            <>
                <Grid item xs display="flex">
                    <Typography fontSize={14} fontWeight={600} ml={1.25}>
                        {t('price')}:
                    </Typography>
                    <Typography fontSize={14} ml={0.625}>
                        {isMarketPrice ? 'MKT' : convertCurrencyFormat(price, ticker.currency)}
                    </Typography>
                </Grid>
                <Grid item xs display="flex">
                    <Typography fontSize={14} fontWeight={600} ml={1.25}>
                        {t('operator')}:
                    </Typography>
                    <Typography fontSize={14} ml={0.625}>
                        {operator?.name || '-'}
                    </Typography>
                </Grid>
            </>
        ) : (
            <>
                <Grid item xs display="flex">
                    <Typography fontSize={14} fontWeight={600} ml={1.25}>
                        {t('quantity_pending')}:
                    </Typography>
                    <Typography fontSize={14} ml={0.625}>
                        {quantity - executed}
                    </Typography>
                </Grid>
                <Grid item xs display="flex">
                    <Typography fontSize={14} fontWeight={600} ml={1.25}>
                        {t('gross_import')}:
                    </Typography>
                    <Typography fontSize={14} ml={0.625}>
                        {convertCurrencyFormat(amount, ticker.currency) || '-'}
                    </Typography>
                </Grid>
                <Grid item xs display="flex">
                    <Typography fontSize={14} fontWeight={600} ml={1.25}>
                        {t('net_import')}:
                    </Typography>
                    <Typography fontSize={14} ml={0.625}>
                        {convertCurrencyFormat(net, ticker.currency) || '-'}
                    </Typography>
                </Grid>
                <Grid item xs display="flex">
                    <Typography fontSize={14} fontWeight={600} ml={1.25}>
                        {t('executed_import')}:
                    </Typography>
                    <Typography fontSize={14} ml={0.625}>
                        {convertCurrencyFormat(gross, ticker.currency) || '-'}
                    </Typography>
                </Grid>
            </>
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    row: GetMarketOrderItem;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OrderDetail.propTypes = propTypes;

export default OrderDetail;
