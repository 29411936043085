import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './WalletDepositPage.styled';
import { Step, StepLabel, Stepper, Box } from '@mui/material';
import DepositStepTwo from './DepositStepTwo';
import DepositStepThree from './DepositStepThree';
import DepositStepOne from './DepositStepOne';

const WalletDepositPage = (props: Props) => {
    const { t, activeStep, steps, setActiveStep } = props;

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <DepositStepOne activeStep={activeStep} setActiveStep={setActiveStep} />;
            case 1:
                return <DepositStepTwo activeStep={activeStep} setActiveStep={setActiveStep} />;
            case 2:
                return <DepositStepThree />;
            default:
                return '';
        }
    };

    return (
        <St.StyledContainer>
            <St.StyledPaper elevation={3}>
                <St.Title>{t('deposit_deposits')}</St.Title>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box>{getStepContent(activeStep)}</Box>
            </St.StyledPaper>
        </St.StyledContainer>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
};

interface extraProps {
    setActiveStep: (value: React.SetStateAction<number>) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WalletDepositPage.propTypes = propTypes;
WalletDepositPage;

export default WalletDepositPage;
