import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BidsOffersTable from './BidsOffersTable';
import { WSPricesData } from 'types/context/prices.context.types';
import { useTranslation } from 'react-i18next';
import { LimitPriceStateInfo } from 'types/pages/marketInstrumentDetail.types';
import { BUYSELL } from 'types/api/orders.types';

const BidsOffersTableContainer = (props: Props) => {
    const { setLimitIndex } = props;
    const { t } = useTranslation('market');

    const childProps = {
        ...props,
        t,
        setLimitIndex,
    };

    return <BidsOffersTable {...childProps} />;
};

const propTypes = {
    isBond: PropTypes.bool.isRequired,
};

interface extraProps {
    bids?: WSPricesData[] | null;
    setLimitIndex: (value: LimitPriceStateInfo | null) => void;
    offers?: WSPricesData[] | null;
    buySell: BUYSELL;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BidsOffersTableContainer.propTypes = propTypes;

export default BidsOffersTableContainer;
