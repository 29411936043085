/* eslint-disable camelcase */
import { BLOCKED_TICKETS } from 'constants/appConstants';
import { GetInstrumentsItem, Quotation, QuotationItem } from 'types/api/instruments.types';
import { formatTimestampToDate } from './dateHelper';
import { InstrumentPricesDetail } from 'types/pages/marketInstrumentDetail.types';
import { WSTable, WSTickerPrices } from 'types/context/prices.context.types';

const calculateQuotation = (baseQuote: WSTickerPrices, otherQuote: WSTickerPrices) => ({
    buy:
        baseQuote?.bids && otherQuote?.bids
            ? (baseQuote.bids[0]?.price || 1) / (otherQuote.bids[0]?.price || 1)
            : 0,
    sell:
        baseQuote?.offers && otherQuote?.offers
            ? (baseQuote.offers[0]?.price || 1) / (otherQuote.offers[0]?.price || 1)
            : 0,
});

const getUpdatedQuotations = (
    baseTicker: string | undefined,
    dolarTicker: string | undefined,
    cableTicker: string | undefined,
    tickerPrices: WSTickerPrices[],
): { mep: Quotation; cable: Quotation } | undefined => {
    if (!baseTicker || BLOCKED_TICKETS.includes(baseTicker)) return undefined;

    const tickersMap = tickerPrices.reduce(
        (acc, tickerPrice) => {
            acc[tickerPrice.ticker] = tickerPrice;
            return acc;
        },
        {} as Record<string, WSTickerPrices>,
    );

    const lastBaseQuote = tickersMap[baseTicker];
    const lastDolarQuote = tickersMap[dolarTicker || ''];
    const lastCableQuote = tickersMap[cableTicker || ''];

    return {
        mep: calculateQuotation(lastBaseQuote, lastDolarQuote),
        cable: calculateQuotation(lastBaseQuote, lastCableQuote),
    };
};

export const getLastTickerQuotationForHome = (
    quotations: QuotationItem[],
    tickerPrices24hs: WSTickerPrices[],
    tickerPricesCI: WSTickerPrices[],
) => {
    return quotations.map(({ instrument }: QuotationItem) => {
        const {
            ARS: baseTicker,
            USD: dolarTicker,
            'USD-C': cableTicker,
        } = instrument.tickers.reduce(
            (acc, { currency, ticker }) => {
                acc[currency] = ticker;
                return acc;
            },
            {} as Record<string, string | undefined>,
        );

        return {
            ticker: baseTicker!,
            ci: getUpdatedQuotations(baseTicker, dolarTicker, cableTicker, tickerPricesCI)!,
            hs24: getUpdatedQuotations(baseTicker, dolarTicker, cableTicker, tickerPrices24hs)!,
        };
    });
};

const buildTableData = (tickerPrice: WSTickerPrices): WSTable => {
    const {
        ticker,
        term,
        bids = [],
        offers = [],
        last,
        close_price,
        nominal_volume,
        opening_price,
        high_price,
        low_price,
        effective_volume,
        favorite,
    } = tickerPrice;

    const variation = close_price
        ? (((last?.price || 0) - close_price.price) * 100) / close_price.price
        : 0;

    return {
        ticker,
        term,
        buyPrice: bids?.[0]?.price || 0,
        buyQuantity: bids?.[0]?.size || 0,
        sellPrice: offers?.[0]?.price || 0,
        sellQuantity: offers?.[0]?.size || 0,
        lastPrice: last?.price || 0,
        variation: String(variation),
        nominalVolume: nominal_volume,
        openingPrice: opening_price,
        maximum: high_price,
        minimum: low_price,
        previous: close_price?.price || 0,
        tradedAmount: effective_volume,
        favorite,
    };
};

export const getLastTickerQuotationForTable = (
    instruments: GetInstrumentsItem[],
    tickerPrices: WSTickerPrices[],
): WSTable[] => {
    if (!instruments?.length || !tickerPrices?.length) return [];

    const instrumentsSubscribed = new Set(
        instruments.flatMap(({ tickers }) => tickers.map(({ ticker }) => ticker)),
    );

    const updateTickerPrices = tickerPrices.reduce<WSTable[]>((acc, tickerPrice) => {
        if (instrumentsSubscribed.has(tickerPrice.ticker)) {
            acc.push(
                buildTableData({
                    ...tickerPrice,
                    favorite:
                        instruments.find(el => el.tickers[0].ticker === tickerPrice.ticker)
                            ?.tickers[0].favorite ?? false,
                }),
            );
        }
        return acc;
    }, []);
    return updateTickerPrices;
};

export const getInstrumentDetailInfoAdapter = (
    // instruments: GetInstrumentsItem[],
    tickerPrices: WSTickerPrices[],
) => {
    const tickerPrice = tickerPrices[0] ?? null;
    return {
        lastUpdate: tickerPrice?.last?.date ? formatTimestampToDate(tickerPrice?.last?.date) : '-',
        price: tickerPrice?.last?.price,
        difference: tickerPrice?.last?.price - tickerPrice?.close_price?.price,
        differencePercentage:
            ((tickerPrice?.last?.price - tickerPrice?.close_price?.price) * 100) /
            tickerPrice?.close_price?.price,
        bids: tickerPrice?.bids,
        offers: tickerPrice?.offers,
        closePrice: tickerPrice?.close_price?.price,
    } as InstrumentPricesDetail;
};
