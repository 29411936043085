import { Box, styled, Typography } from '@mui/material';

export default {
    OptionContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start!important',
            justifyContent: 'flex-start!important',
            gap: '0.5rem',
        }),
    ),
    OptionName: styled(Typography)(({ theme }) => theme.unstable_sx({})),
    OptionCustomerCode: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            fontWeight: '700',
        }),
    ),
};
