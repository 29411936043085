import {
    Box,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import ActionButton from 'components/common/ActionButton';
import CustomRadioButton from 'components/common/CustomRadioButton';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    Stack: styled(
        Stack,
        transientOptions,
    )<{ $textHover: string; $disableBtn: boolean }>(({ theme, $textHover, $disableBtn }) =>
        theme.unstable_sx({
            '#btn2': $disableBtn
                ? {
                      position: 'relative',
                      cursor: 'default',
                      '&:hover::after': {
                          content: `'${$textHover}'`,
                          position: 'absolute',
                          bottom: '-48%',
                          left: '62%',
                          transform: 'translateX(-50%)',
                          backgroundColor: '#616161eb',
                          color: '#fff',
                          fontSize: '0.6875rem',
                          padding: '4px 8px',
                          border: '1px solid',
                          borderColor: theme.palette.divider,
                          borderRadius: '4px',
                          whiteSpace: 'nowrap',
                          zIndex: 1,
                      },
                  }
                : {},
        }),
    ),
    Typography: styled(Typography)(() => ({})) as typeof Typography,
    CuotaparteWrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'baseline',
        gap: '.8rem',
    })),
    CuotaparteCurrent: styled(Typography)(() => ({
        fontSize: pxToRem(26),
    })),
    CuotapartePrevious: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.lightGreen,
        fontWeight: '700',
        height: 'fit-content',
    })),
    AvailableContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        background: '#f5f5f5',
        padding: '.5rem 1rem',
    })),
    InputContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '10px',
    })),
    EstimatedPriceText: styled(Typography)(({ theme }) => ({
        color: theme.palette.custom.darkblue4,
    })),
    EstimatedPriceValue: styled(Typography)(({ theme }) => ({
        fontWeight: 700,
    })),
    RadioWrapper: styled(Box)(() => ({ margin: '0 !important' })),
    FormControlLabel: styled(FormControlLabel)(() => ({
        width: 'fit-content',
        span: {
            fontSize: pxToRem(14),
        },
    })) as typeof FormControlLabel,
    Radio: styled(Radio)(() => ({})),
    Checkbox: styled(Checkbox)(() => ({
        padding: '0 .4rem 0 0',
    })),
    ActionButtonWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            button: {
                width: { xs: '100%' },
                margin: '0 auto',
                px: '2.5rem',
            },
        }),
    ),
    ActionButton: styled(ActionButton)(() => ({})),
    SeeManagementRegulations: styled('a')(({ theme }) =>
        theme.unstable_sx({
            color: theme.palette.primary.main,
            fontSize: pxToRem(14),
            textDecoration: 'underline',
            cursor: 'pointer',
            width: 'fit-content',
            mx: 'auto !important',
        }),
    ),
    CustomRadioButtonWrapper: styled(Box)(() => ({
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
    })),
    CustomRadioButton: styled(CustomRadioButton)(() => ({})),
    Dialog: styled(Dialog)(() => ({})),
    DialogTitle: styled(DialogTitle)(() => ({ textAlign: 'center' })),
    DialogContent: styled(DialogContent)(() => ({
        maxWidth: '300px',
        textAlign: 'center',
        paddingBottom: '.5rem',
    })),
    DialogActions: styled(DialogActions)(() => ({
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '1rem',
        button: { marginTop: '0' },
    })),
};
