import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Form, FormikProvider } from 'formik';
import TopHeader from 'components/common/TopHeader';
import ComponentLi from './ComponentLi';
import ErrorText from 'components/common/ErrorText';
import { AutocompleteItem, Nationality, WorkActivity } from 'types/api/onboarding.types';
import { Tprop } from 'types/common/general.types';
import St from './OnboardingComplementaryDataPage.styled';

export const OnboardingComplementaryDataPage = (props: Props) => {
    const {
        t,
        formik,
        enableButton,
        handleDownload,
        itemsLi,
        VATConditionArticles,
        incomeRegistrationArticles,
        workActivities,
        countriesList,
        isLoadingSubmit,
        errorMessage,
    } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <St.SectionWrapper>
            <TopHeader
                firstElement={t('complementary_data_first_element') as string}
                title={t('complementary_data_title')}
            />
            <FormikProvider value={formik}>
                <Form>
                    <St.List>
                        {itemsLi.map((el, index) => (
                            <ComponentLi
                                key={index}
                                {...{ ...el, values, setFieldValue, countriesList, errors }}
                            />
                        ))}
                    </St.List>
                    <St.AutocompleteWrapper>
                        <St.CustomAutoComplete
                            id="taxCondition"
                            value={values.taxCondition}
                            onChange={(e, value) => setFieldValue('taxCondition', value)}
                            error={errors.taxCondition ?? false}
                            options={VATConditionArticles ?? []}
                            isLoading={false}
                            label={t('complementary_data_VAT_condition') as string}
                            placeholder={t('complementary_data_select_placeholder') as string}
                            renderOption={(props, option) => (
                                <St.NameWrapper {...props} key={option.id}>
                                    {option.name}
                                </St.NameWrapper>
                            )}
                        />
                        <St.CustomAutoComplete
                            id="earningsRegistration"
                            value={values.earningsRegistration}
                            onChange={(e, value) => setFieldValue('earningsRegistration', value)}
                            error={errors.earningsRegistration ?? false}
                            options={incomeRegistrationArticles ?? []}
                            isLoading={false}
                            label={t('complementary_data_income_registration') as string}
                            placeholder={t('complementary_data_select_placeholder') as string}
                            renderOption={(props, option) => (
                                <St.NameWrapper {...props} key={option.id}>
                                    {option.name}
                                </St.NameWrapper>
                            )}
                        />
                        <St.CustomAutoComplete
                            id="activityId"
                            value={values.activityId}
                            onChange={(e, value) => setFieldValue('activityId', value)}
                            error={errors.activityId ?? false}
                            options={workActivities ?? []}
                            isLoading={false}
                            label={t('complementary_data_work_activity') as string}
                            placeholder={t('complementary_data_select_placeholder') as string}
                            renderOption={(props, option) => (
                                <St.NameWrapper {...props} key={option.id}>
                                    {option.name}
                                </St.NameWrapper>
                            )}
                        />
                        <St.DownloadButton type="button" onClick={handleDownload}>
                            {t('complementary_data_btn_activities_download')}
                        </St.DownloadButton>
                        {errorMessage && <ErrorText text={errorMessage} />}
                        <St.LoadingButton
                            type="submit"
                            variant="contained"
                            disabled={!enableButton}
                            loading={isLoadingSubmit}>
                            {t('complementary_data_btn_confirm')}
                        </St.LoadingButton>
                    </St.AutocompleteWrapper>
                </Form>
            </FormikProvider>
        </St.SectionWrapper>
    );
};

const propTypes = {};

interface extraProps extends Tprop {
    formik: any;
    enableButton: boolean;
    itemsLi: Array<{
        formKey: string;
        inputKey?: string;
        inputType?: string;
        text: string;
        tooltipText: string;
    }>;
    VATConditionArticles: AutocompleteItem[];
    incomeRegistrationArticles: AutocompleteItem[];
    countriesList?: Nationality[];
    workActivities?: WorkActivity[];
    handleDownload: () => void;
    isLoadingSubmit: boolean;
    errorMessage: string | false;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingComplementaryDataPage.propTypes = propTypes;

export default OnboardingComplementaryDataPage;
