import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import HeaderFilters from './HeaderFilters';
import { useTranslation } from 'react-i18next';
import { MarketTableFilters } from 'types/context/prices.context.types';
import { AuthContext } from 'context/auth.context';

const HeaderFiltersContainer = React.memo(function HeadersFiltersContainerMemo(props: Props) {
    const {
        setFilters,
        table,
        filters,
        listOfCurrencies,
        filteredByTypeOptions,
        liquidityList,
        classList,
        isPreferred,
    } = props;
    const { userType } = React.useContext(AuthContext);
    const enableFiltersForClassAndPerson = ['CHANNEL', 'ASSISTANT'].includes(userType ?? '');
    const { t } = useTranslation('market');

    const handleChangeState = React.useCallback(
        (key: string, value: 'CI' | '24hs' | boolean | string | { id: string; name: string }) => {
            setFilters(prev => ({ ...prev, [key]: value }));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const childProps = React.useMemo(
        () => ({
            filters,
            listOfCurrencies,
            filteredByTypeOptions,
            liquidityList,
            classList,
            handleChangeState,
            table,
            enableFiltersForClassAndPerson,
            isPreferred,
            t,
        }),
        [
            filters,
            table,
            listOfCurrencies,
            filteredByTypeOptions,
            handleChangeState,
            enableFiltersForClassAndPerson,
            liquidityList,
            classList,
            isPreferred,
            t,
        ],
    );

    return <HeaderFilters {...childProps} />;
});

const propTypes = {};

interface extraProps {
    filters: MarketTableFilters;
    setFilters: React.Dispatch<React.SetStateAction<MarketTableFilters>>;
    filteredByTypeOptions: Array<{ id: string; name: string }>;
    listOfCurrencies: Array<{ id: string; name: string }>;
    liquidityList: Array<{ id: string; name: string }>;
    classList: Array<{ id: string; name: string }>;
    table: 'default' | 'funds';
    isPreferred: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}

export default HeaderFiltersContainer;
