import BlogPage from 'components/pages/BlogPage';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import i18n from 'utils/i18n';
import { mainRoutesPaths, onboardingRoutesPaths } from '../routesPaths';
import OnboardingHomePage from 'components/pages/OnboardingHomePage';
import OnboardingComplementaryDataPage from 'components/pages/OnboardingComplementaryDataPage';
import OnboardingValidatePersonPage from 'components/pages/OnboardingValidatePersonPage';
import OnboardingInvestmentTestPage from 'components/pages/OnboardingInvestmentTestPage';
import OnboardingContactInfoPage from 'components/pages/OnboardingContactInfoPage';
import OnboardingDniPhotoPage from 'components/pages/OnboardingDniPhotoPage';
import OnboardingPersonalDataPage from 'components/pages/OnboardingPersonalDataPage';
import OnboardingStepIncomeProofPage from 'components/pages/OnboardingStepIncomeProofPage';
import OnboardingContractPage from 'components/pages/OnboardingContractPage';
import ProfilePreferencesPage from 'components/pages/ProfilePreferencesPage';

export const onboardingRoutes: Array<SubRouteInfo> = [
    {
        name: 'onboarding',
        path: onboardingRoutesPaths.home,
        title: i18n.t('layout_main_navigation_onboarding_home') as string,
        component: OnboardingHomePage,
    },
    {
        name: 'blog',
        path: onboardingRoutesPaths.blog,
        title: i18n.t('layout_main_navigation_blog') as string,
        component: BlogPage,
    },
    {
        name: 'validate_person',
        path: onboardingRoutesPaths.validatePerson,
        title: i18n.t('layout_main_navigation_nosis') as string,
        component: OnboardingValidatePersonPage,
    },
    {
        name: 'personal_data',
        path: onboardingRoutesPaths.personalData,
        title: i18n.t('layout_main_navigation_nosis') as string,
        component: OnboardingPersonalDataPage,
    },
    {
        name: 'contact_info',
        path: onboardingRoutesPaths.contactInfo,
        title: i18n.t('layout_main_navigation_nosis') as string,
        component: OnboardingContactInfoPage,
    },
    {
        name: 'complementary_data',
        path: onboardingRoutesPaths.complementarydata,
        title: i18n.t('layout_main_navigation_nosis') as string,
        component: OnboardingComplementaryDataPage,
    },
    {
        name: 'upload_document',
        path: onboardingRoutesPaths.uploadDocument,
        title: i18n.t('layout_main_navigation_accounts') as string,
        component: OnboardingDniPhotoPage,
    },
    {
        name: 'investment_test',
        path: onboardingRoutesPaths.investmentTest,
        title: i18n.t('layout_main_navigation_nosis') as string,
        component: () => <OnboardingInvestmentTestPage />,
    },
    {
        name: 'proof_of_income',
        path: onboardingRoutesPaths.proofOfIncome,
        title: i18n.t('layout_main_navigation_nosis') as string,
        component: OnboardingStepIncomeProofPage,
    },
    {
        name: 'contract',
        path: onboardingRoutesPaths.contract,
        title: i18n.t('layout_main_navigation_nosis') as string,
        component: OnboardingContractPage,
    },
    {
        name: 'profile',
        path: mainRoutesPaths.profile,
        title: i18n.t('layout_main_navigation_profile') as string,
        subRoutes: [
            {
                name: 'preferences',
                path: mainRoutesPaths.preferences,
                title: i18n.t('layout_main_navigation_preferences') as string,
                component: ProfilePreferencesPage,
            },
            {
                name: 'deleteAccount',
                path: mainRoutesPaths.deleteAccount,
                title: i18n.t('layout_main_navigation_delete_account') as string,
                component: () => <h1>Eliminar cuenta</h1>,
            },
            {
                name: 'replacePassword',
                path: mainRoutesPaths.replacePassword,
                title: i18n.t('layout_main_navigation_replace_password') as string,
                component: () => <h1>Cambiar contraseña</h1>,
            },
        ],
    },
];
