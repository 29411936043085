import React from 'react';
import InstrumentDetails from './InstrumentDetails';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';

const InstrumentDetailsContainer = (props: Props) => {
    const { t, details, type } = props;
    const childProps = {
        ...props,
        t,
        details,
        type,
    };

    return <InstrumentDetails {...childProps} />;
};

const propTypes = {
    t: PropTypes.func.isRequired,
    details: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentDetailsContainer.propTypes = propTypes;

export default InstrumentDetailsContainer;
