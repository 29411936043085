import { Box, Typography, styled } from '@mui/material';

export default {
    SectionWrapper: styled(Box)(() => ({
        color: 'white',
        flex: 1,
        margin: '3.1rem 4rem',
    })) as typeof Box,
    ArticleWrapper: styled(Box)(() => ({
        color: 'white',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    })) as typeof Box,
    AsideWrapper: styled(Box)(() => ({
        flex: 1,
    })) as typeof Box,
    ParagraphWrapper: styled(Typography)(() => ({
        margin: ' 1rem 0',
        whiteSpace: 'pre-line',
        textAlign: 'center',
    })) as typeof Typography,
};
