import * as React from 'react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormLabel,
    TextField,
    Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './WithdrawalModal.styled';
import ActionButton from 'components/common/ActionButton';
import CustomAutoComplete from 'components/common/CustomAutoComplete';
import CurrencyInput from 'components/common/CurrencyInput';
import { FormikContextType, FormikProvider } from 'formik';
import { MultiCurrencyObj } from 'types/api/marketAccount.types';
import ErrorText from 'components/common/ErrorText';
import { Currency } from 'types/common/general.types';
import CustomLoader from 'components/common/CustomLoader';
import { mainRoutesPaths } from 'router/routesPaths';

const WithdrawalModal = (props: Props) => {
    const {
        t,
        formik,
        bankAccountsOptions,
        isLoading,
        isBankAccountsLoading,
        availableExtraction,
        currencyOptions,
        handleCurrencyChange,
        isLoadingWithdrawal,
        errorSubmit,
        close,
        handleSend,
    } = props;

    const { errors, values, setFieldValue } = formik;

    const selectedAccount = bankAccountsOptions?.find(
        item => item.id === values.destinationAccount,
    );

    const getCheckboxOptions = () => {
        if (['ARS', 'USD', 'USDM_T'].includes(values.currency)) {
            return [t('rescue_FCI'), t('no_money_available'), t('out_of_hours'), t('other')];
        } else if (['USDC', 'USDC_T', 'USDC_EXT'].includes(values.currency)) {
            return [t('no_money_available'), t('out_of_hours'), t('other')];
        }
        return [];
    };

    return (
        <FormikProvider value={formik}>
            <St.Form onSubmit={formik.handleSubmit}>
                {isLoading ? (
                    <CustomLoader />
                ) : (
                    <>
                        {availableExtraction &&
                            Object.entries(availableExtraction).filter(([, data]) => data > 0) && (
                                <>
                                    <FormLabel>{t('new_order_treasury_available')}</FormLabel>
                                    <Box
                                        display="grid"
                                        gridTemplateColumns="repeat(2, 1fr)"
                                        gap={1}>
                                        {availableExtraction &&
                                            Object.entries(availableExtraction)
                                                .filter(([, data]) => data > 0)
                                                .map(([currency, data]) => (
                                                    <Box key={currency}>
                                                        <Typography variant="body2">
                                                            {currency.toUpperCase()}: $
                                                            {data.toFixed(2)}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                    </Box>
                                </>
                            )}
                    </>
                )}
                <FormLabel>{t('currency')}</FormLabel>
                <CustomAutoComplete
                    isLoading={isLoading}
                    id="currency"
                    value={currencyOptions.find(option => option.id === values.currency) ?? null}
                    onChange={handleCurrencyChange}
                    error={errors.currency && errors.currency.toString()}
                    options={currencyOptions ?? []}
                    label={t('currency')}
                    placeholder={t('currency')}
                    renderOption={(props, option) => (
                        <Box {...props} key={option.id}>
                            {option.name}
                        </Box>
                    )}
                    helperText={errors.currency && errors.currency.toString()}
                />
                <CustomAutoComplete
                    isLoading={isBankAccountsLoading}
                    id="destinationAccount"
                    value={
                        values.destinationAccount
                            ? bankAccountsOptions?.find(
                                  item => item.id === values.destinationAccount,
                              )
                            : null
                    }
                    onChange={(e, value) => {
                        setFieldValue('destinationAccount', value?.id);
                    }}
                    options={bankAccountsOptions}
                    label={t('destination_account')}
                    getOptionLabel={option =>
                        `(${option.description}) ${option.name} (${option.currency})`
                    }
                    renderOption={(props, option) => (
                        <Box {...props} key={option.id}>
                            ({option.description}) {option.name} ({option.currency})
                        </Box>
                    )}
                    helperText={errors.destinationAccount && errors.destinationAccount.toString()}
                    error={errors.destinationAccount && errors.destinationAccount.toString()}
                />
                {bankAccountsOptions?.length === 0 && values.currency && (
                    <>
                        <Typography variant="body2">
                            {`${t('bank_accounts_currency_avaiable', {
                                currency: values.currency?.toString(),
                            })}`}
                        </Typography>
                        <St.StyledLink
                            variant="body2"
                            paragraph
                            href={mainRoutesPaths.bankAccounts}>
                            {t('manage_my_bank_accounts')}
                        </St.StyledLink>
                    </>
                )}

                {getCheckboxOptions().length > 0 && (
                    <FormGroup>
                        {getCheckboxOptions().map(option => (
                            <FormControlLabel
                                key={option}
                                control={
                                    <Checkbox
                                        checked={values.options?.includes(option)}
                                        onChange={e => {
                                            const isChecked = e.target.checked;
                                            const updatedOptions = isChecked
                                                ? [...(values.options || []), option]
                                                : values.options?.filter(
                                                      (opt: string) => opt !== option,
                                                  );
                                            setFieldValue('options', updatedOptions);
                                        }}
                                    />
                                }
                                label={t(option)}
                            />
                        ))}
                        <Typography variant="body2" color="primary" textAlign={'center'} m={2}>
                            {t('extract_time')}
                        </Typography>

                        {values.options?.length > 0 && (
                            <TextField
                                id="comment"
                                label={t('comment')}
                                value={values.comment}
                                onChange={e => setFieldValue('comment', e.target.value)}
                                error={Boolean(errors.comment)}
                                placeholder={t('comment_placeholder')}
                                fullWidth
                                multiline
                                rows={3}
                                helperText={errors.comment && errors.comment.toString()}
                            />
                        )}
                    </FormGroup>
                )}

                <FormLabel>{t('amount')}</FormLabel>
                <CurrencyInput
                    value={values.amountToExtract}
                    currency={selectedAccount ? (selectedAccount.currency as Currency) : 'ARS'}
                    error={errors.amountToExtract as string | null}
                    handleChange={value => setFieldValue('amountToExtract', value)}
                    helperText={errors.amountToExtract && errors.amountToExtract.toString()}
                />
            </St.Form>
            <Box display="flex" justifyContent="end" gap={2}>
                <ActionButton
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={close}
                    disabled={isLoadingWithdrawal}>
                    {t('cancel')}
                </ActionButton>
                <ActionButton
                    size="small"
                    variant="contained"
                    color="primary"
                    loading={isLoadingWithdrawal}
                    onClick={handleSend}
                    disabled={!values.currency || isLoadingWithdrawal}>
                    {t('sent')}
                </ActionButton>
            </Box>
            {errorSubmit && <ErrorText text={errorSubmit} />}
        </FormikProvider>
    );
};
const propTypes = {
    t: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    handleSend: PropTypes.func.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    bankAccountsOptions: {
        name: string;
        id: string;
        description: string;
        currency: string;
    }[];
    isLoading: boolean;
    isBankAccountsLoading: boolean;
    availableExtraction: MultiCurrencyObj | undefined;
    currencyOptions: { id: string; name: string }[];
    handleCurrencyChange: (event: React.SyntheticEvent, newValue: any) => void;
    isLoadingWithdrawal: boolean;
    errorSubmit: string | null;
    close(): void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WithdrawalModal.propTypes = propTypes;

export default WithdrawalModal;
