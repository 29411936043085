import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './BidsOffersTable.styled';
import { WSPricesData } from 'types/context/prices.context.types';
import { TableBody, TableHead, TableRow } from '@mui/material';
import { LimitPriceStateInfo } from 'types/pages/marketInstrumentDetail.types';
import { BUYSELL } from 'types/api/orders.types';
import { formatNumber } from 'utils/helpers/commonHelper';

const BidsOffersTable = (props: Props) => {
    const { bids, buySell, offers, t, setLimitIndex, isBond } = props;

    return (
        <St.TableContainer sx={{ background: 'transparent' }}>
            <St.TableHeader>
                <St.TableText variant="h5">{t('market_table_buy_text')}</St.TableText>
                <St.TableText variant="h5">{t('market_table_sell_text')}</St.TableText>
            </St.TableHeader>
            <St.Divider />
            <St.Table aria-label="prices">
                <TableHead>
                    <TableRow>
                        <St.HeaderCell>{t('market_table_quantity')}</St.HeaderCell>
                        <St.HeaderCell>{t('market_table_price')}</St.HeaderCell>
                        <St.HeaderCell>{t('market_table_price')}</St.HeaderCell>
                        <St.HeaderCell>{t('market_table_quantity')}</St.HeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[0, 1, 2, 3, 4].map(element => {
                        const bid = bids ? bids[element] : null;
                        const offer = offers ? offers[element] : null;
                        return (
                            <TableRow key={element}>
                                <St.Cell $canBeSelected={buySell === 'SELL'}>
                                    {offer?.size ? formatNumber(offer.size, isBond ? 3 : 2) : '-'}
                                </St.Cell>
                                <St.Cell
                                    $isPrice
                                    $canBeSelected={buySell === 'SELL'}
                                    onClick={() =>
                                        setLimitIndex({ type: 'SELL', value: element ?? 0 })
                                    }>
                                    {offer?.price ? formatNumber(offer.price, isBond ? 3 : 2) : '-'}
                                </St.Cell>
                                <St.Cell
                                    $isPrice
                                    $canBeSelected={buySell === 'BUY'}
                                    onClick={() =>
                                        setLimitIndex({ type: 'BUY', value: element ?? 0 })
                                    }>
                                    {bid?.price ? formatNumber(bid.price, isBond ? 3 : 2) : '-'}
                                </St.Cell>
                                <St.Cell $canBeSelected={buySell === 'BUY'}>
                                    {bid?.size ? formatNumber(bid?.size, isBond ? 3 : 2) : '-'}
                                </St.Cell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </St.Table>
        </St.TableContainer>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isBond: PropTypes.bool.isRequired,
};

interface extraProps {
    buySell: BUYSELL;
    bids?: WSPricesData[] | null;
    offers?: WSPricesData[] | null;
    setLimitIndex: (value: LimitPriceStateInfo | null) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BidsOffersTable.propTypes = propTypes;

export default BidsOffersTable;
