import { axiosDataInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import { INSTRUMENTS_BASE_URL } from 'constants/appConstants';
import {
    GetFundDetailBody,
    GetFundDetailResponse,
    GetFundsResponse,
    GetHistoricalPricesResponse,
    GetInstrumentsByTickerResponse,
    GetInstrumentsResponse,
    GetMarketTimesResponse,
    GetQuotationsResponse,
    PostFavoritesBody,
} from 'types/api/instruments.types';

const apiName = 'instruments';

export default {
    getInstruments: (filterQueryString: string) =>
        withFakeData<GetInstrumentsResponse>(
            () =>
                axiosDataInstance.get(
                    `${INSTRUMENTS_BASE_URL}/instruments/?${filterQueryString}&page=1&pageSize=400`,
                ),
            apiName,
            'instruments',
            false,
            500,
        ),
    getTagsForInstruments: () =>
        withFakeData<GetInstrumentsResponse>(
            () => axiosDataInstance.get(`${INSTRUMENTS_BASE_URL}/instruments/tags`),
            apiName,
            'tags-instruments',
            false,
            500,
        ),
    getInstrumentByticker: (ticker: string) =>
        withFakeData<GetInstrumentsByTickerResponse>(
            () => axiosDataInstance.get(`${INSTRUMENTS_BASE_URL}/instruments/${ticker}`),
            apiName,
            'instruments',
            false,
            500,
        ),
    getQuotations: () =>
        withFakeData<GetQuotationsResponse>(
            () => axiosDataInstance.get(`${INSTRUMENTS_BASE_URL}/quotations`),
            apiName,
            'getQuotations',
            false,
            500,
        ),
    postFavorites: (req: PostFavoritesBody) =>
        withFakeData(
            () => axiosDataInstance.post(`${INSTRUMENTS_BASE_URL}/ticker/favorites`, req),
            apiName,
            'postFavorites',
            false,
            500,
        ),
    deleteFavorites: (req: PostFavoritesBody) =>
        withFakeData(
            () => axiosDataInstance.post(`${INSTRUMENTS_BASE_URL}/ticker/favorites/delete`, req),
            apiName,
            'deleteFavorites',
            false,
            500,
        ),
    fundDetail: (req: GetFundDetailBody) =>
        withFakeData<GetFundDetailResponse>(
            () => axiosDataInstance.get(`${INSTRUMENTS_BASE_URL}/funds/${req.id}`),
            apiName,
            'fundDetail',
            false,
            500,
        ),
    getFunds: () =>
        withFakeData<GetFundsResponse[]>(
            () => axiosDataInstance.get(`${INSTRUMENTS_BASE_URL}/funds`),
            apiName,
            'fundDetail',
            false,
            500,
        ),
    historicalPrices: (id: string, filterQueryString: string) =>
        withFakeData<GetHistoricalPricesResponse>(
            () =>
                axiosDataInstance.get(
                    `${INSTRUMENTS_BASE_URL}/historical/${id}?${filterQueryString}`,
                ),
            apiName,
            'historicalPrices',
            false,
            500,
        ),
    getMarketsTimes: () =>
        withFakeData<GetMarketTimesResponse[]>(
            () => axiosDataInstance.get(`${INSTRUMENTS_BASE_URL}/markets/`),
            apiName,
            'fundDetail',
            false,
            500,
        ),
};
