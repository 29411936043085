import { withFakeData } from 'api/fakedata';
import { axiosDataInstance } from 'api/axiosInstances';
import { GetBlogResponse } from 'types/api/wrapper.types';

const apiName = 'wrapper';
const WRAPPER_BASE_URL = '/wrapper/v1_0';
const WRAPPER_BLOG_BASE_URL = `${WRAPPER_BASE_URL}/blogs`;

export default {
    getBlogs: () =>
        withFakeData<GetBlogResponse>(
            () => axiosDataInstance.get(`${WRAPPER_BLOG_BASE_URL}`),
            apiName,
            'getBlogs',
            false,
            500,
        ),
    getLatestBlogs: () =>
        withFakeData<GetBlogResponse>(
            () => axiosDataInstance.get(`${WRAPPER_BLOG_BASE_URL}/latest`),
            apiName,
            'getLatestBlogs',
            false,
            500,
        ),
};
