import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PropTypes from 'prop-types';
import St from './OnboardingStepIncomeProofPage.styled';
import { Form, FormikContextType, FormikProvider } from 'formik';
import { Grid, List, ListItem } from '@mui/material';
import FileUploader from 'components/common/FileUploader';
import TopHeader from 'components/common/TopHeader';
import ErrorText from 'components/common/ErrorText';

export const OnboardingStepIncomeProofPage = (props: Props) => {
    const { t, formik, enableButton, proofListText, errorMessage, isLoading } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <St.IncomeProof>
            <TopHeader
                firstElement={t('valid_proof_title') as string}
                title={t('valid_proof_subtitle')}
            />
            <FormikProvider value={formik}>
                <Form>
                    <St.IncomeProofInternalBox>
                        <St.IncomeProofText variant="h4">
                            {t('valid_proof_list_text')}
                        </St.IncomeProofText>
                        <List sx={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            {proofListText?.map((proof: string, index: number) => (
                                <ListItem
                                    key={index}
                                    disableGutters
                                    sx={{ display: 'list-item', padding: 0 }}>
                                    <St.ListItemText primary={t(proof)} />
                                </ListItem>
                            ))}
                        </List>
                        <St.IncomeProofInputs>
                            <FileUploader
                                values={values}
                                errors={errors}
                                setFieldValue={setFieldValue as any}
                                label={t('valid_proof')}
                                field="proof"
                            />
                            {!!values?.proof && (
                                <FileUploader
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue as any}
                                    label={t('valid_proof_number_two')}
                                    field="second_proof"
                                />
                            )}
                            {!!values?.second_proof && (
                                <FileUploader
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue as any}
                                    label={t('valid_proof_number_three')}
                                    field="third_proof"
                                />
                            )}
                        </St.IncomeProofInputs>
                        <St.IncomeProofButtonsBox>
                            <Grid container justifyContent={'center'}>
                                <Grid item>
                                    <St.OnBoardingStepButton
                                        type="submit"
                                        variant="contained"
                                        disabled={!enableButton}
                                        loading={isLoading}
                                        fullWidth>
                                        {t('next_button', { ns: 'auth' })}
                                    </St.OnBoardingStepButton>
                                </Grid>
                            </Grid>
                        </St.IncomeProofButtonsBox>
                        {errorMessage ? <ErrorText text={errorMessage} /> : null}
                    </St.IncomeProofInternalBox>
                </Form>
            </FormikProvider>
        </St.IncomeProof>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    enableButton: boolean;
    proofListText: string[];
    errorMessage: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingStepIncomeProofPage.propTypes = propTypes;

export default OnboardingStepIncomeProofPage;
