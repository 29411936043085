import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './AccountStatus.styled';
import { Grid } from '@mui/material';
import AccountInformation from './AccountInformation';
import CircularSpinner from '../CircularSpinner';
import {
    AccountAum,
    AccountPerformance,
    AvailableBalance,
    GetAccountStatusResponse,
    MarketAccount,
} from 'types/api/marketAccount.types';
import CustomPieChart from '../CustomPieChart';
import CustomRadioButton from 'components/common/CustomRadioButton';
import CustomIconCreator from '../CustomIconCreator';
import UserIcon from 'assets/icons/user-line.svg?react';
import ClientInformation from './ClientInformation';

const AccountStatus = (props: Props) => {
    const {
        isLoading,
        aum,
        availableBalance,
        performance,
        series,
        labels,
        total,
        isWallet,
        concertation,
        setConcertation,
        liquidation,
        setLiquidation,
        setSelectedInstrumentType,
        t,
        marketAccounts,
        isUserApAc,
        data,
    } = props;

    return (
        <St.AccountStatusWrapper>
            <St.AccountStatusContainer>
                <Grid container gap={'20px'} alignItems="center">
                    {isLoading ? (
                        <CircularSpinner />
                    ) : (
                        <>
                            <Grid item xs={12} lg={4}>
                                <AccountInformation
                                    aum={aum}
                                    availableBalance={availableBalance}
                                    performance={performance}
                                    isWallet={isWallet}
                                    isUserApAc={isUserApAc}
                                />
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                {isUserApAc && !isWallet ? (
                                    <St.ClientAmountContainer>
                                        <CustomIconCreator icon={UserIcon} alt="user-icon" />
                                        <St.ClientAmount typography="h6">
                                            {t('clients_number', {
                                                clients: marketAccounts?.length,
                                            })}
                                        </St.ClientAmount>
                                    </St.ClientAmountContainer>
                                ) : null}
                                {isUserApAc && isWallet ? (
                                    <ClientInformation t={t} marketAccounts={marketAccounts} />
                                ) : null}
                                {data?.aum.total.ars === 0 && data?.aum.total.ars === 0 ? (
                                    <St.NoDataChartContainer>
                                        <St.Amount typography="h4">0,00</St.Amount>
                                        <St.TotalText typography="body1">
                                            {t('total_pie_chart')}
                                        </St.TotalText>
                                    </St.NoDataChartContainer>
                                ) : (
                                    <CustomPieChart
                                        id="big-chart"
                                        series={series}
                                        labels={labels}
                                        width={'90%'}
                                        total={total}
                                        setInstrumentType={setSelectedInstrumentType || undefined}
                                    />
                                )}

                                {isWallet && (
                                    <>
                                        <St.WalletTitle variant="h6" color={'black'}>
                                            {t('wallet_title')}
                                        </St.WalletTitle>
                                        <St.RadioButtonContainer>
                                            <CustomRadioButton
                                                checked={concertation}
                                                name="wallet_concertation"
                                                onChange={() => {
                                                    setConcertation(true);
                                                    setLiquidation(false);
                                                }}
                                                text={t('wallet_concertation')}
                                                value="concertation"
                                            />
                                            <CustomRadioButton
                                                checked={liquidation}
                                                name="wallet_liquidation"
                                                onChange={() => {
                                                    setLiquidation(true);
                                                    setConcertation(false);
                                                }}
                                                text={t('wallet_liquidation')}
                                                value="liquidation"
                                            />
                                        </St.RadioButtonContainer>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            </St.AccountStatusContainer>
        </St.AccountStatusWrapper>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    total: PropTypes.string.isRequired,
    isWallet: PropTypes.bool,
    concertation: PropTypes.bool.isRequired,
    liquidation: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
};

interface extraProps {
    aum?: AccountAum;
    availableBalance?: AvailableBalance;
    performance?: AccountPerformance;
    series: number[];
    labels: string[];
    setConcertation: (value: boolean) => void;
    setLiquidation: (value: boolean) => void;
    setSelectedInstrumentType?: React.Dispatch<React.SetStateAction<string>>;
    data: GetAccountStatusResponse | undefined;
    isUserApAc: boolean;
    marketAccounts: MarketAccount[] | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
AccountStatus.propTypes = propTypes;

export default AccountStatus;
