import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InstrumentBuySell.styled';
import { Stack, Typography } from '@mui/material';
import CustomInput from 'components/common/CustomInput';
import { CommonSelectType, Currency } from 'types/common/general.types';
import ActionButton from 'components/common/ActionButton';
import MarketTermCurrencyUpdate from 'components/common/MarketTermCurrencyUpdate';
import {
    GetInstrumentsItem,
    InstrumentRule,
    TickerRequieresDeclaration,
} from 'types/api/instruments.types';
import BuySellSwitch from '../../../common/BuySellSwitch';
import { REGEX_0_TO_9 } from 'utils/helpers/constants';
import { BUYSELL, GetMarketPreviewResponse } from 'types/api/orders.types';
import ErrorText from 'components/common/ErrorText';
import BuySellPrevisualizeModal from './BuySellPrevisualizeModal';
import { tErrorsContext } from 'constants/appConstants';
import CurrencyInput from 'components/common/CurrencyInput';

const InstrumentBuySell = (props: Props) => {
    const {
        t,
        term,
        amount,
        isBond,
        minimum,
        buySell,
        setTerm,
        quantity,
        currency,
        setAmount,
        available,
        setBuySell,
        currencies,
        instrument,
        limitPrice,
        currentTerm,
        setCurrency,
        setQuantity,
        errorMessage,
        isMarketPrice,
        setLimitPrice,
        marketPreview,
        blockBuyRules,
        disableButton,
        blockSellRules,
        estimatedPrice,
        setCurrentTerm,
        currentCurrency,
        isLoadingSubmit,
        isLoadingProfile,
        errorProfileInfo,
        requiresDeclaration,
        isHigherThanAvailable,
        openPrevisualizeModal,
        dontHaveExteriorAccount,
        setOpenPrevisualizeModal,
        handleOpenPrevisualizeModal,
    } = props;

    return (
        <St.Container>
            <Stack spacing={2}>
                <BuySellSwitch
                    active={buySell === 'SELL'}
                    setActive={(value: boolean) => setBuySell(value ? 'SELL' : 'BUY')}
                />
                <MarketTermCurrencyUpdate
                    currencies={currencies}
                    currentCurrency={currentCurrency}
                    currentTerm={currentTerm}
                    instrument={instrument}
                    setCurrentTerm={setCurrentTerm}
                    currency={currency}
                    disableButton={disableButton}
                    setCurrency={setCurrency}
                    setTerm={setTerm}
                    term={term}
                />
                <St.AvailableContainer>
                    <Typography variant="body1">
                        {t('available_balance_text', {
                            term: currentTerm === 'CI' ? t('operate_immediate') : currentTerm,
                        })}
                    </Typography>
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        {buySell === 'BUY' ? (currentCurrency === 'ARS' ? '$' : 'US$') : ''}
                        {available || 0}
                    </Typography>
                </St.AvailableContainer>
                {isBond && (
                    <St.Typography variant="caption">
                        {t('bond_disclaimer_text', { minimum })}
                    </St.Typography>
                )}
                <St.InputContainer>
                    <Typography variant="body2" fontWeight={700} flex={1}>
                        {t('limit_price_input_label')}
                    </Typography>
                    <CurrencyInput
                        currency={currentCurrency}
                        handleChange={value => setLimitPrice(value)}
                        sx={{ width: '50%' }}
                        value={limitPrice}
                        decimalScale={isBond ? 3 : 2}
                    />
                </St.InputContainer>
                {buySell === 'BUY' && (
                    <St.InputContainer>
                        <Typography variant="body2" fontWeight={700}>
                            {t('amount_input_label')}
                        </Typography>
                        <CurrencyInput
                            currency={currentCurrency}
                            handleChange={value => setAmount(value)}
                            sx={{ width: '50%' }}
                            value={amount}
                            decimalScale={isBond ? 3 : 2}
                        />
                    </St.InputContainer>
                )}
                <St.InputContainer>
                    <Typography variant="body2" fontWeight={700}>
                        {t('quantity_input_label')}
                    </Typography>
                    <CustomInput
                        field="quantity"
                        values={{ quantity }}
                        handleChange={value => setQuantity(value)}
                        sx={{ width: '50%' }}
                        regex={REGEX_0_TO_9}
                    />
                </St.InputContainer>
                <St.AvailableContainer>
                    <St.EstimatedPriceText variant="body1">
                        {t('estimated_price')}
                    </St.EstimatedPriceText>
                    <St.EstimatedPriceValue variant="body1">
                        {currentCurrency === 'ARS' ? '$' : 'US$'}
                        {estimatedPrice ?? '0'}
                    </St.EstimatedPriceValue>
                </St.AvailableContainer>
                <ActionButton
                    loading={isLoadingSubmit || isLoadingProfile}
                    onClick={() => handleOpenPrevisualizeModal()}
                    variant="contained"
                    sx={{ width: '100%' }}
                    disabled={
                        !quantity ||
                        !disableButton ||
                        isLoadingProfile ||
                        quantity < minimum ||
                        isHigherThanAvailable ||
                        dontHaveExteriorAccount ||
                        (errorProfileInfo && currentCurrency === 'USD-C') ||
                        (buySell === 'BUY' && !!blockBuyRules?.length) ||
                        (buySell === 'SELL' && !!blockSellRules?.length)
                    }>
                    <Typography variant="body1">{t('previsualize_button_text')}</Typography>
                </ActionButton>
                {blockBuyRules?.length
                    ? blockBuyRules.map(r => (
                          <ErrorText
                              sx={{ marginBottom: '0.5rem' }}
                              key={r.reason}
                              text={t('rules_error', { reason: r.reason })}
                          />
                      ))
                    : null}
                {blockSellRules?.length
                    ? blockSellRules.map(r => (
                          <ErrorText
                              sx={{ marginBottom: '0.5rem' }}
                              key={r.reason}
                              text={t('rules_error', { reason: r.reason })}
                          />
                      ))
                    : null}
                {isHigherThanAvailable && (
                    <ErrorText
                        sx={{ marginBottom: '0.5rem' }}
                        text={t('insufficient_balance_local_warning', tErrorsContext)}
                    />
                )}
                {dontHaveExteriorAccount && (
                    <ErrorText
                        sx={{ marginBottom: '0.5rem' }}
                        text={t('error_has_exterior_account', tErrorsContext)}
                    />
                )}
                {quantity !== 0 && quantity !== null && Boolean(quantity < minimum) && (
                    <ErrorText
                        sx={{ marginBottom: '0.5rem' }}
                        text={t('quantity_lower_than_minimum', tErrorsContext)}
                    />
                )}
                {errorProfileInfo && currentCurrency === 'USD-C' && (
                    <ErrorText sx={{ marginBottom: '0.5rem' }} text={errorProfileInfo} />
                )}
                {errorMessage && <ErrorText sx={{ marginBottom: '0.5rem' }} text={errorMessage} />}
                {marketPreview && (
                    <BuySellPrevisualizeModal
                        buySell={buySell}
                        currency={currentCurrency}
                        open={openPrevisualizeModal}
                        isMarketPrice={isMarketPrice}
                        marketPreview={marketPreview}
                        isBond={isBond}
                        instrumentType={instrument?.type}
                        instrumentName={instrument?.name ?? ''}
                        requiresDeclaration={requiresDeclaration}
                        close={() => setOpenPrevisualizeModal(false)}
                        tickerId={
                            instrument?.tickers.filter(t => t.currency == currentCurrency)[0].id
                        }
                    />
                )}
            </Stack>
        </St.Container>
    );
};

const propTypes = {
    isBond: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    setBuySell: PropTypes.func.isRequired,
    available: PropTypes.string.isRequired,
    setCurrentTerm: PropTypes.func.isRequired,
    setAmount: PropTypes.func.isRequired,
    setQuantity: PropTypes.func.isRequired,
    setLimitPrice: PropTypes.func.isRequired,
    handleOpenPrevisualizeModal: PropTypes.func.isRequired,
    setOpenPrevisualizeModal: PropTypes.func.isRequired,
    isLoadingSubmit: PropTypes.bool.isRequired,
    openPrevisualizeModal: PropTypes.bool.isRequired,
    isMarketPrice: PropTypes.bool.isRequired,
    isHigherThanAvailable: PropTypes.bool.isRequired,
    minimum: PropTypes.number.isRequired,
    dontHaveExteriorAccount: PropTypes.bool.isRequired,
    isLoadingProfile: PropTypes.bool.isRequired,
    disableButton: PropTypes.bool.isRequired,
};

interface extraProps {
    currentCurrency: Currency;
    currentTerm: 'CI' | '24hs';
    instrument: GetInstrumentsItem | null;
    currencies: CommonSelectType[] | null;
    limitPrice: number;
    quantity: number | null;
    amount: number | null;
    estimatedPrice: string;
    errorMessage: string | null;
    errorProfileInfo: string | null;
    buySell: BUYSELL;
    marketPreview?: GetMarketPreviewResponse;
    requiresDeclaration: TickerRequieresDeclaration;
    blockBuyRules?: InstrumentRule[];
    blockSellRules?: InstrumentRule[];
    currency: CommonSelectType | null;
    setCurrency: (value: CommonSelectType | null) => void;
    term: CommonSelectType | null;
    setTerm: (value: CommonSelectType | null) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentBuySell.propTypes = propTypes;

export default InstrumentBuySell;
