import { Box, styled } from '@mui/material';

export default {
    CarouselContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: 'fit-content',
            cursor: 'grab',
            ':active': {
                cursor: 'grabbing',
            },
        }),
    ),
};
