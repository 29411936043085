import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import LearnToInvestCards from './LearnToInvestCards';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mainRoutesPaths, onboardingRoutesPaths } from 'router/routesPaths';
// import { useGetBlogNews } from 'hooks/api/onboarding.hooks';

const LearnToInvestCardsContainer = (props: Props) => {
    const { isOnboarding = false } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    // const { data: blogNews, isLoading: isLoadingNews } = useGetBlogNews();
    const handleClickSeeMore = () =>
        navigate(isOnboarding ? onboardingRoutesPaths.blog : mainRoutesPaths.blog);

    const blogNews = [
        {
            text: 'Informe diario Precios al 6 de Junio',
            url: 'https://api.consultatiofinancialservices.com/wp-content/uploads/2023/08/Diario-05_Thumbnail.jpg?w=384&q=80 1x, https://api.consultatiofinancialservices.com/wp-content/uploads/2023/08/Diario-05_Thumbnail.jpg?w=640&q=80 2x',
        },
        {
            text: 'Flashback 2019: las dos caras del saneamiento',
            url: 'https://api.consultatiofinancialservices.com/wp-content/uploads/2024/05/Weekly_Thumbnail-3.jpg?w=384&q=80 1x, https://api.consultatiofinancialservices.com/wp-content/uploads/2024/05/Weekly_Thumbnail-3.jpg?w=640&q=80 2x',
        },
        {
            text: 'Abrimos la recomendación del Consultatio Ahorro Plus, nuestro nuevo fondo de Lecaps',
            url: 'https://api.consultatiofinancialservices.com/wp-content/uploads/2023/11/Weekly_Thumbnail-31.jpg?w=640&q=80',
        },
    ];

    const childProps = {
        ...props,
        t,
        blogNews,
        isLoadingNews: false,
        handleClickSeeMore,
    };

    return <LearnToInvestCards {...childProps} />;
};

const propTypes = {
    isOnboarding: PropTypes.bool,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LearnToInvestCardsContainer.propTypes = propTypes;

export default LearnToInvestCardsContainer;
