import { tCommon } from 'constants/appConstants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MarketTableInstrumentType } from 'types/api/instruments.types';

const tBase = 'market_table_';

const useFilters = () => {
    const { t } = useTranslation('market');
    const { filteredByTypeOptions, listOfCurrencies, liquidityList, classList } =
        React.useMemo(() => {
            const filteredByTypeOptions: {
                [key in MarketTableInstrumentType]: Array<{ id: string; name: string }>;
            } = {
                EQUITY: [
                    { id: 'MERVAL', name: 'Merval' },
                    { id: 'GENERAL', name: 'General' },
                ],
                BOND: [
                    { id: 'SOVEREIGN', name: t(`${tBase}sovereign`) },
                    { id: 'PROVINCIAL', name: t(`${tBase}provincial`) },
                    { id: 'CORPORATE', name: t(`${tBase}corporate`) },
                    { id: 'LETTER', name: t(`${tBase}letter`) },
                    { id: 'CUPON', name: t(`${tBase}cupon`) },
                ],
                CERTIFICATE: [
                    {
                        id: 'FINANCIAL&tags=MATERIALS&tags=ENERGY&tags=INDUSTRY&tags=COMSNTEC&tags=CONSUMER&tags=HEALT&tags=ETF&tags=OTHER',
                        name: t(`${tBase}all`),
                    },
                    { id: 'FINANCIAL', name: t(`${tBase}financial`) },
                    { id: 'MATERIALS', name: t(`${tBase}materials`) },
                    { id: 'ENERGY', name: t(`${tBase}energy`) },
                    { id: 'INDUSTRY', name: t(`${tBase}industry`) },
                    { id: 'COMSNTEC', name: t(`${tBase}comsntec`) },
                    { id: 'CONSUMER', name: t(`${tBase}consumer`) },
                    { id: 'HEALTH', name: t(`${tBase}healt`) },
                    { id: 'ETF', name: 'ETFs' },
                    { id: 'OTHER', name: t(`${tBase}other`) },
                ],
                CURRENCY: [],
                EXTERIOR: [],
                EXTERIOR_BOND: [],
                EXTERIOR_FUND: [],
                EXTERIOR_ON: [],
                ADR: [],
                FUND: [
                    { id: 'FIXED_INCOME', name: 'Renta fija' },
                    { id: 'MIXED_INCOME', name: 'Renta mixta' },
                    { id: 'VARIABLE_INCOME', name: 'Renta variable' },
                ],
                LETTER: [],
                OTHER: [],
                PREFERRED: [
                    { id: 'CERTIFICATE&type=BOND&type=EQUITY', name: t(`${tBase}all`) },
                    { id: 'EQUITY', name: t('EQUITY', tCommon) },
                    { id: 'CERTIFICATE', name: t('CERTIFICATE', tCommon) },
                    { id: 'BOND', name: t('BOND', tCommon) },
                ],
            };
            const listOfCurrencies = [
                { id: '', name: t(`${tBase}all_currency`) },
                { id: 'ARS', name: 'ARS' },
                { id: 'USD', name: 'USD' },
                { id: 'USD-C', name: 'USD-C' },
            ];
            const liquidityList = [
                { id: 'all', name: t(`${tBase}all`) },
                { id: 'Inmediato', name: 'Inmediato' },
                { id: '24 horas', name: '24 horas' },
                { id: '48 horas', name: '48 horas' },
                { id: '72 horas', name: '72 horas' },
            ];
            const classList = [
                { id: 'CLASS_A', name: 'Clase A' },
                { id: 'CLASS_B', name: 'Clase B' },
                { id: 'CLASS_C', name: 'Clase C' },
            ];

            return {
                filteredByTypeOptions,
                listOfCurrencies,
                liquidityList,
                classList,
            };
        }, [t]);

    return { filteredByTypeOptions, listOfCurrencies, liquidityList, classList };
};

export default useFilters;
