import { Box, Skeleton, styled, Typography } from '@mui/material';

export default {
    PrimarySection: styled(Box)(({ theme }) => ({})),
    SecondTitle: styled(Typography)(({ theme }) => ({
        fontSize: '2.125rem',
        lineHeight: '39.84px',
        letterSspacing: '0.0025em',
        marginTop: '1rem',
        color: theme.palette.custom.darkblue2,
    })),
    SecondSection: styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        margin: '40px 0',
    })),
    ContainerCardsQuotes: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            justifyContent: { xs: 'center', md: 'space-between' },
            gap: '20px',
            flexWrap: 'wrap',
        }),
    ),
    TitleSectionQuotes: styled(Typography)(({ theme }) => ({
        color: '#000',
        fontSize: '12px',
    })),
    SkeletonQuoteCard: styled(Skeleton)(({ theme }) => ({
        borderRadius: '40px',
        height: '80px',
        width: '299px',
    })),
};
