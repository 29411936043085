import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Divider, Grid } from '@mui/material';
import { LOCAL_ACCOUNTS } from 'constants/appConstants';
import InfoLabel from 'components/common/InfoLabel';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { GetBankAccountsItem, SortedBankAccount } from 'types/api/cashflow.types';

const BankAccountDetail = ({
    row: { accountNumber, currency, description, type, reason, createdAt, decisionAt },
    accountsInfo,
    t,
}: Props) => (
    <Grid container spacing={2} alignItems="baseline">
        <Grid container item xs={accountsInfo?.INTERMEDIARY ? 7 : 12}>
            {LOCAL_ACCOUNTS.includes(type) && (
                <>
                    <InfoLabel label={t('account_number')} text={accountNumber} />
                    <InfoLabel label={t('currency')} text={currency} />
                </>
            )}
            {accountsInfo?.BROKER && (
                <>
                    <InfoLabel label={t('broker_name')} text={accountsInfo.BROKER?.broker} />
                    <InfoLabel
                        label={t('broker_customer_code')}
                        text={accountsInfo.BROKER?.brokerCustomerCode}
                    />
                    <InfoLabel label={t('appellation')} text={accountsInfo.BROKER?.appellation} />
                    <InfoLabel label={t('aba')} text={accountsInfo.BROKER?.aba} />
                    <InfoLabel label={t('swift')} text={accountsInfo.BROKER?.swift} />
                    <InfoLabel label={t('iban')} text={accountsInfo.BROKER?.iban} />
                </>
            )}

            {accountsInfo?.DESTINATION && (
                <>
                    <InfoLabel
                        label={t('appellation')}
                        text={accountsInfo.DESTINATION?.appellation}
                    />
                    <InfoLabel label={t('aba')} text={accountsInfo.DESTINATION?.aba} />
                    <InfoLabel label={t('swift')} text={accountsInfo.DESTINATION?.swift} />
                    <InfoLabel label={t('iban')} text={accountsInfo.DESTINATION?.iban} />
                </>
            )}
            <InfoLabel label={t('created_at')} text={formatDateWithHour(createdAt)} />
            <InfoLabel label={t('description')} text={description} />
        </Grid>

        {accountsInfo?.INTERMEDIARY && (
            <Grid container item xs={5}>
                {t('bank_account_intermediary_detail')}
                {accountsInfo.INTERMEDIARY.map(({ appellation, aba, swift, iban }) => (
                    <>
                        <InfoLabel label={t('appellation')} text={appellation} />
                        <InfoLabel label={t('aba')} text={aba} />
                        <InfoLabel label={t('swift')} text={swift} />
                        <InfoLabel label={t('iban')} text={iban} />
                    </>
                ))}
            </Grid>
        )}

        {reason && (
            <>
                <Divider sx={{ width: '100%', mt: 1.25 }} />
                <InfoLabel column={12} label={t('rejected_reason')} text={reason} />
            </>
        )}
    </Grid>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    row: GetBankAccountsItem;
    accountsInfo?: SortedBankAccount;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BankAccountDetail.propTypes = propTypes;

export default BankAccountDetail;
