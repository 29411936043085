import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './ChannelAccountStatus.styled';
import { Grid } from '@mui/material';
import { ChannelAccountAum, ChannelAvailableBalance } from 'types/api/marketAccount.types';
import UserIcon from 'assets/icons/user-line.svg?react';
import CircularSpinner from 'components/common/CircularSpinner';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CustomPieChart from 'components/common/CustomPieChart';
import ChannelAccountInformation from './ChannelAccountInformation';

const ChannelAccountStatus = (props: Props) => {
    const {
        isLoading,
        aum,
        series,
        labels,
        total,
        setSelectedInstrumentType,
        customersQuantity,
        availableBalance,
        t,
    } = props;

    return (
        <St.AccountStatusWrapper>
            <St.AccountStatusContainer>
                <Grid container gap={'20px'} alignItems="center">
                    {isLoading ? (
                        <CircularSpinner />
                    ) : (
                        <>
                            <Grid item xs={12} lg={4}>
                                <ChannelAccountInformation
                                    aum={aum}
                                    availableBalance={availableBalance}
                                />
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <St.ClientAmountContainer>
                                    <CustomIconCreator icon={UserIcon} alt="user-icon" />
                                    <St.ClientAmount typography="h6">
                                        {t('clients_number', {
                                            clients: customersQuantity,
                                        })}
                                    </St.ClientAmount>
                                </St.ClientAmountContainer>

                                {aum?.ars === 0 && aum?.usd === 0 ? (
                                    <St.NoDataChartContainer>
                                        <St.Amount typography="h4">0,00</St.Amount>
                                        <St.TotalText typography="body1">
                                            {t('total_pie_chart')}
                                        </St.TotalText>
                                    </St.NoDataChartContainer>
                                ) : (
                                    <CustomPieChart
                                        id="big-chart"
                                        series={series}
                                        labels={labels}
                                        width={'90%'}
                                        total={total}
                                        setInstrumentType={setSelectedInstrumentType || undefined}
                                    />
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            </St.AccountStatusContainer>
        </St.AccountStatusWrapper>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    total: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

interface extraProps {
    aum: ChannelAccountAum | undefined;
    availableBalance?: ChannelAvailableBalance;
    customersQuantity: number | undefined;
    series: number[];
    labels: string[];
    setSelectedInstrumentType?: React.Dispatch<React.SetStateAction<string>>;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ChannelAccountStatus.propTypes = propTypes;

export default ChannelAccountStatus;
