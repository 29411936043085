import { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    AlertColor,
} from '@mui/material';
import CloseIcon from 'assets/icons/close-line.svg?react';
import CustomIconCreator from '../CustomIconCreator';
import { ICommonModalView } from 'types/common/tableLayout.types';

const CommonModal = ({
    row,
    isOpen,
    id,
    width,
    createButton,
    Icon,
    open,
    close,
    setSnackBarMessage,
    SnackBar,
    component,
    t,
}: Props) => (
    <>
        {createButton ? (
            <Button variant="contained" onClick={open}>
                <Icon /> {t(id)}
            </Button>
        ) : (
            <Tooltip title={t(id)}>
                <IconButton onClick={open}>
                    <Icon />
                </IconButton>
            </Tooltip>
        )}
        {isOpen && (
            <Dialog fullWidth maxWidth={width || 'sm'} open={isOpen}>
                <DialogTitle>
                    {t(`modal_${id}`)}
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            color: theme => theme.palette.grey[500],
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}>
                        <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {
                        component({ row, close, setSnackBarMessage } as {
                            row: any;
                            close(): void;
                            setSnackBarMessage(msj: string, sever?: AlertColor): void;
                        }) as ReactNode
                    }
                </DialogContent>
            </Dialog>
        )}
        <SnackBar />
    </>
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps extends ICommonModalView {
    SnackBar: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonModal.propTypes = propTypes;

export default CommonModal;
