import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './RecommendedWallet.styled';
import { Box, Typography } from '@mui/material';
import CustomPieChart from 'components/common/CustomPieChart';
import { RecommendedWalletValues } from 'types/pages/home.types';
import CircularSpinner from 'components/common/CircularSpinner';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CustomCarousel from 'components/common/CustomCarousel';

const RecommendedWallet = (props: Props) => {
    const { t, values, isLoading } = props;

    return (
        <St.RecommendedWalletWrapper>
            <St.RecommendedWalletContainer>
                <Typography variant="h3" color="primary">
                    {t('recommended_wallet_title')}
                </Typography>
                {isLoading ? (
                    <Box sx={{ marginTop: '10px' }}>
                        <CircularSpinner />
                    </Box>
                ) : (
                    <CustomCarousel width={'700px'}>
                        {values &&
                            Object.keys(values).map((item, key) => {
                                const { colors, labels, series } =
                                    values[item as 'funds' | 'coins' | 'credits'];
                                return (
                                    <St.CarouselItemContainer key={key}>
                                        <St.AvailabeByText variant="h5">
                                            {t(`recommended_wallet_${item}_subtitle`)}
                                        </St.AvailabeByText>
                                        <St.PieChartContainer>
                                            <CustomPieChart
                                                id="recommended-wallet-chart"
                                                width={'100%'}
                                                height={'100%'}
                                                labels={labels ?? []}
                                                series={series ?? []}
                                                colors={colors}
                                            />
                                        </St.PieChartContainer>
                                    </St.CarouselItemContainer>
                                );
                            })}
                    </CustomCarousel>
                )}
            </St.RecommendedWalletContainer>
        </St.RecommendedWalletWrapper>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    values?: RecommendedWalletValues;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RecommendedWallet.propTypes = propTypes;

export default RecommendedWallet;
