import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CommonTableActions from '../CommonTableActions';
import CommonTablePagination from '../CommonTablePagination';
import { ICommonTable } from 'types/common/tableLayout.types';
import defaultTheme from 'utils/theme/defaultTheme';
import CommonTableHeader from '../CommonTableHeader';
import AlertNoData from '../AlertNoData';
import { t } from 'i18next';

const CommonTable = ({
    columns,
    rows: { items, ...pagination },
    actions,
    sortInfo,
    handlePageChange,
    handleSort,
    headerStyles,
    onRowClick,
}: Props) => (
    <>
        {items?.length === 0 ? (
            <AlertNoData title={t('no_data_was_found')} />
        ) : (
            <TableContainer sx={{ mt: 2.5 }} component={Paper}>
                <Table size="small">
                    <CommonTableHeader
                        handleSort={handleSort}
                        columns={columns}
                        hasActions={!!actions?.length}
                        sortInfo={sortInfo}
                        headerStyles={headerStyles}
                    />
                    <TableBody>
                        {items?.map((data: any) => (
                            <TableRow
                                key={data.id}
                                sx={{
                                    backgroundColor: defaultTheme.palette.primary.light,
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: defaultTheme.palette.custom.vibrantblue10,
                                    },
                                    cursor: onRowClick ? 'pointer' : 'default',
                                }}
                                onClick={onRowClick ? () => onRowClick(data) : undefined}>
                                {columns.map(({ id: columnId, align, selector }: any) => (
                                    <TableCell key={columnId} align={align}>
                                        {selector ? selector(data) : data[columnId]}
                                    </TableCell>
                                ))}
                                {actions && <CommonTableActions row={data} actions={actions} />}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {pagination?.page &&
                    pagination?.pageSize &&
                    pagination?.total &&
                    handlePageChange && (
                        <CommonTablePagination
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            total={pagination.total}
                            handlePageChange={handlePageChange}
                        />
                    )}
            </TableContainer>
        )}
    </>
);

const propTypes = {};

interface extraProps extends ICommonTable {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonTable.propTypes = propTypes;

export default CommonTable;
