import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CardBlog from './CardBlog';

const CardBlogContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <CardBlog {...childProps} />;
};

const propTypes = {};

interface extraProps {
    text: string;
    url: string;
    handleClick?: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CardBlogContainer.propTypes = propTypes;

export default CardBlogContainer;
