import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CardBlog.styled';

const CardBlog = (props: Props) => {
    const { text, url, handleClick } = props;

    return (
        <St.Card component="article" onClick={() => handleClick && handleClick()}>
            <St.Img src={url} alt={'img'} />
            <St.Span>{text}</St.Span>
        </St.Card>
    );
};

const propTypes = {};

interface extraProps {
    text: string;
    url: string;
    handleClick?: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CardBlog.propTypes = propTypes;

export default CardBlog;
