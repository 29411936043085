import CustomLoader from 'components/common/CustomLoader';
import ErrorText from 'components/common/ErrorText';
import TopHeader from 'components/common/TopHeader';
import PropTypes from 'prop-types';
import { Tprop } from 'types/common/general.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './OnboardingContactInfoPage.styled';
import OnboardingContactInfoPhoneNumberSection from './OnboardingContactInfoPhoneNumberSection';
import OnboardingContactInfoContactSection from './OnboardingContactInfoContactSection';

export const OnboardingContactInfoPage = (props: Props) => {
    const { t, setPhoneNumber, errorMessageLegalData, isLoadingLegalData, phoneNumber } = props;

    return (
        <St.SectionWrapper>
            {isLoadingLegalData ? (
                <CustomLoader />
            ) : errorMessageLegalData ? (
                <ErrorText text={errorMessageLegalData} />
            ) : (
                <>
                    <TopHeader
                        firstElement={t('contact_info') as string}
                        title={
                            !phoneNumber
                                ? t('contact_validate_Date')
                                : t('contact_validate_personal_data')
                        }
                    />
                    {!phoneNumber ? (
                        <OnboardingContactInfoPhoneNumberSection setPhoneNumber={setPhoneNumber} />
                    ) : (
                        <OnboardingContactInfoContactSection phoneNumber={phoneNumber} />
                    )}
                </>
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {
    phoneNumber: PropTypes.string,
    setPhoneNumber: PropTypes.func.isRequired,
};
interface extraProps extends Tprop {
    isLoadingLegalData: boolean;
    errorMessageLegalData: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContactInfoPage.propTypes = propTypes;

export default OnboardingContactInfoPage;
