import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Box } from '@mui/material';
import { GetFundDetailResponse } from 'types/api/instruments.types';
import {
    calculateDifference,
    convertCurrencyFormat,
    formatNumber,
} from 'utils/helpers/commonHelper';
import { InvestmentTestValues } from 'types/common/users.types';
import St from './FundDetail.styled';
import InstrumentMarketStatus from './InstrumentMarketStatus';
import { formatSingleDate } from 'utils/helpers/dateHelper';

const FundDetail = (props: Props) => {
    const { fundDetail, t, recommendationText, fundType, isMarketClosed } = props;
    const mobileReturns = [
        {
            label: t('market_mobile_rent_mtd'),
            value: `${formatNumber(fundDetail?.fundInfo?.performanceMtd || 0, 2)}%`,
        },
        {
            label: t('market_mobile_rent_qtd'),
            value: `${formatNumber(fundDetail?.fundInfo?.performanceQtd || 0, 2)}%`,
        },
        {
            label: t('market_mobile_rent_ytd'),
            value: `${formatNumber(fundDetail?.fundInfo?.performanceYtd || 0, 2)}%`,
        },
        {
            label: t('market_mobile_rent_wtd'),
            value: `${formatNumber(fundDetail?.fundInfo?.performanceWtd || 0, 2)}%`,
        },
    ];

    return (
        <St.Container>
            <St.LastUpdate>
                {t('market_fund_details_last_update', {
                    date: formatSingleDate(
                        fundDetail?.fundInfo?.performanceVcpUpdate,
                        'DD/MM/YYYY',
                    ),
                })}
            </St.LastUpdate>
            <St.Title>{fundDetail?.ticker}</St.Title>
            <St.ContentContainer>
                <Box>
                    <St.ClassType>{fundDetail?.fundInfo?.fundClass}</St.ClassType>
                    <St.FundValue>
                        {convertCurrencyFormat(
                            fundDetail?.fundInfo?.performanceVcp || 0,
                            fundDetail?.currency,
                            7,
                        )}
                    </St.FundValue>
                    <St.VCPDescription>{t('market_fund_details_vcp_legend')}</St.VCPDescription>
                    <St.VCPValue>
                        {convertCurrencyFormat(
                            calculateDifference(
                                fundDetail?.fundInfo?.performanceVcp || 0,
                                fundDetail?.fundInfo?.performanceVcpPercentage || 0,
                            ),
                            fundDetail?.currency,
                        )}
                        {` (${formatNumber(fundDetail?.fundInfo?.performanceVcpPercentage || 0)}%)`}
                    </St.VCPValue>
                    <St.VCPDescription
                        sx={{ my: 0.5 }}>{`${t('fund_type')}: ${fundType}`}</St.VCPDescription>
                    <St.BlueTypography variant="body2">{recommendationText}</St.BlueTypography>
                    <InstrumentMarketStatus
                        marketClosingTime={fundDetail.fund_closing_time}
                        isMarketClosed={isMarketClosed}
                    />
                </Box>
                <St.TableContainer>
                    <St.Table>
                        <St.TableTitle>{t('market_fund_details_mobile_returns')}</St.TableTitle>
                        <St.TableContentWrapper>
                            {mobileReturns.map((item, index) => (
                                <St.TableCell key={index} $index={index}>
                                    <St.TableCellLabel>{item.label}</St.TableCellLabel>
                                    <St.TableCellValue>{item.value}</St.TableCellValue>
                                </St.TableCell>
                            ))}
                        </St.TableContentWrapper>
                    </St.Table>
                </St.TableContainer>
            </St.ContentContainer>
        </St.Container>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isMarketClosed: PropTypes.bool.isRequired,
};

interface extraProps {
    fundDetail: GetFundDetailResponse;
    investmentProfile?: InvestmentTestValues;
    recommendationText: string;
    fundType: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FundDetail.propTypes = propTypes;

export default FundDetail;
