import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './OnboardingValidatePersonPage.styled';
import OnboardingValidatePersonQuestionsSection from './OnboardingValidatePersonQuestionsSection';
import OnboardingValidatePersonDniSection from './OnboardingValidatePersonDniSection';

const OnboardingValidatePersonPage = (props: Props) => {
    const { setShowNosisQuestions, showNosisQuestions } = props;

    return (
        <St.SectionWrapper>
            {showNosisQuestions ? (
                <OnboardingValidatePersonQuestionsSection />
            ) : (
                <OnboardingValidatePersonDniSection setShowNosisQuestions={setShowNosisQuestions} />
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {};

interface extraProps {
    showNosisQuestions: boolean;
    setShowNosisQuestions: (value: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingValidatePersonPage.propTypes = propTypes;

export default OnboardingValidatePersonPage;
