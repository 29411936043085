import { axiosDataInstance } from 'api/axiosInstances';
import { withFakeData } from 'api/fakedata';
import {
    CreateBankAccountRequest,
    CreateDepositRequest,
    CreateWithdrawalRequest,
    GetBankAccountsResponse,
    GetDepositsResponse,
    GetWithdrawalsResponse,
    UpdateBankAccountRequest,
    VerifyCbvuRequest,
    VerifyCbvuResponse,
} from 'types/api/cashflow.types';

const apiName = 'cashflow';
const CASHFLOW_BASE_URL = '/cashflow/v1_0';

export default {
    getBankAccounts: (filterQueryString: string) =>
        withFakeData<GetBankAccountsResponse>(
            () => axiosDataInstance.get(`${CASHFLOW_BASE_URL}/bank-accounts/?${filterQueryString}`),
            apiName,
            'bank-accounts',
            false,
            500,
        ),
    createBankAccount: (body: CreateBankAccountRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${CASHFLOW_BASE_URL}/bank-accounts/`, body),
            apiName,
            'bank-accounts',
            false,
            500,
        ),
    updateBankAccount: ({ id, body }: { id: string; body: UpdateBankAccountRequest }) =>
        withFakeData(
            () => axiosDataInstance.patch(`${CASHFLOW_BASE_URL}/bank-accounts/${id}`, body),
            apiName,
            'bank-accounts',
            false,
            500,
        ),
    deleteBankAccounts: (id: string) =>
        withFakeData(
            () => axiosDataInstance.delete(`${CASHFLOW_BASE_URL}/bank-accounts/${id}`),
            apiName,
            'bank-accounts',
            false,
            500,
        ),
    createWithdrawal: (argument: CreateWithdrawalRequest) =>
        withFakeData(
            () => axiosDataInstance.post(`${CASHFLOW_BASE_URL}/withdrawal`, argument),
            apiName,
            'withdrawal',
            false,
            500,
        ),

    createDeposit: (argument: CreateDepositRequest) =>
        withFakeData(
            () =>
                axiosDataInstance.post(`${CASHFLOW_BASE_URL}/deposit/`, {
                    ...argument,
                }),
            apiName,
            'deposit',
            false,
            500,
        ),
    verifyCbvu: (argument: VerifyCbvuRequest) =>
        withFakeData<VerifyCbvuResponse>(
            () =>
                axiosDataInstance.post(`${CASHFLOW_BASE_URL}/bank-accounts/verify_cbvu`, argument),
            apiName,
            'verify_cbvu',
            false,
            500,
        ),
    getWithdrawals: (filterQueryString: string) =>
        withFakeData<GetWithdrawalsResponse>(
            () => axiosDataInstance.get(`${CASHFLOW_BASE_URL}/withdrawal?${filterQueryString}`),
            apiName,
            'withdrawal',
            false,
            500,
        ),
    getDeposits: (filterQueryString: string) =>
        withFakeData<GetDepositsResponse>(
            () => axiosDataInstance.get(`${CASHFLOW_BASE_URL}/deposit?${filterQueryString}`),
            apiName,
            'deposit',
            false,
            500,
        ),
};
