import React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { MarketTableFilters, WSTable } from 'types/context/prices.context.types';
import { Tprop } from 'types/common/general.types';
import CustomLoader from '../CustomLoader';
import MarketInstrumentTable from './MarketInstrumentTable';
import HeaderFilters from './HeaderFilters';
import GeneralMarketSearchBar from '../GeneralMarketSearchBar';
import St from './CommonMarketTable.styled';

const CommonMarketTable = (props: Props) => {
    const {
        title,
        filters,
        setFilters,
        tableData,
        isLoading,
        changeValues,
        filteredByTypeOptions,
        listOfCurrencies,
        table = 'default',
        liquidityList,
        classList,
        refetch,
        isPreferred,
        t,
    } = props;

    return (
        <St.CommonMarketTableWrapper>
            <St.BoxWrapper>
                <St.Title>{title}</St.Title>
                <GeneralMarketSearchBar />
            </St.BoxWrapper>
            <HeaderFilters
                {...{
                    table,
                    filters,
                    setFilters,
                    filteredByTypeOptions,
                    listOfCurrencies,
                    liquidityList,
                    classList,
                    isPreferred,
                }}
            />
            {isLoading ? (
                <CustomLoader />
            ) : (
                <MarketInstrumentTable
                    {...{
                        table,
                        filters,
                        tableData,
                        isLoading,
                        changeValues,
                        refetch,
                        t,
                    }}
                />
            )}
        </St.CommonMarketTableWrapper>
    );
};

const propTypes = {};

interface extraProps extends Tprop {
    title: string;
    isPreferred: boolean;
    filters: MarketTableFilters;
    setFilters: React.Dispatch<React.SetStateAction<MarketTableFilters>>;
    tableData?: WSTable[];
    isLoading: boolean;
    changeValues?: {
        [ticker: string]: Partial<Record<keyof WSTable, string>>;
    };
    filteredByTypeOptions: Array<{ id: string; name: string }>;
    listOfCurrencies: Array<{ id: string; name: string }>;
    liquidityList: Array<{ id: string; name: string }>;
    classList: Array<{ id: string; name: string }>;
    table?: 'default' | 'funds';
    refetch: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CommonMarketTable.propTypes = propTypes;

export default CommonMarketTable;
