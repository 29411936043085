import React, { useContext } from 'react';
import { Routes } from 'react-router-dom';
import { processRoutesRecursively } from 'utils/helpers/routesHelper';
import routes from './routeList';
import { AuthContext } from 'context/auth.context';

const AppRoutes = () => {
    const { isAuthenticated, isTestExpired, userType, userData } = useContext(AuthContext);
    const routesMapped = React.useMemo(() => {
        return processRoutesRecursively(
            routes,
            isAuthenticated,
            userData?.onboardingPending ?? false,
            userType,
            isTestExpired,
        );
    }, [isAuthenticated, isTestExpired, userType, userData?.onboardingPending]);

    return (
        <>
            <Routes>{routesMapped}</Routes>
        </>
    );
};

export default AppRoutes;
