import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Form, FormikContextType, FormikProvider } from 'formik';
import TopHeader from 'components/common/TopHeader';
import CircleWithNumber from 'components/common/CircleWithNumber';
import CustomLoader from 'components/common/CustomLoader';
import { tCommon } from 'constants/appConstants';
import QuestionsAnswers from './QuestionsAnswers';
import St from './OnboardingInvestmentTestPage.styled';
import React from 'react';
import { UserData } from 'types/api/auth.types';
import { ValidateInvestmentTestAnswersResponse } from 'types/api/onboarding.types';
import {
    OnboardingInvestmentTestPageProps,
    QuestionsInvestmentById,
} from 'types/pages/onboardingValidatePerson.types';
import { Typography } from '@mui/material';
import { formatDate } from 'utils/helpers/dateHelper';

const OnboardingInvestmentTestPage = (props: Props) => {
    const {
        t,
        handleCheck,
        formik,
        isLoading,
        isLoadingValidate,
        errorMessage,
        currentStep,
        handleChangeQuestion,
        validateInvestmentTestQuestions,
        handleNavigateToHome,
        prevTest,
        setPrevTest,
        isOnboarding,
        userData,
        investorFeatures,
        navigate,
        investorProfileIsExpired,
    } = props;

    const values = formik.values as QuestionsInvestmentById;
    return (
        <St.SectionWrapper $isOnboarding={isOnboarding} $isExpired={investorProfileIsExpired}>
            {investorProfileIsExpired ? (
                <St.HeaderExpiredWrapper>
                    <TopHeader
                        firstElement={t('investment_test_page_title_expired') as string}
                        title={t('investment_test_page_subtitle_expired')}
                    />
                </St.HeaderExpiredWrapper>
            ) : (
                <TopHeader
                    firstElement={t('investment_test_page_first_text') as string}
                    title={
                        validateInvestmentTestQuestions || prevTest
                            ? (t('investment_test_page_title_with_profile', {
                                  profile: prevTest
                                      ? userData?.info?.investmentProfile.toLowerCase()
                                      : validateInvestmentTestQuestions?.investmentProfile?.toLowerCase(),
                              }) as string)
                            : t('investment_test_page_title')
                    }
                />
            )}

            {validateInvestmentTestQuestions || prevTest ? (
                <>
                    {!investorProfileIsExpired && (
                        <>
                            <St.Subtitle>
                                {t('investment_test_page_subtitle_with_profile')}
                            </St.Subtitle>
                            {validateInvestmentTestQuestions ? (
                                <St.Ul>
                                    {investorFeatures?.map((el: string, index: number) => (
                                        <St.Li key={index}>{el}</St.Li>
                                    ))}
                                </St.Ul>
                            ) : (
                                <St.Ul>
                                    {investorFeatures.map((el: string, index: number) => (
                                        <St.Li key={index}>{el}</St.Li>
                                    ))}
                                </St.Ul>
                            )}
                        </>
                    )}

                    {prevTest ? (
                        <>
                            <St.ActionButtonWrapper>
                                <St.ActionButton
                                    variant="contained"
                                    type="button"
                                    onClick={() => setPrevTest(false)}>
                                    {t('investment_test_page_do_another_test')}
                                </St.ActionButton>
                            </St.ActionButtonWrapper>
                            <St.ActionButtonWrapper>
                                <Typography textAlign={'center'}>
                                    Fecha de último test{' '}
                                    {formatDate(userData?.info?.investorProfileUpdatedAt || '')}
                                </Typography>
                            </St.ActionButtonWrapper>
                        </>
                    ) : isOnboarding ? (
                        <St.ActionButtonWrapper>
                            <St.ActionButton
                                variant="contained"
                                type="button"
                                onClick={handleNavigateToHome}>
                                {t('investment_test_page_btn_finish')}
                            </St.ActionButton>
                        </St.ActionButtonWrapper>
                    ) : (
                        <St.ActionButtonWrapper>
                            <St.ActionButton
                                variant="contained"
                                type="button"
                                onClick={() => navigate('/home#recommended-wallet')}>
                                {t('investment_test_page_see__recommended_portfolio')}
                            </St.ActionButton>
                        </St.ActionButtonWrapper>
                    )}
                </>
            ) : (
                <FormikProvider value={formik}>
                    <Form>
                        {isLoading ? (
                            <CustomLoader />
                        ) : (
                            <>
                                <St.NumberWrapper>
                                    {Object.entries(values.questions ?? []).map(([key, value]) => (
                                        <CircleWithNumber
                                            key={value.questionId}
                                            activated={value.current}
                                            number={Number(value.questionId)}
                                            className={
                                                value.selectedOption && !value.current
                                                    ? 'complete'
                                                    : ''
                                            }
                                        />
                                    ))}
                                </St.NumberWrapper>
                                <St.QuestionsAnswersWrapper key={currentStep}>
                                    <QuestionsAnswers
                                        {...{
                                            ...values.questions?.[currentStep],
                                            questionKey: currentStep,
                                            handleCheck,
                                        }}
                                    />
                                    {errorMessage && (
                                        <St.ErrorTextWrapper>
                                            <St.ErrorText text={errorMessage as string} />
                                        </St.ErrorTextWrapper>
                                    )}
                                    <St.ActionButtonWrapper>
                                        {currentStep !== '1' && (
                                            <St.ActionButton
                                                variant="outlined"
                                                type="button"
                                                disabled={isLoadingValidate}
                                                onClick={() => handleChangeQuestion()}>
                                                {t('back', tCommon)}
                                            </St.ActionButton>
                                        )}
                                        <St.LoadingButton
                                            variant="contained"
                                            type={currentStep === '8' ? 'button' : 'submit'}
                                            disabled={
                                                !values.questions?.[currentStep].selectedOption ||
                                                isLoadingValidate
                                            }
                                            loading={isLoadingValidate}
                                            loadingIndicator={<St.CustomCircularProgress />}
                                            onClick={() =>
                                                currentStep === '8'
                                                    ? formik.handleSubmit()
                                                    : handleChangeQuestion(true)
                                            }>
                                            {currentStep === '8'
                                                ? t('investment_test_page_btn_finish')
                                                : t('next', tCommon)}
                                        </St.LoadingButton>
                                    </St.ActionButtonWrapper>
                                </St.QuestionsAnswersWrapper>
                            </>
                        )}
                    </Form>
                </FormikProvider>
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {};

interface extraProps extends OnboardingInvestmentTestPageProps {
    prevTest: boolean;
    setPrevTest: React.Dispatch<React.SetStateAction<boolean>>;
    isOnboarding: boolean;
    userData: UserData | null;
    investorFeatures: string[];
    navigate: (path: string) => void;
    formik: FormikContextType<any>;
    isLoading: boolean;
    isLoadingValidate: boolean;
    handleCheck: (field: string, questionKey: string) => void;
    handleChangeQuestion: (next?: boolean) => void;
    errorMessage: string | boolean;
    currentStep: string;
    validateInvestmentTestQuestions: ValidateInvestmentTestAnswersResponse | undefined;
    handleNavigateToHome: () => void;
    investorProfileIsExpired: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingInvestmentTestPage.propTypes = propTypes;

export default OnboardingInvestmentTestPage;
