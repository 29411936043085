import axios, { Axios, AxiosError } from 'axios';
import { V_BASE_URL, V_AXIOS_WITH_CREDENTIALS } from 'constants/envVarConstants';
import { AUTH_ERROR_CODES, PERMISSION_ERROR_CODES } from 'constants/errorConstants';
import { loggedOutSubroutesPaths, publicSubroutesPaths } from 'router/routesPaths';

const axiosTimeout = 30000;

// let store: any = null;

// export const injectStore = (_store: any) => {
//     store = _store;
// };
// Se comenta ya que puede ser usado a futuro

// ------------------- //
// Annonymous Instance //
// ------------------- //

const getAnonInstance = (baseUrl: string) => {
    const anonInstance = axios.create({
        baseURL: baseUrl,
        withCredentials: V_AXIOS_WITH_CREDENTIALS,
    });

    anonInstance.interceptors.response.use(
        (response: any) => {
            //   if (printRequestResult) logStep('request result: ' + JSON.stringify(response.data));
            return response;
        },
        error => {
            //   handleErrorLogger(error);
            return Promise.reject(error);
        },
    );

    anonInstance.interceptors.request.use(async (config: any) => {
        // if (printRequestConfig) consoleLog('request config:', JSON.stringify(config));
        return config;
    });

    anonInstance.defaults.timeout = axiosTimeout;

    return anonInstance;
};

// ---------------------- //
// Authenticated Instance //
// ---------------------- //

const getAuthInstance = (baseUrl: string) => {
    const axiosInstance = axios.create({
        baseURL: baseUrl,
        withCredentials: V_AXIOS_WITH_CREDENTIALS,
    });

    axiosInstance.interceptors.request.use(
        config => {
            const token = localStorage.getItem('IdToken');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        },
    );

    axiosInstance.interceptors.response.use(
        response => response,
        (error: AxiosError) => {
            if (error.response && PERMISSION_ERROR_CODES.includes(error.response.status))
                window.location.replace(publicSubroutesPaths.noPermissions);

            if (error.response && AUTH_ERROR_CODES.includes(error.response.status)) {
                // Limpiar localStorage
                localStorage.clear();
                window.location.replace(loggedOutSubroutesPaths.login);
            }
            return Promise.reject(error);
        },
    );
    return axiosInstance;
};

export const axiosAnonInstance = getAnonInstance(V_BASE_URL);
export const axiosDataInstance = getAuthInstance(V_BASE_URL);
