import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
} from 'react';
import { AuthContext } from './auth.context';
import { useGetOnboardingLegalData, useGetSteps } from 'hooks/api/onboarding.hooks';
import { onboardingHomeStepsDataAdapter } from 'adapters/api/onboarding.adapter';
import { Step } from 'types/pages/onboardingHome.types';
import { useNavigate } from 'react-router-dom';
import { onboardingRoutesPaths } from 'router/routesPaths';
import { GetLegalDataReponse } from 'types/api/onboarding.types';
import { scrollToTop } from 'utils/helpers/commonHelper';

interface IntialState {
    steps: Step[] | null;
    setSteps: Dispatch<SetStateAction<Step[] | null>>;
    isLoadingSteps: boolean;
    legalData: GetLegalDataReponse | null;
    setLegalData: Dispatch<SetStateAction<GetLegalDataReponse | null>>;
    isLoadingLegalData: boolean;
    errorMessageLegalData: string | null;
}

export const OnboardingContext = createContext({} as IntialState);

const OnboardingContextProvider = ({ children }: { children: ReactNode }) => {
    const [steps, setSteps] = React.useState<Step[] | null>(null);
    const [legalData, setLegalData] = React.useState<GetLegalDataReponse | null>(null);
    const [firstLogin, setFirstLogin] = React.useState<boolean>(true);
    const { isAuthenticated } = useContext(AuthContext);
    const { mutate: getSteps, isLoading: isLoadingSteps } = useGetSteps();
    const {
        mutate: getLegalData,
        isLoading: isLoadingLegalData,
        errorMessage: errorMessageLegalData,
    } = useGetOnboardingLegalData();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated && firstLogin) {
            getSteps(
                {},
                {
                    onSuccess: data => {
                        setFirstLogin(false);
                        setSteps(onboardingHomeStepsDataAdapter(data.data));
                        const isOnboardingStartedPending =
                            data.data.steps.filter(e => e.stepName === 'ONBOARDING_STARTED')[0]
                                .status === 'PENDING';
                        const isValidatePersonPending =
                            data.data.steps.filter(e => e.stepName === 'VALIDATE_PERSON')[0]
                                .status === 'PENDING';
                        if (!isOnboardingStartedPending)
                            getLegalData({}, { onSuccess: data => setLegalData(data.data) });
                        if (isOnboardingStartedPending || isValidatePersonPending) {
                            navigate(onboardingRoutesPaths.validatePerson, {
                                state: { showNosisQuestions: !isOnboardingStartedPending },
                            });
                        } else if (!window.location.pathname.includes('onboarding')) {
                            navigate(onboardingRoutesPaths.home);
                        }
                    },
                },
            );
        }
    }, [isAuthenticated, firstLogin]);

    useEffect(() => {
        if (!firstLogin && window.location.pathname.includes(onboardingRoutesPaths.home)) {
            scrollToTop();
            getSteps(
                {},
                {
                    onSuccess: data => {
                        setSteps(onboardingHomeStepsDataAdapter(data.data));
                    },
                },
            );
        }
    }, [window.location.pathname]);

    const memoedValue = React.useMemo(() => {
        const value = {
            steps,
            setSteps,
            isLoadingSteps,
            legalData,
            setLegalData,
            isLoadingLegalData,
            errorMessageLegalData,
        };
        return value;
    }, [
        steps,
        setSteps,
        isLoadingSteps,
        legalData,
        setLegalData,
        isLoadingLegalData,
        errorMessageLegalData,
    ]);

    return <OnboardingContext.Provider value={memoedValue}>{children}</OnboardingContext.Provider>;
};

export default OnboardingContextProvider;
