import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomDatePicker from './CustomDatePicker';
import { useTranslation } from 'react-i18next';
import { getLanguage } from 'storage/auth.storage';
import { Typography } from '@mui/material';
import St from './CustomDatePicker.styled';
import moment from 'moment';

const CustomDatePickerContainer = (props: Props) => {
    const { value, setFieldValue, fieldValue } = props;
    const { t } = useTranslation();
    const locale = React.useMemo(() => new Date(value), [value]);

    const lang = getLanguage()?.split('-')[0];

    const handlerDateSelector = (newValue: any): void => {
        let getMilis = null;
        if (newValue && newValue._d) {
            getMilis = new Date(newValue._d);
            const offset = getMilis.getTimezoneOffset();
            if (offset < 0) {
                getMilis.setMinutes(Math.abs(offset));
            }
        }
        setFieldValue(fieldValue, getMilis ? getMilis.getTime() : '');
    };

    const CustomToolbar = (value: Date) => {
        let format: null | string = null;
        if (value instanceof Date && value.toString() !== 'Invalid Date') {
            const arrOfVals = String(value).split(' ');
            format = `${t(`${arrOfVals[0]}`, { ns: 'date' })}, ${t(`${arrOfVals[1]}`, {
                ns: 'date',
            })} ${arrOfVals[2]} - ${arrOfVals[3]}`;
        }

        return (
            <St.Box>
                <Typography variant="h5">{format ?? t('select_date')}</Typography>
            </St.Box>
        );
    };

    const dateValue = React.useMemo(() => {
        return value === null
            ? ''
            : new Date(locale.getUTCFullYear(), locale.getUTCMonth(), locale.getUTCDate());
    }, [value, locale]);

    const validDate = moment(dateValue).isValid();

    const handleClearDate = () => {
        handlerDateSelector('');
    };

    const childProps = {
        ...props,
        t,
        handlerDateSelector,
        lang,
        locale,
        CustomToolbar,
        handleClearDate,
        validDate,
        dateValue,
    };

    return <CustomDatePicker {...childProps} />;
};

const propTypes = {};

interface extraProps {
    value: number | string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    fieldValue: string;
    isReadOnly?: boolean;
    minDate?: number | Date;
    errorMessage?: string;
    placeholder?: string;
    required?: boolean;
    width?: string;
    minWidth?: string;
    disablePast?: boolean;
    disableCurrentDay?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomDatePickerContainer.propTypes = propTypes;

export default CustomDatePickerContainer;
