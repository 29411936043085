import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableLayout from 'components/layouts/TableLayout';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { useGetChannelClients } from 'hooks/api/marketAccount.hooks';
import { MarketAccount } from 'types/api/marketAccount.types';

const ClientsPage = ({ t, columns, actions, filters }: Props) => (
    <TableLayout
        title={t('clients_title')}
        fetchData={useGetChannelClients}
        columns={columns}
        actions={actions}
        filters={filters}
    />
);

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    columns: CommonColumn<MarketAccount>[];
    actions: CommonAction<MarketAccount>[];
    filters: CommonFilter[];
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsPage.propTypes = propTypes;

export default ClientsPage;
