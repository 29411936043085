import { AxiosResponse } from 'axios';
import { V_OFFLINE_MODE } from 'constants/envVarConstants';
import { later } from 'utils/helpers/commonHelper';
// type GenericAxiosReturnType<T> = Promise<AxiosResponse<T, any>>;

const fakeData: { [key: string]: any } =
    process.env.NODE_ENV !== 'production'
        ? {
              auth: import('./auth/auth.api.fakedata'),
              alerts: import('./alerts/alerts.api.fakedata'),
              cashflow: import('./cashflow/cashflow.api.fakedata'),
              instruments: import('./instruments/instruments.api.fakedata'),
              onboarding: import('./onboarding/onboarding.api.fakedata'),
              orders: import('./orders/orders.api.fakedata'),
              marketAccount: import('./marketAccount/marketAccount.api.fakedata'),
          }
        : {};

export const withFakeData = <T>(
    request: () => any,
    api: string,
    call: string,
    alwaysFake = false,
    delay = 0,
): Promise<AxiosResponse<T, any>> => {
    if (V_OFFLINE_MODE || alwaysFake || process.env.NODE_ENV == 'test') {
        // Fakedata files not included
        if (fakeData[api] == null) {
            return Promise.resolve({
                status: 200,
                statusText: 'OK',
                headers: {},
                config: {},
                data: {},
            }) as Promise<AxiosResponse<T, any>>;
        }

        return fakeData[api].then((apiObject: any) => {
            const dataObject = (apiObject.default[call] ?? {}) as T;

            if (delay > 0) {
                return later(delay, {
                    status: 200,
                    statusText: 'OK',
                    headers: {},
                    config: {},
                    data: dataObject,
                }) as Promise<AxiosResponse<T, any>>;
            }

            return Promise.resolve({
                status: 200,
                statusText: 'OK',
                headers: {},
                config: {},
                data: dataObject,
            }) as Promise<AxiosResponse<T, any>>;
        });
    } else {
        return request() as Promise<AxiosResponse<T, any>>;
    }
};
