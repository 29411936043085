import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BuySellPrevisualizeModal from './BuySellPrevisualizeModal';
import { useTranslation } from 'react-i18next';
import {
    BUYSELL,
    CreateMarketOrderRequest,
    GetMarketPreviewResponse,
} from 'types/api/orders.types';
import { InstrumentType, TickerRequieresDeclaration } from 'types/api/instruments.types';
import { useCreateMarketOrder } from 'hooks/api/orders.hooks';
import { AuthContext } from 'context/auth.context';
import { Currency } from 'types/common/general.types';
import { useRequestOtp } from 'hooks/api/auth.hooks';
import { channelRoutesPaths, mainRoutesPaths } from 'router/routesPaths';

const BuySellPrevisualizeModalContainer = (props: Props) => {
    const { instrumentType, marketPreview, buySell, isMarketPrice, tickerId, requiresDeclaration } =
        props;
    const { t } = useTranslation('market');
    const { customerCode, userData, selectedCustomer, userType } = React.useContext(AuthContext);
    const [openModal, setOpenModal] = React.useState<'error' | 'success' | 'enableMFA' | null>(
        null,
    );
    const [manualMarketPrice, setManualMarketPrice] = React.useState<boolean>(isMarketPrice);
    const [body, setBody] = React.useState<CreateMarketOrderRequest | null>(null);
    const [isManual, setIsManual] = React.useState<boolean>(false);
    const [acceptDDJJ, setAcceptDDJJ] = React.useState<boolean>(false);
    const { createMarketOrder, isLoading, errorMessage } = useCreateMarketOrder();
    const { mutate: requestOtp, isLoading: isLoadingRequestOtp } = useRequestOtp();

    const handleCreateOrder = (req: CreateMarketOrderRequest) => {
        createMarketOrder(req, {
            onSuccess() {
                setOpenModal('success');
            },
            onError() {
                setOpenModal('error');
            },
        });
    };

    const onSubmit = () => {
        const req: CreateMarketOrderRequest = {
            amount: marketPreview.amount,
            buySell,
            customerCode: customerCode as string,
            userUuid: selectedCustomer?.uuid as string,
            isManual,
            isMarketPrice: isManual ? manualMarketPrice : isMarketPrice,
            price: marketPreview.price,
            quantity: marketPreview.quantity,
            term: marketPreview.term,
            tickerId: tickerId as number,
        };
        if (userData?.mfaType !== 'DISABLED') {
            setBody(req);
            setOpenModal('enableMFA');
        } else {
            handleCreateOrder(req);
        }
    };

    const handleRequestOtp = () => {
        requestOtp(
            {},
            {
                onError() {
                    setOpenModal('error');
                },
            },
        );
    };
    const handleValidateOtp = (otp: string) => {
        handleCreateOrder({ ...(body as CreateMarketOrderRequest), otp });
    };

    const successModalProps = {
        leftText: t('go_to_orders_button_text'),
        leftUrl: mainRoutesPaths.orders,
        rightText: t(`go_to_${buySell === 'BUY' ? 'preferred' : 'wallet'}_button_text`),
        rightUrl:
            buySell === 'BUY'
                ? mainRoutesPaths.preferred
                : ['ASSISTANT', 'CHANNEL'].includes(userType || '')
                  ? channelRoutesPaths.wallet
                  : mainRoutesPaths.briefcase,
    };

    const childProps = {
        ...props,
        t,
        buySell,
        onSubmit,
        userType,
        isLoading: isLoading || isLoadingRequestOtp,
        openModal,
        setOpenModal,
        instrumentType,
        selectedCustomer,
        manualMarketPrice,
        setManualMarketPrice,
        mfaType: userData?.mfaType,
        isLoadingSubmitMfa: isLoading,
        requestOtp: handleRequestOtp,
        validateOtp: handleValidateOtp,
        apiError: errorMessage,
        setIsManual,
        isManual,
        successModalProps,
        acceptDDJJ,
        setAcceptDDJJ,
        requiresDeclaration:
            (requiresDeclaration === 'BUY_SELL' ? true : requiresDeclaration === buySell) &&
            ['APP_LEGAL', 'APP_PHYSICAL'].includes(userType || ''),
    };

    return <BuySellPrevisualizeModal {...childProps} />;
};

const propTypes = {
    tickerId: PropTypes.number,
    isMarketPrice: PropTypes.bool.isRequired,
    isBond: PropTypes.bool.isRequired,
    instrumentName: PropTypes.string.isRequired,
};

interface extraProps {
    open: boolean;
    buySell: BUYSELL;
    close: () => void;
    currency: Currency;
    instrumentType?: InstrumentType;
    marketPreview: GetMarketPreviewResponse;
    requiresDeclaration: TickerRequieresDeclaration;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BuySellPrevisualizeModalContainer.propTypes = propTypes;

export default BuySellPrevisualizeModalContainer;
