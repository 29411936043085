import React from 'react';
import { Grid } from '@mui/material';
import St, { getColor, getIconDetails } from './InstrumentDetails.styled';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CustomIconCreator from 'components/common/CustomIconCreator';
import { TFunction } from 'i18next';

const isBond = (type: string) => type === 'BOND';
const isNotCurrencyOrBond = (type: string) => type !== 'BOND' && type !== 'CURRENCY';

const renderProfitability = (t: TFunction, details: any) => {
    if (details.profitability !== 0 && details.profitability !== undefined) {
        return (
            <St.BodyText variant="body2" color={getColor(details.profitability)}>
                <St.TextWithIconContainer component={'span'}>
                    ${details.profitability} ({details.profitabilityPercentage || ''}%)
                    <CustomIconCreator
                        icon={getIconDetails(details.profitability).icon}
                        alt={getIconDetails(details.profitability).alt}
                        sx={{
                            marginLeft: '8px',
                            color: getIconDetails(details.profitability).color,
                        }}
                    />
                </St.TextWithIconContainer>
            </St.BodyText>
        );
    }
    return null;
};

const renderPpc = (t: TFunction, details: any) => {
    if (details.ppc !== 0 && details.ppc !== undefined) {
        return (
            <St.BodyText variant="body2" color={getColor(details.ppc)}>
                <St.TextWithIconContainer component={'span'}>
                    {t('instrument_ppc')}: ${details.ppc} ({details.ppcPercentage || ''}%)
                    <CustomIconCreator
                        icon={getIconDetails(details.ppc).icon}
                        alt={getIconDetails(details.ppc).alt}
                        sx={{
                            marginLeft: '8px',
                            color: getIconDetails(details.ppc).color,
                        }}
                    />
                </St.TextWithIconContainer>
            </St.BodyText>
        );
    }
    return null;
};

const renderResult = (t: TFunction, details: any) => {
    if (details.result !== 0 && details.result !== undefined) {
        return (
            <St.BodyText variant="body2" color={getColor(details.result)}>
                <St.TextWithIconContainer component={'span'}>
                    {t('instrument_result')}: ${details.result}
                    <CustomIconCreator
                        icon={getIconDetails(details.result).icon}
                        alt={getIconDetails(details.result).alt}
                        sx={{
                            marginLeft: '8px',
                            color: getIconDetails(details.result).color,
                        }}
                    />
                </St.TextWithIconContainer>
            </St.BodyText>
        );
    }
    return null;
};

const InstrumentDetails = (props: Props) => {
    const { t, details, type } = props;

    return (
        <St.InstrumentDetailsContainer>
            {details && (
                <>
                    <Grid container spacing={2}>
                        <St.ColumnLeft item xs={6}>
                            <St.Title variant="subtitle1">
                                {details.title || details.description}
                            </St.Title>
                            <St.Description variant="h6">
                                {details.currency || details.ticker}
                            </St.Description>
                            {!type.includes('CURRENCY') && (
                                <St.BodyText variant="body2">
                                    {t('instrument_price')}: $ {details.price?.value || ''}
                                </St.BodyText>
                            )}
                            <St.BodyText variant="body2">
                                {t('instrument_quantity')}: {details.quantity || ''}
                            </St.BodyText>
                            <St.BodyText variant="body2">
                                {t('instrument_available')}: {details.available || ''}
                            </St.BodyText>
                            <St.BodyText variant="body2">
                                {t('instrument_not_available')}: {details.notAvailable || '0'}
                            </St.BodyText>
                        </St.ColumnLeft>

                        <St.ColumnRight item xs={6}>
                            <St.BodyText variant="body2">
                                {t('instrument_portfolio_holdings')}:{' '}
                                {details.holdingPercentage || ''}%
                            </St.BodyText>
                            <St.Description variant="body2" color="black !important">
                                ${details.valuation || ''}
                            </St.Description>

                            {isBond(type) && (
                                <>
                                    {renderProfitability(t, details)}
                                    {renderPpc(t, details)}
                                </>
                            )}

                            {isNotCurrencyOrBond(type) && (
                                <>
                                    {renderProfitability(t, details)}
                                    {renderPpc(t, details)}
                                    {renderResult(t, details)}
                                </>
                            )}
                        </St.ColumnRight>
                    </Grid>
                </>
            )}
        </St.InstrumentDetailsContainer>
    );
};

const propTypes = {
    type: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

interface extraProps {
    details: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentDetails.propTypes = propTypes;

export default InstrumentDetails;
