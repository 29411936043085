import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentDetailCard from './InstrumentDetailCard';
import { GetInstrumentsItem } from 'types/api/instruments.types';
import {
    InstrumentPricesDetail,
    LimitPriceStateInfo,
    MarketStatusTimes,
} from 'types/pages/marketInstrumentDetail.types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Currency } from 'types/common/general.types';
import { BUYSELL } from 'types/api/orders.types';

const InstrumentDetailCardContainer = (props: Props) => {
    const { tickerPriceInfo, setLimitIndex, buySell } = props;
    const { t } = useTranslation('market');
    const theme = useTheme();

    const priceColor = React.useMemo(() => {
        if (!tickerPriceInfo?.difference || tickerPriceInfo?.difference === 0) return 'default';
        else if (tickerPriceInfo?.difference > 0) return theme.palette.custom.green;
        return theme.palette.custom.lightRed;
    }, [tickerPriceInfo?.difference, theme]);

    const handleLimitIndex = (value: LimitPriceStateInfo | null) => {
        if (buySell === value?.type) {
            setLimitIndex(value);
        }
    };

    const childProps = {
        ...props,
        t,
        priceColor,
        handleLimitIndex,
    };

    return <InstrumentDetailCard {...childProps} />;
};

const propTypes = {
    ticker: PropTypes.string.isRequired,
    isBond: PropTypes.bool.isRequired,
    isMarketClosed: PropTypes.bool.isRequired,
};

interface extraProps {
    instrument: GetInstrumentsItem | null;
    tickerPriceInfo?: InstrumentPricesDetail;
    term: 'CI' | '24hs';
    setLimitIndex: (value: LimitPriceStateInfo | null) => void;
    currency: Currency;
    buySell: BUYSELL;
    currentMarket: MarketStatusTimes | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentDetailCardContainer.propTypes = propTypes;

export default InstrumentDetailCardContainer;
