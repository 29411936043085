import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './InstrumentDetailCard.styled';
import { Grid, Typography } from '@mui/material';
import { GetInstrumentsItem } from 'types/api/instruments.types';
import {
    InstrumentPricesDetail,
    LimitPriceStateInfo,
    MarketStatusTimes,
} from 'types/pages/marketInstrumentDetail.types';
import { tCommon } from 'constants/appConstants';
import { formatNumber } from 'utils/helpers/commonHelper';
import { Currency } from 'types/common/general.types';
import BidsOffersTable from './BidsOffersTable';
import InstrumentMarketStatus from '../InstrumentMarketStatus';
import { BUYSELL } from 'types/api/orders.types';

const InstrumentDetailCard = (props: Props) => {
    const {
        instrument,
        ticker,
        tickerPriceInfo,
        t,
        priceColor,
        term,
        currency,
        isBond,
        handleLimitIndex,
        currentMarket,
        buySell,
        isMarketClosed,
    } = props;

    return (
        <St.InstrumentContentWrapper sx={{ padding: '2rem' }}>
            <Grid container gap={'4rem'}>
                <Grid item xs={12} md={4}>
                    <St.GrayText variant="caption">
                        {t('updated_at', { ...tCommon, date: tickerPriceInfo?.lastUpdate })}
                    </St.GrayText>
                    <Typography variant="h2" color="primary.main">
                        {decodeURIComponent(ticker).toUpperCase()}
                    </Typography>
                    <St.TagsContainer>
                        <St.Tag variant="h5">{term}</St.Tag>
                        <St.Tag variant="h5">{currency}</St.Tag>
                    </St.TagsContainer>
                    <St.GrayText variant="body1">{instrument?.name}</St.GrayText>
                    <St.PriceValue variant="h3">
                        {tickerPriceInfo?.price
                            ? `${currency === 'ARS' ? '$' : 'US$'}${formatNumber(tickerPriceInfo?.price, isBond ? 3 : 2)}`
                            : '-'}
                    </St.PriceValue>
                    <Typography variant="h5" color={priceColor}>
                        {`$${tickerPriceInfo?.difference ? formatNumber(tickerPriceInfo?.difference, isBond ? 3 : 2) : 0} (${tickerPriceInfo?.differencePercentage ? formatNumber(tickerPriceInfo?.differencePercentage ?? 0, 2) : 0}%)`}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7}>
                    <BidsOffersTable
                        isBond={isBond}
                        buySell={buySell}
                        setLimitIndex={handleLimitIndex}
                        bids={tickerPriceInfo?.bids}
                        offers={tickerPriceInfo?.offers}
                    />
                    <St.MarketStatusContainer>
                        <InstrumentMarketStatus
                            term={term}
                            currentMarket={currentMarket}
                            isMarketClosed={isMarketClosed}
                        />
                    </St.MarketStatusContainer>
                </Grid>
            </Grid>
        </St.InstrumentContentWrapper>
    );
};

const propTypes = {
    ticker: PropTypes.string.isRequired,
    isBond: PropTypes.bool.isRequired,
    priceColor: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    isMarketClosed: PropTypes.bool.isRequired,
};

interface extraProps {
    instrument: GetInstrumentsItem | null;
    tickerPriceInfo?: InstrumentPricesDetail;
    currency: Currency;
    term: 'CI' | '24hs';
    currentMarket: MarketStatusTimes | null;
    handleLimitIndex: (value: LimitPriceStateInfo | null) => void;
    buySell: BUYSELL;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InstrumentDetailCard.propTypes = propTypes;

export default InstrumentDetailCard;
