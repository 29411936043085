import { ReactElement } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InfoLabel from './InfoLabel';
import PropTypes from 'prop-types';

const InfoLabelContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <InfoLabel {...childProps} />;
};

const propTypes = {
    label: PropTypes.string.isRequired,
    withoutSeparation: PropTypes.bool,
};

interface extraProps {
    label: string | null;
    text?: string | number | ReactElement;
    column?: number;
    fontSize?: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
InfoLabelContainer.propTypes = propTypes;

export default InfoLabelContainer;
