import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import Dropdown from './Dropdown';

const DropdownContainer = (props: Props) => {
    // const {} = props;

    const childProps = {
        ...props,
    };

    return <Dropdown {...childProps} />;
};

const propTypes = {};

interface extraProps {
    options: Array<{ id: string; name: string }>;
    selectedValue: { id: string; name: string } | null;
    onSelect: (value: { id: string; name: string }) => void;
    disableClearable?: boolean;
    label: string;
    minWidth: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
DropdownContainer.propTypes = propTypes;

export default DropdownContainer;
