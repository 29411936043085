import { useContext } from 'react';
import { AuthContext } from 'context/auth.context';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ClientsPage from './ClientsPage';
import { useTranslation } from 'react-i18next';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import CustomIconCreator from 'components/common/CustomIconCreator';
import StarEmpty from 'assets/icons/star-line.svg?react';
import StarFilled from 'assets/icons/star-line-filled.svg?react';
import Wallet from 'assets/icons/wallet.svg?react';
import CheckEmpty from 'assets/icons/checkbox-blank-circle-line.svg?react';
import CheckFilled from 'assets/icons/checkbox-blank-circle-fill.svg?react';
import { useAddFavoriteMarketAccount, useDeleteFavoriteMarketAccount } from 'hooks/api/auth.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { useNavigate } from 'react-router-dom';
import { MarketAccount } from 'types/api/marketAccount.types';
import { channelRoutesPaths } from 'router/routesPaths';
import { IconButton, Tooltip } from '@mui/material';

const ClientsPageContainer = (props: Props) => {
    const { t } = useTranslation();
    const { selectedCustomer, setSelectedCustomer } = useContext(AuthContext);
    const { setSnackBarMessage } = useSnackBar();
    const { mutate: addFavorite } = useAddFavoriteMarketAccount(setSnackBarMessage);
    const { mutate: deleteFavorite } = useDeleteFavoriteMarketAccount(setSnackBarMessage);
    const navigate = useNavigate();

    // TODO el back tiene que agregar el dato de favorito en el modelo del cliente
    const handleFavorite = (customerCode: number, favorite?: boolean) =>
        favorite
            ? deleteFavorite({ customerCodes: customerCode })
            : addFavorite({ customerCodes: customerCode });

    const handleGoToWallet = (row: MarketAccount) => {
        setSelectedCustomer(row);
        navigate(channelRoutesPaths.wallet);
    };

    const handleSelectClient = (row: MarketAccount) => setSelectedCustomer(row);

    const columns: CommonColumn<MarketAccount>[] = [
        {
            id: 'favorite',
            name: '',
            selector: ({ favorite, customerCode }) => (
                <Tooltip title={t('favorite')}>
                    <IconButton onClick={() => handleFavorite(Number(customerCode), favorite)}>
                        <CustomIconCreator icon={favorite ? StarFilled : StarEmpty} />
                    </IconButton>
                </Tooltip>
            ),
            align: 'center',
        },
        {
            id: 'customerCode',
            name: t('customer_code'),
            sortable: true,
        },
        {
            id: 'name',
            name: t('name'),
        },
        {
            id: 'last_name',
            name: t('last_name'),
        },
    ];
    const filters: CommonFilter[] = [];
    const actions: CommonAction<MarketAccount>[] = [
        {
            id: 'wallet',
            Icon: () => <CustomIconCreator icon={Wallet} />,
            onClick: ({ row }) => handleGoToWallet(row),
        },
        {
            id: 'selected_customer',
            Icon: () => <CustomIconCreator icon={CheckFilled} />,
            isHidden: ({ customerCode }) => selectedCustomer?.customerCode !== customerCode,
            onClick: ({ row }) => handleSelectClient(row),
        },
        {
            id: 'select_customer',
            Icon: () => <CustomIconCreator icon={CheckEmpty} />,
            onClick: ({ row }) => handleSelectClient(row),
            isHidden: ({ customerCode }) => selectedCustomer?.customerCode === customerCode,
        },
    ];

    const childProps = {
        ...props,
        columns,
        filters,
        actions,
        t,
    };

    return <ClientsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ClientsPageContainer.propTypes = propTypes;

export default ClientsPageContainer;
