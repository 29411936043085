import { styled, Card } from '@mui/material';

export default {
    Card: styled(Card)(() => ({
        width: '100%',
        height: '100%',
        position: 'relative',
        borderRadius: '0',
    })) as typeof Card,
    Span: styled('div')(() => ({
        position: 'absolute',
        bottom: '0',
        width: 'calc(100% - 38px)',
        padding: '12px 22px 12px 16px',
        fontSize: '1.063rem',
        backgroundColor: 'rgb(45 42 44 / 70%)',
        color: 'white',
    })),
    Img: styled('img')(() => ({
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    })),
};
