import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import PropTypes from 'prop-types';
import St from './OnboardingDniPhotoPage.styled';
import { Form, FormikContextType, FormikProvider } from 'formik';
import FileUploader from 'components/common/FileUploader';
import TopHeader from 'components/common/TopHeader';
import ErrorText from 'components/common/ErrorText';

export const OnboardingDniPhotoPage = (props: Props) => {
    const { t, formik, enableButton, errorMessage, isLoading } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <St.DniPhotoBox>
            <TopHeader
                firstElement={t('dni_photo_title') as string}
                title={t('dni_photo_subtitle')}
                subtitle={t('dni_photo_text')}
            />
            <FormikProvider value={formik}>
                <Form>
                    <St.DniPhotoInputs>
                        <FileUploader
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue as any}
                            label={t('dni_photo_file1')}
                            field="dniPhoto"
                        />
                        <FileUploader
                            values={values}
                            errors={errors}
                            setFieldValue={setFieldValue as any}
                            label={t('dni_photo_file2')}
                            field="dniSecondPhoto"
                        />
                    </St.DniPhotoInputs>
                    <St.DniPhotoButtonsBox>
                        <St.DniPhotoButtonsBoxInnerGrid>
                            <St.DniPhotoLoadingButton
                                type="submit"
                                variant="contained"
                                disabled={!enableButton}
                                loading={isLoading}
                                fullWidth>
                                {t('send_button')}
                            </St.DniPhotoLoadingButton>
                        </St.DniPhotoButtonsBoxInnerGrid>
                    </St.DniPhotoButtonsBox>
                    {errorMessage ? <ErrorText text={errorMessage} /> : null}
                </Form>
            </FormikProvider>
        </St.DniPhotoBox>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

interface extraProps {
    formik: FormikContextType<any>;
    enableButton: boolean;
    errorMessage: string | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingDniPhotoPage.propTypes = propTypes;

export default OnboardingDniPhotoPage;
