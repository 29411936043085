import { Box, styled, Typography } from '@mui/material';

export default {
    Title: styled(Typography)`
        font-family: 'Monument', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
            'Helvetica Neue', Arial, sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 4rem;
        line-height: 4rem;
        margin-bottom: 4rem;
        text-align: center;
        text-transform: uppercase;
        font-size: min(8vw, 4rem);
        line-height: min(8vw, 4rem);
    `,
};
