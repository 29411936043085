import React, { createContext, ReactNode } from 'react';
import { useGetFunds, useGetMarkets } from 'hooks/api/instruments.hooks';
import { GetFundsResponse, GetMarketTimesResponse } from 'types/api/instruments.types';

interface IntialState {
    funds?: GetFundsResponse[];
    isLoadingFunds: boolean;
    errorMessageFunds: string | null;
    marketsTimes?: GetMarketTimesResponse[];
    isLoadingMarkets: boolean;
    errorMessageMarkets: string | null;
}

export const MarketContext = createContext({} as IntialState);

const MarketContextProvider = ({ children }: { children: ReactNode }) => {
    const { funds, isLoading: isLoadingFunds, errorMessage: errorMessageFunds } = useGetFunds();

    const {
        data: marketsTimes,
        isLoading: isLoadingMarkets,
        errorMessage: errorMessageMarkets,
    } = useGetMarkets();

    const memoedValue = React.useMemo(() => {
        const value = {
            funds,
            marketsTimes,
            isLoadingFunds,
            isLoadingMarkets,
            errorMessageFunds,
            errorMessageMarkets,
        };
        return value;
    }, [
        funds,
        marketsTimes,
        isLoadingFunds,
        isLoadingMarkets,
        errorMessageFunds,
        errorMessageMarkets,
    ]);

    return <MarketContext.Provider value={memoedValue}>{children}</MarketContext.Provider>;
};

export default MarketContextProvider;
