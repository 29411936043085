import endpoints from 'api/onboarding/onboarding.api';
import { tErrorsContext } from 'constants/appConstants';
import { useTranslation } from 'react-i18next';
import { errorResponseHelper } from 'utils/helpers/errorsHelper';
import {
    questionsInvestmentDataAdapter,
    questionsNosisDataAdapter,
} from 'adapters/api/onboarding.adapter';
import { Nationality, State, WorkActivity } from 'types/api/onboarding.types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ITranslate } from 'types/common/general.types';
import React from 'react';
import { t } from 'i18next';

export const useGetNosisQuestions = (setValidationFailedErrorMessage: (value: string) => void) => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['getNosisQuestions'],
        () => endpoints.getNosisQuestions(),
        {
            select: data => questionsNosisDataAdapter(data.data),
            onError() {
                setValidationFailedErrorMessage(t('blocked_user_error', tErrorsContext));
            },
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        errorMessage: error ? errorResponseHelper(error) : null,
        refetch,
    };
};

export const useGetOnboardingLegalData = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.getLegalData);
    return {
        mutate,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};
export const useGetValidateNosisAnswers = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.getValidateNosisAnswers);
    return {
        mutate,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};

export const useStartOnboarding = (setShowNosisQuestions: (value: boolean) => void) => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.startOnboarding, {
        onSuccess: () => {
            setShowNosisQuestions(true);
        },
    });
    return {
        mutate,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};

export const useGetSteps = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.getOnboardingSteps);
    return {
        mutate,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};
export const useGetBlogNews = () => {
    const { t } = useTranslation();
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['getBlogNews'],
        () => endpoints.getBlogNews(),
        {
            select: data => data.data.news.slice(0, 3),
        },
    );

    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: t(
            (error as any)?.response?.data?.error_messages[0].code ?? '',
            tErrorsContext,
        ),
    };
};

export const usePersonalDataStep = () => {
    const { mutate, isLoading, error } = useMutation(endpoints.uploadPersonalDataStep);
    return {
        mutate,
        isLoading,
        error: error ? errorResponseHelper(error) : null,
    };
};

export const useGetNationalities = () => {
    const { data, isLoading } = useQuery(['nationalities'], () => endpoints.getNationalities(), {
        select: data => {
            return data?.data.nationalities.map(
                n => ({ id: n.nationalityId, name: n.nationality }) as Nationality,
            );
        },
    });
    return {
        data,
        isLoading,
    };
};
export const useGetWorkActivities = () => {
    const { data, isLoading } = useQuery(['workActivities'], () => endpoints.getWorkActivities(), {
        select: data => {
            return data?.data.activities.map(
                n => ({ id: n.activityId, name: n.activity }) as WorkActivity,
            );
        },
    });
    return {
        data,
        isLoading,
    };
};

export const useComplementaryDataPage = (t: ITranslate) => {
    const keys = [
        { inputKey: 'pepCharge', inputType: 'text', key: 'PEP' },
        { key: 'isUIF' },
        { inputKey: 'ssn', inputType: 'number', key: 'isUSA' },
        { inputKey: 'countryTaxResidence', inputType: 'autocomplete', key: 'OCDE' },
    ];

    const itemsLi = React.useMemo(
        () =>
            keys.map((el, index) => ({
                formKey: el.key,
                inputKey: el.inputKey,
                inputType: el.inputType,
                text: t(`complementary_data_li_${index + 1}`),
                tooltipText: t(`complementary_data_li_${index + 1}_tooltip`),
            })),
        [t],
    );

    const VATConditionArticles = React.useMemo(
        () =>
            [...Array(7).keys()].map((el, index) => ({
                id: index,
                name: t(`complementary_data_VAT_condition_article_${el + 1}`),
            })),
        [t],
    );

    const incomeRegistrationArticles = React.useMemo(
        () =>
            [...Array(4).keys()].map((el, index) => ({
                id: index,
                name: t(`complementary_data_income_registration_article_${el + 1}`),
            })),
        [t],
    );
    const workActivityArticles = React.useMemo(
        () =>
            [...Array(24).keys()].map((el, index) => ({
                id: index,
                name: t(`complementary_data_work_activity_article_${el + 1}`),
            })),
        [t],
    );
    return { itemsLi, VATConditionArticles, incomeRegistrationArticles, workActivityArticles };
};

export const useComplementaryDataPost = () => {
    const { mutate, isLoading, error, isError } = useMutation(
        endpoints.uploadComplementaryDataStep,
    );
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: isError && errorResponseHelper(error),
    };
};

export const useUploadDocument = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.uploadDocument, {});
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};

export const useIncomeProof = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.uploadProof, {
        onSuccess: async ({ data }: { data: any }) => {
            // navigate();
        },
        // onError: async (err: any) => {
        //     const body = JSON.parse(err.config.data);
        // },
    });
    return {
        mutate,
        isLoading,
        isError,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetValidateInvestmentTestAnswers = () => {
    const { mutate, data, isLoading, error, isError } = useMutation(
        endpoints.getValidateInvestmentTestAnswers,
    );
    return {
        mutate,
        data: data?.data,
        isLoading,
        errorMessage: isError && errorResponseHelper(error),
    };
};
export const useUploadContactInfoStep = () => {
    const { mutate, isLoading, error } = useMutation(endpoints.uploadContactInfoStep);
    return {
        mutate,
        isLoading,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetInvestmentTestQuestions = () => {
    const { data, isLoading, error } = useQuery(
        ['getInvestmentTestQuestions'],
        () => endpoints.getInversorTestQuestions(),
        {
            select: data => questionsInvestmentDataAdapter(data.data),
        },
    );
    return {
        data,
        isLoading,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};

export const useGetContract = () => {
    const { data, isLoading, error } = useQuery(
        ['getContract'],
        () => endpoints.getContractPath(),
        {
            select: data => {
                const { contractPath, tycPath } = data.data;
                const keys = [
                    'https://dev-ctio-files.s3.sa-east-1.amazonaws.com/',
                    'https://stg-ctio-files.s3.sa-east-1.amazonaws.com/',
                    'https://prd-ctio-files.s3.sa-east-1.amazonaws.com/',
                    'https://uat-ctio-files.s3.sa-east-1.amazonaws.com/',
                ];
                const key = keys.find(k => tycPath.includes(k));
                return {
                    contractPath,
                    tycPath: key ? tycPath.split(key)[1] : tycPath,
                };
            },
        },
    );
    return {
        data,
        isLoading,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};
export const useUploadContractStep = () => {
    const { mutate, isLoading, error, isError } = useMutation(endpoints.uploadContractStep);
    return {
        mutate,
        isLoading,
        errorMessage: isError ? errorResponseHelper(error) : null,
    };
};
export const useGetReferenceStates = () => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery(
        ['states'],
        () => endpoints.getStates(),
        {
            select: data => data.data.states.map(s => ({ id: s.stateId, name: s.state }) as State),
        },
    );
    return {
        data,
        isLoading: isLoading || isRefetching,
        refetch,
        errorMessage: error ? errorResponseHelper(error) : null,
    };
};
