import React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MarketCollateralsPage from './MarketCollateralsPage';
import { useTranslation } from 'react-i18next';
import CustomIconCreator from 'components/common/CustomIconCreator';
import EyeIcon from 'assets/icons/eye-icon.svg?react';
import InformationLineIcon from 'assets/icons/information-line.svg?react';
import DeleteIcon from 'assets/icons/delete-bin-7-line.svg?react';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { formatDateWithHour } from 'utils/helpers/dateHelper';
import { GetCollateralOrderItem } from 'types/api/orders.types';
import { ICON_CELL_SIZE, tCommon } from 'constants/appConstants';
import { Chip } from '@mui/material';
import { getColorByStatus } from 'utils/helpers/commonHelper';
import { AuthContext } from 'context/auth.context';
import AddIcon from 'assets/icons/add-line.svg?react';
import OrderForm from './OrderForm';
import DeleteModal from './DeleteModal';

const MarketCollateralsPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('market');
    const tBase = 'market_collaterals_';
    const { marketAccounts } = React.useContext(AuthContext);

    const columns: CommonColumn<GetCollateralOrderItem>[] = [
        {
            id: 'createdAt',
            name: t(`${tBase}column_date`),
            selector: row => formatDateWithHour(row.createdAt),
        },
        {
            id: 'code',
            name: t(`${tBase}comitente`),
            selector: row => row.customerCode,
        },
        {
            id: 'client',
            name: t(`${tBase}column_client`),
            selector: ({ user }) => (user ? `${user.name} ${user.lastName}` : '-'),
        },
        {
            id: 'buySell',
            name: t(`${tBase}order_type`),
            selector: row =>
                t(`${tBase}order_${row.buySell === 'SELL' ? 'taker' : 'placer'}`).toUpperCase(),
        },
        {
            id: 'currency',
            name: t('currency', tCommon),
            selector: row => row.currency,
        },
        {
            id: 'amount',
            name: t('amount', tCommon),
            selector: row => row.amount,
        },
        {
            id: 'rate',
            name: t(`${tBase}rate`),
            selector: ({ isMarketRate, rate }) => (isMarketRate ? 'MKT' : `${rate}%`),
        },
        {
            id: 'liquidationDatetime',
            name: t('term', tCommon),
            selector: row => row.term,
        },
        {
            id: 'state',
            name: t('status', tCommon),
            selector: row => (
                <Chip sx={{ ...getColorByStatus(row.status) }} label={t(row.status, tCommon)} />
            ),
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'orderType',
            label: t(`${tBase}order_type`),
            type: 'select',
            query: 'buySell',
            options: [
                { id: 'SELL', name: t(`${tBase}order_taker`) },
                { id: 'BUY', name: t(`${tBase}order_placer`) },
            ],
        },
        {
            id: 'status',
            label: t('status', tCommon),
            type: 'select',
            query: 'status',
            options: [
                { id: 'PENDING', name: t('PENDING', tCommon) },
                { id: 'COMPLETED', name: t('COMPLETED', tCommon) },
                { id: 'REJECTED', name: t('REJECTED', tCommon) },
                { id: 'EXPIRED ', name: t('EXPIRED', tCommon) },
                { id: 'CANCELLED ', name: t('CANCELLED', tCommon) },
            ],
        },
        {
            id: 'customerCode',
            label: t(`${tBase}comitente`),
            type: 'select',
            query: 'customerCode',
            options:
                marketAccounts?.map(el => ({ id: el.customerCode, name: el.customerCode })) ?? [],
        },
        {
            id: 'client',
            label: t(`${tBase}column_client`),
            type: 'select',
            query: 'customerCode',
            options: marketAccounts?.map(el => ({ id: el.customerCode, name: el.name })) ?? [],
        },
        {
            id: 'dateFrom',
            label: t('from', tCommon),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tCommon),
            type: 'date',
            query: 'dateTo',
        },
    ];

    const actions: CommonAction<GetCollateralOrderItem>[] = [
        {
            id: 'detail',
            type: 'modal',
            Icon: props => <CustomIconCreator icon={EyeIcon} />,
            onClick: props => (
                <div>
                    <h1>detalle</h1>
                </div>
            ),
            isHidden: row => row.buySell === 'BUY', // tomadora
        },
        {
            id: 'rejected_reason',
            type: 'modal',
            Icon: props => <CustomIconCreator icon={InformationLineIcon} />,
            onClick: props => <p>{props.row.reason ?? '-'}</p>,
            isHidden: row => !row.reason,
        },
        {
            type: 'modal',
            id: 'delete_collateral',
            Icon: props => <CustomIconCreator icon={DeleteIcon} />,
            onClick: (props: any) => <DeleteModal {...props} />,
            isHidden: row => !(row.status === 'PENDING'),
        },
    ];

    const headAction: CommonAction<GetCollateralOrderItem> = {
        id: 'create',
        type: 'modal',
        Icon: () => <CustomIconCreator icon={AddIcon} sx={{ ...ICON_CELL_SIZE, mt: 0.7 }} />,
        onClick: props => <OrderForm {...props} />,
    };

    const childProps = {
        ...props,
        columns,
        actions,
        filters,
        headAction,
        t,
    };

    return <MarketCollateralsPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketCollateralsPageContainer.propTypes = propTypes;

export default MarketCollateralsPageContainer;
