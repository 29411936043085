import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './NoPermissionsPage.styled';
import HeadLogo from 'components/common/HeadLogo';

const NoPermissionsPage = (props: Props) => {
    const { t, backToHome, goToMail } = props;

    return (
        <St.ContainerPage>
            <HeadLogo />

            <St.Title variant="h1">{t('no_permission_title')}</St.Title>
            <St.Subtitle variant="h2">{t('no_permission_description')}</St.Subtitle>
            <St.Message variant="body1">{t('no_permission_help')}</St.Message>
            <St.ActionButtons>
                <St.HomeLink onClick={backToHome}>{t('no_permission_button_home')}</St.HomeLink>
                <St.HomeLink onClick={goToMail}>{t('no_permission_button_mail')}</St.HomeLink>
            </St.ActionButtons>
        </St.ContainerPage>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    backToHome: () => void;
    goToMail: () => string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
NoPermissionsPage.propTypes = propTypes;

export default NoPermissionsPage;
