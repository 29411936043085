import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import { Outlet } from 'react-router-dom';
import HelmetLayout from '../HelmetLayout';
import NavBarLayout from '../NavBarLayout';
import { SubRouteInfo } from 'utils/helpers/routesHelper';
import FooterLayout from '../FooterLayout';
import HelpTooltip from 'components/common/HelpTooltip';
import ChannelLayout from '../ChannelLayout';
import TermsConditionsModal from 'components/common/TermsConditionsModal';
import MarketContextProvider from 'context/market.context';
import St from './MainLayout.styled';
import MobileLayout from '../MobileLayout';
import EnableMfaModal from 'components/common/EnableMfaModal';

const MainLayout = (props: Props) => {
    const { routes, isTestExpired, isChannel, isMobile, forceMFA, isTermsAccepted } = props;

    return (
        <MarketContextProvider>
            <HelmetLayout withOutlet={false} />
            <St.MainWrapper component="main">
                {isMobile ? <MobileLayout routes={routes} /> : <NavBarLayout routes={routes} />}
                {isChannel && <ChannelLayout isMobile={isMobile} />}
                <St.SectionWrapper component="section">
                    <St.PageWrapper>
                        <Outlet />
                    </St.PageWrapper>
                </St.SectionWrapper>
                <FooterLayout />
                {!isTermsAccepted && <TermsConditionsModal open={!isTermsAccepted} />}
                {forceMFA && <EnableMfaModal open={forceMFA} />}
                {!isTestExpired && <HelpTooltip />}
            </St.MainWrapper>
        </MarketContextProvider>
    );
};

const propTypes = {
    isTestExpired: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isChannel: PropTypes.bool,
    forceMFA: PropTypes.bool.isRequired,
};

interface extraProps {
    routes: Array<SubRouteInfo>;
    isTermsAccepted: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MainLayout.propTypes = propTypes;

export default MainLayout;
