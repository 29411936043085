// ReplacePasswordForm.tsx
import * as React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PasswordField from 'components/common/PasswordField';
import { FormikContextType, FormikProvider } from 'formik';
import St from './ReplacePasswordForm.styled';
import CustomInput from 'components/common/CustomInput';
import ErrorText from 'components/common/ErrorText';
import TopHeader from 'components/common/TopHeader';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CircleWithNumber from 'components/common/CircleWithNumber';
import ActionButton from 'components/common/ActionButton';

const ReplacePasswordForm = (props: Props) => {
    const {
        t,
        isLoading,
        formik,
        errorMessage,
        isSigningUp,
        getPasswordErrorClass,
        handleGoBackLogin,
    } = props;
    const { errors, values, setFieldValue } = formik;

    return (
        <FormikProvider value={formik}>
            <St.Form>
                <Stack spacing={1} rowGap={'1rem'}>
                    {isSigningUp && (
                        <St.NumberBox>
                            <CircleWithNumber number={1} />
                            <CircleWithNumber number={2} />
                            <CircleWithNumber activated number={3} />
                        </St.NumberBox>
                    )}
                    <TopHeader
                        title={t('generate_password')}
                        subtitle={t('security_requirements') as string}
                    />
                    <St.Ul>
                        <li
                            style={{
                                ...getPasswordErrorClass('minimum_letters', errors),
                            }}>
                            {t('password_requeriments_minimum_letters')}
                        </li>
                        <li
                            style={{
                                ...getPasswordErrorClass('one_number', errors),
                            }}>
                            {t('password_requeriments_one_number')}
                        </li>
                        <li style={{ ...getPasswordErrorClass('one_capital_letter', errors) }}>
                            {t('password_requeriments_one_capital_letter')}
                        </li>
                        <li
                            style={{
                                ...getPasswordErrorClass('one_lowercase_letter', errors),
                            }}>
                            {t('password_requeriments_one_lowercase_letter')}
                        </li>
                        <li
                            style={{
                                ...getPasswordErrorClass('one_special_character', errors),
                            }}>
                            {t('password_requeriments_one_special_character')}
                        </li>
                    </St.Ul>
                    <Typography variant="h6" gutterBottom fontFamily={'roboto'} fontSize={'16px'}>
                        {t('dont_share_your_password')}
                    </Typography>
                    <St.FieldsContainer>
                        <St.FieldWrapper>
                            <CustomInput
                                field="username"
                                errors={errors}
                                label={t('username')}
                                placeholder={t('username')}
                                setFieldValue={setFieldValue}
                                values={values}
                                autoFocus
                                helperText={isSigningUp ? t('new_username_requeriments') : ''}
                                regex={/^\S*$/}
                                inputProps={{ autocomplete: 'off' }}
                            />
                        </St.FieldWrapper>
                        <St.FieldWrapper>
                            <PasswordField
                                name="newPassword"
                                error={errors.newPassword as string}
                                label={t('new_password')}
                                placeholder={t('enter_new_password')}
                                onChange={({ target }) => {
                                    setFieldValue('newPassword', target.value);
                                }}
                                value={values.newPassword}
                                helperText={
                                    errors.newPassword || isSigningUp
                                        ? ''
                                        : t('password_requeriments')
                                }
                                inputProps={{ autocomplete: 'off' }}
                            />
                        </St.FieldWrapper>
                        <St.FieldWrapper>
                            <PasswordField
                                name="newPasswordConfirmation"
                                error={errors.newPasswordConfirmation as string}
                                helperText={errors.newPasswordConfirmation?.toString()}
                                label={t('change_password_repeat')}
                                placeholder={t('enter_new_password')}
                                onChange={({ target }) => {
                                    setFieldValue('newPasswordConfirmation', target.value);
                                }}
                                value={values.newPasswordConfirmation}
                                inputProps={{ autocomplete: 'off' }}
                            />
                        </St.FieldWrapper>
                        {isSigningUp && (
                            <St.FieldWrapper>
                                <CustomInput
                                    field="referralCode"
                                    errors={errors}
                                    label={t('referal_code')}
                                    placeholder={t('referal_code_placeholder')}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    autoFocus
                                    helperText={t('new_username_requeriments')}
                                    regex={/^\S*$/}
                                    inputProps={{ autocomplete: 'off' }}
                                />
                            </St.FieldWrapper>
                        )}
                        <St.FieldWrapper>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                fullWidth
                                loading={isLoading}>
                                {t('confirm_button')}
                            </LoadingButton>
                        </St.FieldWrapper>
                    </St.FieldsContainer>
                    {errorMessage && <ErrorText text={errorMessage} />}
                    {!isSigningUp && (
                        <ActionButton
                            sx={{ width: '250px', alignSelf: 'center' }}
                            variant="outlined"
                            color="primary"
                            disabled={isLoading}
                            onClick={handleGoBackLogin}>
                            {t('back')}
                        </ActionButton>
                    )}
                </Stack>
            </St.Form>
        </FormikProvider>
    );
};

const propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    formik: PropTypes.any.isRequired,
    isSigningUp: PropTypes.bool,
    errorMessage: PropTypes.string,
};

interface extraProps {
    t: (key: string) => string;
    isLoading: boolean;
    formik: FormikContextType<any>;
    errorMessage?: string;
    getPasswordErrorClass: (field: string, errors: any) => any;
    handleGoBackLogin: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReplacePasswordForm.propTypes = propTypes;

export default ReplacePasswordForm;
