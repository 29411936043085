import * as React from 'react';
/* import PropTypes from 'prop-types'; */
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import FundsTable from './FundsTable';
import { useTranslation } from 'react-i18next';
import { CommonColumn, HeaderStyles } from 'types/common/tableLayout.types';
import { formatPercentage } from 'utils/helpers/commonHelper';
import { MarketContext } from 'context/market.context';
import { GetFundsResponse } from 'types/api/instruments.types';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const FundsTableContainer = (props: Props) => {
    const { t } = useTranslation('home');

    const columns: CommonColumn<GetFundsResponse>[] = [
        {
            id: 'fundColor',
            name: '',
            selector: row => (
                <Box
                    style={{
                        backgroundColor: row.fundInfo.fundColor || '',
                        width: 20,
                        height: 20,
                        borderRadius: 100,
                    }}></Box>
            ),
            sortable: false,
        },
        {
            id: 'fundName',
            name: t('table_header_funds_family'),
            selector: row => row?.fundInfo?.shortName || '-',
            sortable: false,
        },
        {
            id: 'currency',
            name: '',
            selector: row => row.currency,
        },
        {
            id: 'performanceVcpPercentage',
            name: t('table_header_daily_performance'),
            selector: row => formatPercentage(row.fundInfo.performanceVcpPercentage || 0),
        },
        {
            id: 'performanceMtd',
            name: t('table_header_monthly_performance'),
            selector: row => formatPercentage(row.fundInfo.performanceMtd || 0),
        },
        {
            id: 'performanceYtd',
            name: t('table_header_yearly_performance'),
            selector: row => formatPercentage(row.fundInfo.performanceYtd || 0),
        },
        {
            id: 'performanceVcpPercentage12m',
            name: t('table_header_last_twelve_months_performance'),
            selector: row => formatPercentage(row.fundInfo.performanceVcpPercentage12m || 0),
        },
    ];

    const navigate = useNavigate();
    const { funds } = React.useContext(MarketContext);

    const [value, setValue] = React.useState('1');

    const formattedDate = moment().subtract(1, 'days').format('DD/MM/YYYY');
    const classAFunds = funds ? funds.filter(fund => fund.fundInfo.fundClass === 'Clase A') : [];
    const classBFunds = funds ? funds.filter(fund => fund.fundInfo.fundClass === 'Clase B') : [];

    const handleTabsChange = (e: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const onRowClick = (row: GetFundsResponse) => {
        navigate(row.fundInfo.fundUrl);
    };

    const rows = { items: value === '1' ? classAFunds : classBFunds };

    const headerStyles: HeaderStyles = {
        fontWeight: 'bold',
        fontSize: '15px',
    };

    const childProps = {
        ...props,
        rows,
        columns,
        value,
        handleTabsChange,
        headerStyles,
        formattedDate,
        t,
        onRowClick,
    };

    return <FundsTable {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
FundsTableContainer.propTypes = propTypes;

export default FundsTableContainer;
