import { useState } from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import TableLayoutHeader from './TableLayoutHeader';
import { useTranslation } from 'react-i18next';
import { CommonAction, CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import useExportData from 'hooks/common/CommonGrid/exportData.hooks';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';

const TableLayoutHeaderContainer = (props: Props) => {
    const { filterHeaderString, columns, fetchData, exportTitle, canExport } = props;
    const [showFilters, setShowFilters] = useState(false);
    const { t } = useTranslation();

    const toggleFilters = () => setShowFilters(!showFilters);

    const { loading: exportLoading, handleExportData } = useExportData(
        fetchData,
        columns,
        exportTitle,
        filterHeaderString,
    );

    const childProps = {
        ...props,
        showFilters,
        exportLoading,
        canExport,
        toggleFilters,
        handleExportData,
        t,
    };

    return <TableLayoutHeader {...childProps} />;
};

const propTypes = {};

interface extraProps {
    columns: CommonColumn<any>[];
    filterHeaderString: string;
    setFilterHeaderString(filterHeaderString: string): void;
    fetchData(params: GridHookParams): any;
    title?: string;
    filters?: CommonFilter[];
    createAction?: CommonAction<any>;
    exportTitle?: string;
    canExport?: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
TableLayoutHeaderContainer.propTypes = propTypes;

export default TableLayoutHeaderContainer;
