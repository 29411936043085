import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './RejectionModal.styled';
import { Step } from 'types/pages/onboardingHome.types';
import PropTypes from 'prop-types';
import CustomIconCreator from 'components/common/CustomIconCreator';
import CloseIcon from 'assets/icons/close-line.svg?react';

const RejectionModal = (props: Props) => {
    const { t, isOpenModal, handleClose, rejectSteps } = props;

    return (
        <St.Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpenModal}
            fullWidth
            maxWidth="sm">
            <St.DialogTitle id="customized-dialog-title">
                {t('rejection_modal_title')}
                <St.IconButton aria-label="close" onClick={handleClose}>
                    <CustomIconCreator icon={CloseIcon} alt="close-icon" />
                </St.IconButton>
            </St.DialogTitle>
            <St.DialogContent>
                <St.Stack spacing={2}>
                    {rejectSteps?.map(el => (
                        <St.Alert severity="error" key={el.stepNumber}>
                            <St.AlertTitle>{el.stepName}</St.AlertTitle>
                            {t('reason_with_body', { reason: el.reason ?? '-' })}
                        </St.Alert>
                    ))}
                </St.Stack>
            </St.DialogContent>
            <St.DialogActions>
                <St.ActionButton variant="outlined" onClick={handleClose}>
                    {t('rejection_modal_btn_text')}
                </St.ActionButton>
            </St.DialogActions>
        </St.Dialog>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    isOpenModal: boolean;
    rejectSteps?: Array<Step>;
    handleClose: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
RejectionModal.propTypes = propTypes;

export default RejectionModal;
