import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './CustomCarousel.styled';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import CustomDot from './CustomDot';

const CustomCarousel = (props: Props) => {
    const { children, width, matchesMd } = props;

    return (
        <St.CarouselContainer>
            <Carousel
                showThumbs={false}
                width={matchesMd ? window.innerWidth - 100 : (width ?? '700px')}
                renderIndicator={(clickHandler, isSelected, index, label) => (
                    <CustomDot
                        onClickHandler={clickHandler}
                        isSelected={isSelected}
                        index={index}
                        label={label}
                    />
                )}
                showStatus={false}
                showArrows={false}
                swipeable
                emulateTouch>
                {children}
            </Carousel>
        </St.CarouselContainer>
    );
};

const propTypes = {
    width: PropTypes.string,
    matchesMd: PropTypes.bool,
};

interface extraProps {
    children: any;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CustomCarousel.propTypes = propTypes;

export default CustomCarousel;
