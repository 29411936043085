import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import BlogPage from './BlogPage';
import { useTranslation } from 'react-i18next';
import { useGetBlogs, useGetLatestBlogs } from 'hooks/api/wrapper.hooks';
import { BlogArticle } from 'types/api/wrapper.types';

const BlogPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const { data, isLoading } = useGetBlogs();

    const { data: dataLatest, isLoading: isLoadingLatest } = useGetLatestBlogs();

    const groupedData = data?.reduce(
        (acc: { [key: string]: BlogArticle[] }, article: BlogArticle) => {
            const categoryName = article.category[0].nombre;
            if (!acc[categoryName]) acc[categoryName] = [];
            acc[categoryName].push(article);
            return acc;
        },
        {},
    );

    const dataLatestArray = dataLatest ? Object.values(dataLatest) : [];

    const childProps = {
        ...props,
        t,
        groupedData,
        isLoading,
        dataLatestArray,
        isLoadingLatest,
    };

    return <BlogPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
BlogPageContainer.propTypes = propTypes;

export default BlogPageContainer;
