import { SubRouteInfo } from 'utils/helpers/routesHelper';
import i18n from 'utils/i18n';
import BlogPage from 'components/pages/BlogPage';
import HomePage from 'components/pages/HomePage';
import MarketPreferredPage from 'components/pages/MarketPreferredPage';
import MarketEquitiesPage from 'components/pages/MarketEquitiesPage';
import MarketBondsPage from 'components/pages/MarketBondsPage';
import MarketFundsPage from 'components/pages/MarketFundsPage';
import MarketCedearsPage from 'components/pages/MarketCedearsPage';
import MarketInstrumentDetailPage from 'components/pages/MarketInstrumentDetailPage';
import MarketFundDetailPage from 'components/pages/MarketFundDetailPage';
import MarketOthersPage from 'components/pages/MarketOthersPage';
import ReportsTicketsPage from 'components/pages/ReportsTicketsPage';
import OperationsReferralOrdersPage from 'components/pages/OperationsReferralOrdersPage';
import OperationsFCIRequestsPage from 'components/pages/OperationsFCIRequestsPage';
import ClientsWalletPage from 'components/pages/ClientsWalletPage';
import ProfileDeleteAccountPage from 'components/pages/ProfileDeleteAccountPage';
import ProfileBankAccountPage from 'components/pages/ProfileBankAccountPage';
import ProfileOnboardingInvestmentTestPage from 'components/pages/OnboardingInvestmentTestPage';
import ProfilePersonalDataPage from 'components/pages/ProfilePersonalDataPage';
import ProfilePreferencesPage from 'components/pages/ProfilePreferencesPage';
import WalletDepositPage from 'components/pages/WalletDepositPage';
import { mainRoutesPaths } from '../routesPaths';
import WalletWithdrawalPage from 'components/pages/WalletWithdrawalPage';
import ReportsConsolidatedAccountPage from 'components/pages/ReportsConsolidatedAccountPage';

export const mainRoutes: Array<SubRouteInfo> = [
    {
        name: 'home',
        path: mainRoutesPaths.home,
        title: i18n.t('layout_main_navigation_home') as string,
        component: HomePage,
    },
    {
        name: 'wallet',
        title: i18n.t('layout_main_navigation_wallet') as string,
        subRoutes: [
            {
                name: 'briefcase',
                path: mainRoutesPaths.briefcase,
                title: i18n.t('layout_main_navigation_wallet_briefcase') as string,
                component: ClientsWalletPage,
            },
            {
                name: 'deposit',
                path: mainRoutesPaths.deposit,
                title: i18n.t('layout_main_navigation_wallet_deposit') as string,
                component: WalletDepositPage,
            },
            {
                name: 'withdrawal',
                path: mainRoutesPaths.withdrawal,
                title: i18n.t('layout_main_navigation_wallet_withdrawal') as string,
                component: WalletWithdrawalPage,
            },
        ],
    },
    {
        name: 'operations',
        title: i18n.t('layout_main_navigation_operations') as string,
        subRoutes: [
            {
                name: 'orders',
                path: mainRoutesPaths.orders,
                title: i18n.t('layout_main_navigation_operations_orders') as string,
                component: OperationsReferralOrdersPage,
            },
            {
                name: 'fci_request',
                path: mainRoutesPaths.referralFciRequest,
                title: i18n.t('layout_main_navigation_operations_referral_fci_request') as string,
                component: OperationsFCIRequestsPage,
            },
        ],
    },
    {
        name: 'market',
        title: i18n.t('layout_main_navigation_market') as string,
        subRoutes: [
            {
                name: 'equity',
                path: mainRoutesPaths.equity,
                title: i18n.t('layout_main_navigation_market_equity') as string,
                component: MarketEquitiesPage,
            },
            {
                name: 'bond',
                path: mainRoutesPaths.bonds,
                title: i18n.t('layout_main_navigation_market_bonuses') as string,
                component: MarketBondsPage,
            },
            {
                name: 'funds',
                path: mainRoutesPaths.funds,
                title: i18n.t('layout_main_navigation_market_funds') as string,
                component: MarketFundsPage,
            },
            {
                name: 'cedears',
                path: mainRoutesPaths.cedears,
                title: i18n.t('layout_main_navigation_market_cedears') as string,
                component: MarketCedearsPage,
            },
            {
                name: 'preferred',
                path: mainRoutesPaths.preferred,
                title: i18n.t('layout_main_navigation_market_preferred') as string,
                component: MarketPreferredPage,
            },
            {
                name: 'others',
                path: mainRoutesPaths.others,
                title: i18n.t('layout_main_navigation_market_others') as string,
                component: MarketOthersPage,
            },
        ],
    },
    {
        name: 'reports',
        title: i18n.t('layout_main_navigation_reports') as string,
        subRoutes: [
            {
                name: 'consolidated_account',
                path: mainRoutesPaths.consolidatedAccount,
                title: i18n.t('layout_main_navigation_reports_consolidated_account') as string,
                component: ReportsConsolidatedAccountPage,
            },
            // {
            //     name: 'receipts_and_invoices',
            //     path: mainRoutesPaths.receiptsInvoices,
            //     title: i18n.t('layout_main_navigation_reports_receipts_and_invoices') as string,
            //     component: ReportsTicketsPage,
            // },
            {
                name: 'tickets',
                path: mainRoutesPaths.tickets,
                title: i18n.t('layout_main_navigation_reports_tickets') as string,
                component: ReportsTicketsPage,
            },
        ],
    },
    {
        name: 'blog',
        path: mainRoutesPaths.blog,
        title: i18n.t('layout_main_navigation_blog') as string,
        component: BlogPage,
    },
    {
        name: 'profile',
        title: i18n.t('layout_main_navigation_profile') as string,
        subRoutes: [
            {
                name: 'personalData',
                path: mainRoutesPaths.profilePersonalData,
                title: i18n.t('layout_main_navigation_personal_data') as string,
                component: ProfilePersonalDataPage,
            },
            {
                name: 'bankAccounts',
                path: mainRoutesPaths.bankAccounts,
                title: i18n.t('layout_main_navigation_bank_accounts') as string,
                component: ProfileBankAccountPage,
            },
            {
                name: 'investmentTest',
                path: mainRoutesPaths.investmentTest,
                title: i18n.t('layout_main_navigation_investor_profile') as string,
                component: () => <ProfileOnboardingInvestmentTestPage prevTest />,
            },
            {
                name: 'preferences',
                path: mainRoutesPaths.preferences,
                title: i18n.t('layout_main_navigation_preferences') as string,
                component: ProfilePreferencesPage,
            },
            {
                name: 'deleteAccount',
                path: mainRoutesPaths.deleteAccount,
                title: i18n.t('layout_main_navigation_delete_account') as string,
                component: ProfileDeleteAccountPage,
            },
            // {
            //     name: 'replacePassword',
            //     path: mainRoutesPaths.replacePassword,
            //     title: i18n.t('layout_main_navigation_replace_password') as string,
            //     component: () => <h1>Cambiar contraseña</h1>,
            // },
        ],
    },
];

export const extraRoutes: Array<SubRouteInfo> = [
    {
        name: 'instrument-detail',
        path: mainRoutesPaths.instrumentDetail,
        component: () => <MarketInstrumentDetailPage />,
    },
    {
        name: 'fund-detail',
        path: mainRoutesPaths.fundDetail,
        component: () => <MarketFundDetailPage />,
    },
];
