import GoBackButton from 'components/common/GoBackButton';
import PropTypes from 'prop-types';
import {
    GetInstrumentsItem,
    InstrumentRule,
    InstrumentType,
    TickerRequieresDeclaration,
} from 'types/api/instruments.types';
import {
    InstrumentPricesDetail,
    LimitPriceStateInfo,
    MarketStatusTimes,
} from 'types/pages/marketInstrumentDetail.types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import InstrumentDetailCard from './InstrumentDetailCard';
import St from './MarketInstrumentDetailPage.styled';
import { CommonSelectType, Currency } from 'types/common/general.types';
import CircularSpinner from 'components/common/CircularSpinner';
import FavoriteButton from 'components/common/FavoriteButton';
import InstumentGraphicCard from './InstumentGraphicCard';
import InstrumentBuySell from './InstrumentBuySell';
import { GetAccountStatusResponse } from 'types/api/marketAccount.types';
import GeneralMarketSearchBar from 'components/common/GeneralMarketSearchBar';
import { BUYSELL } from 'types/api/orders.types';

const MarketInstrumentDetailPage = (props: Props) => {
    const {
        instrument,
        favorite,
        isLoading,
        ticker,
        tickerPriceInfo,
        term,
        rules,
        currency,
        accountStatus,
        isBond,
        setTerm,
        currencies,
        setLimitIndex,
        limitIndex,
        buySell,
        setBuySell,
        requiresDeclaration,
        getRouteByInstrumentType,
        isMarketClosed,
        currentMarket,
    } = props;
    return (
        <St.SectionWrapper>
            <St.HeaderContainer>
                <GoBackButton prevRoute={getRouteByInstrumentType(instrument?.type ?? null)} />
                <GeneralMarketSearchBar />
                <FavoriteButton favorite={favorite} ticker={ticker} />
            </St.HeaderContainer>
            {isLoading ? (
                <CircularSpinner />
            ) : (
                <>
                    <St.PageWrapper>
                        <InstrumentDetailCard
                            buySell={buySell}
                            setLimitIndex={setLimitIndex}
                            currency={currency}
                            term={term}
                            instrument={instrument}
                            ticker={ticker}
                            tickerPriceInfo={tickerPriceInfo}
                            isBond={isBond}
                            currentMarket={currentMarket}
                            isMarketClosed={isMarketClosed}
                        />
                        {!isMarketClosed && (
                            <InstrumentBuySell
                                term={term}
                                rules={rules}
                                ticker={ticker}
                                buySell={buySell}
                                setTerm={setTerm}
                                setBuySell={setBuySell}
                                limitIndex={limitIndex}
                                instrument={instrument}
                                currency={currency}
                                currencies={currencies}
                                tickerPriceInfo={tickerPriceInfo}
                                accountStatus={accountStatus}
                                isBond={isBond}
                                requiresDeclaration={requiresDeclaration}
                            />
                        )}
                    </St.PageWrapper>
                    <InstumentGraphicCard ticker={ticker} />
                </>
            )}
        </St.SectionWrapper>
    );
};

const propTypes = {
    favorite: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMarketClosed: PropTypes.bool.isRequired,
    isBond: PropTypes.bool.isRequired,
    ticker: PropTypes.string.isRequired,
    setTerm: PropTypes.func.isRequired,
    setBuySell: PropTypes.func.isRequired,
};

interface extraProps {
    instrument: GetInstrumentsItem | null;
    rules: InstrumentRule[] | null;
    tickerPriceInfo?: InstrumentPricesDetail;
    accountStatus?: GetAccountStatusResponse;
    term: 'CI' | '24hs';
    currency: Currency;
    currencies: CommonSelectType[] | null;
    limitIndex: LimitPriceStateInfo | null;
    setLimitIndex: (value: LimitPriceStateInfo | null) => void;
    buySell: BUYSELL;
    requiresDeclaration: TickerRequieresDeclaration;
    getRouteByInstrumentType: (value: InstrumentType | null) => string;
    currentMarket: MarketStatusTimes | null;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MarketInstrumentDetailPage.propTypes = propTypes;

export default MarketInstrumentDetailPage;
