import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import OnboardingContactInfoModal from './OnboardingContactInfoModal';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { tRequiredFieldError } from 'constants/appConstants';
import React from 'react';
import { FormikConfig, FormikValues, useFormik } from 'formik';
import { LegalData, State } from 'types/api/onboarding.types';
import { useTranslation } from 'react-i18next';
import { ContactInfoExtraData } from 'types/pages/onboardingContactInfo.types';
import { useGetReferenceStates } from 'hooks/api/onboarding.hooks';
import { REGEX_0_TO_9 } from 'utils/helpers/constants';

const getInitialValues = (formValues: LegalData, states?: State[]) => {
    return {
        floor: formValues?.floor ?? '',
        apartment: formValues?.apartment ?? '',
        state: states && formValues?.state ? states.find(s => s.name === formValues.state) : null,
        zipCode: formValues?.zipCode,
        city: formValues?.city,
        streetAddress: formValues?.streetAddress,
        streetNumber: formValues?.streetNumber,
        documentPath: null,
        sector: '',
        square: '',
        tower: '',
    };
};

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            streetAddress: Yup.string().required(tRequiredFieldError),
            streetNumber: Yup.number().required(tRequiredFieldError),
            floor: Yup.number(),
            apartment: Yup.string(),
            sector: Yup.string(),
            tower: Yup.string(),
            square: Yup.string(),
            city: Yup.string().required(tRequiredFieldError),
            zipCode: Yup.string().required(tRequiredFieldError),
            state: Yup.object()
                .shape({
                    id: Yup.number(),
                    name: Yup.string(),
                })
                .required(tRequiredFieldError)
                .nullable(),
            documentPath: Yup.string().required(tRequiredFieldError).nullable(),
        }),
    );

const OnboardingContactInfoModalContainer = (props: Props) => {
    const { setOpenModal, formValues, handleContactInfo, openModal } = props;
    const { t } = useTranslation('onboarding');
    const [fileLoading, setFileLoading] = React.useState(false);
    const { data: states, isLoading: isLoadingStates } = useGetReferenceStates();
    const formInputs = [
        { label: 'streetName', id: 'streetAddress' },
        { label: 'streetNum', id: 'streetNumber', regex: REGEX_0_TO_9 },
        { label: 'floor', id: 'floor', regex: REGEX_0_TO_9 },
        { label: 'apartment', id: 'apartment' },
        { label: 'sector', id: 'sector' },
        { label: 'tower', id: 'tower' },
        { label: 'square', id: 'square' },
        { label: 'city', id: 'city' },
        { label: 'zipCode', id: 'zipCode' },
    ];

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const formData = {
                ...values,
                state: values.state.name,
            };
            const extraData: ContactInfoExtraData = {
                documentPath: values.documentPath,
                tower: values.tower,
                sector: values.sector,
                square: values.square,
            };
            handleContactInfo(formData, extraData);
        },
        [handleContactInfo],
    );

    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(formValues as any, states),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit, formValues, states],
    );

    const formik = useFormik(formikInitProps);

    React.useEffect(() => {
        if (!openModal) formik.resetForm();
    }, [openModal]);

    const cleanForm = () => {
        formik.resetForm();
        formik.setValues({
            floor: '',
            apartment: '',
            zipCode: '',
            city: '',
            streetAddress: '',
            streetNumber: '',
            state: null,
        });
    };

    const childProps = {
        ...props,
        t,
        handleClose,
        setOpenModal,
        formik,
        // handleClearAllInputs,
        formInputs,
        states,
        isLoadingStates,
        cleanForm,
        fileLoading,
        setFileLoading,
    };

    return <OnboardingContactInfoModal {...childProps} />;
};

const propTypes = {
    handleContactInfo: PropTypes.func.isRequired,
};

interface extraProps {
    openModal: boolean;
    setOpenModal: (state: boolean) => void;
    formValues: LegalData | null;
    errorSubmit: string | null;
    isLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
OnboardingContactInfoModalContainer.prototype = propTypes;

export default OnboardingContactInfoModalContainer;
