import { Box, Grid, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';
import ArrowUpIcon from 'assets/icons/arrow-drop-up-line.svg?react';
import ArrowDownIcon from 'assets/icons/arrow-drop-down-line.svg?react';

const parseNumber = (value: string): number => {
    return parseFloat(value.replace(',', '.'));
};

export const getColor = (value: string): string => {
    const numericValue = parseNumber(value);
    return numericValue < 0 ? 'red !important' : 'green !important';
};

export const getIconDetails = (value: string) => {
    if (parseNumber(value) < 0) {
        return {
            icon: ArrowDownIcon,
            color: 'red',
            alt: 'arrow-down',
        };
    }
    return {
        icon: ArrowUpIcon,
        color: 'green',
        alt: 'arrow-up',
    };
};

export default {
    InstrumentDetailsContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            border: `1px solid ${theme.palette.divider}`,
            padding: '1rem',
            marginTop: '10px',
            borderRadius: pxToRem(8),
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[1],
        }),
    ),
    TextWithIconContainer: styled(Box)(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    })),
    Title: styled(Typography)(({ theme }) => ({
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    })),
    Description: styled(Typography)(({ theme }) => ({
        fontSize: '1.25rem',
        color: theme.palette.primary.main,
    })),
    BodyText: styled(Typography)(({ theme }) => ({
        fontSize: '0.875rem',
        color: 'grey',
        lineHeight: '1.5',
    })),
    ColumnLeft: styled(Grid)(({ theme }) => ({
        paddingRight: '1rem',
    })),
    ColumnRight: styled(Grid)(({ theme }) => ({
        paddingLeft: '1rem',
        textAlign: 'right',
    })),
};
