import { Box, styled, Typography } from '@mui/material';
import { pxToRem } from 'utils/helpers/stylesHelper';

export default {
    AccountStatusWrapper: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            position: 'relative',
            width: '100%',
        }),
    ),
    AccountStatusContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: '100%',
            backgroundColor: theme.palette.custom.vibrantblue10,
            padding: { xs: '2rem', sm: '3.5rem', md: '2.5rem', lg: '3.5rem' },
            margin: { xs: 'auto', md: '-1.5rem', lg: 'auto' },
            borderTopLeftRadius: pxToRem(40),
            borderBottomLeftRadius: pxToRem(40),
            borderBottomRightRadius: { xl: pxToRem(40), xs: pxToRem(40), lg: 0 },
            borderTopRightRadius: { xl: pxToRem(40), xs: pxToRem(40), lg: 0 },
            overflow: 'hidden',
            [theme.breakpoints.down(450)]: {
                overflow: 'scroll',
            },
        }),
    ),
    WalletTitle: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            textAlign: 'center',
            marginBottom: '10px',
            marginTop: '9rem',
        }),
    ),
    RadioButtonContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            width: { sm: '100%', xs: '100%' },
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '20px',
        }),
    ),
    NoDataChartContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.custom.vibrantblue,
        }),
    ),
    Amount: styled(Typography)(({ theme }) => theme.unstable_sx({})),
    TotalText: styled(Typography)(({ theme }) =>
        theme.unstable_sx({
            marginTop: pxToRem('10px'),
        }),
    ),
    ClientAmountContainer: styled(Box)(({ theme }) =>
        theme.unstable_sx({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'black',
            gap: '8px',
            marginBottom: '20px',
        }),
    ),
    ClientAmount: styled(Typography)(({ theme }) => theme.unstable_sx({})),
};
