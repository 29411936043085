import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import St from './LearnToInvestCards.styled';
import CardBlog from '../CardBlog';
import { BlogNews } from 'types/api/onboarding.types';

const LearnToInvestCards = (props: Props) => {
    const { t, handleClickSeeMore, blogNews } = props;

    return (
        <div>
            <St.SecondSectionWrapper>
                <St.SecondTitle>{t('learn_to_invest_title')}</St.SecondTitle>
                <St.ActionButton onClick={handleClickSeeMore}>{t('btn_see_more')}</St.ActionButton>
            </St.SecondSectionWrapper>
            <St.CardWrapper>
                {blogNews?.map((el, index) => <CardBlog key={index} text={el.text} url={el.url} />)}
            </St.CardWrapper>
        </div>
    );
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    blogNews?: Array<BlogNews>;
    handleClickSeeMore: () => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
LearnToInvestCards.propTypes = propTypes;

export default LearnToInvestCards;
