import { Box, Grid, styled, Typography } from '@mui/material';
import ActionButton from '../ActionButton';

export default {
    SecondSectionWrapper: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        button: {
            width: 'auto',
            height: 'fit-content',
            backgroundColor: theme.palette.custom.darkblue7,
            color: theme.palette.background.default,
            padding: '12px 24px',
            margin: '0',
            fontSize: '0.75rem',
            fontWeight: '400',
            lineHeight: '14.06px',
            letterSpacing: '0.016em',
            transition: '0.2s',
            '&:hover': {
                backgroundColor: theme.palette.custom.darkblue7,
                opacity: '0.8',
            },
        },
    })) as typeof Box,
    CardWrapper: styled(Grid)(({ theme }) =>
        theme.unstable_sx({
            display: 'grid',
            gap: '1.8rem',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: 'repeat(3,1fr)' },
            gridTemplateRows: '190px',
            m: '2rem 0 5rem',
        }),
    ) as typeof Grid,
    SecondTitle: styled(Typography)(({ theme }) => ({
        fontSize: '2.125rem',
        lineHeight: '39.84px',
        letterSspacing: '0.0025em',
        color: theme.palette.custom.darkblue2,
    })),
    ActionButton: styled(ActionButton)(() => ({})),
};
