import { Box, Divider, styled, Table, TableCell, TableContainer, Typography } from '@mui/material';
import { pxToRem, transientOptions } from 'utils/helpers/stylesHelper';

export default {
    TableContainer: styled(TableContainer)(({ theme }) => ({
        background: 'transparent',
        borderRadius: pxToRem(24),
        backgroundColor: theme.palette.custom.vibrantblue10,
        border: `1px solid #D6E5F8`,
    })),
    Table: styled(Table)(({ theme }) => ({})),
    HeaderCell: styled(TableCell)(({ theme }) => ({
        background: theme.palette.custom.vibrantblue10,
        border: 'none',
        textAlign: 'right',
        fontWeight: 700,
        color: theme.palette.custom.darkblue,
    })),
    Cell: styled(
        TableCell,
        transientOptions,
    )<{ $isPrice?: boolean; $canBeSelected: boolean }>(({ theme, $isPrice, $canBeSelected }) => ({
        background: theme.palette.custom.vibrantblue10,
        cursor: $isPrice && $canBeSelected ? 'pointer' : 'default',
        fontWeight: $canBeSelected ? 700 : 400,
        border: 'none',
        textAlign: 'right',
        color: theme.palette.custom.darkblue,
    })),
    TableHeader: styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'space-around',
        padding: '0.5rem',
    })),
    TableText: styled(Typography)(({ theme }) => ({
        textAlign: 'center',
        width: '100%',
        color: theme.palette.custom.darkblue3,
        lineHeight: '28.13px',
        fontWeight: 500,
        borderRight: `1px dotted ${theme.palette.custom.darkblue4}`,
        ':last-child': {
            border: 'none!important',
        },
    })),
    Divider: styled(Divider)(({ theme }) => ({
        marginLeft: '1rem !important',
        marginRight: '1rem !important',
        borderStyle: 'dotted',
        borderColor: theme.palette.custom.darkblue4,
    })),
};
