import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import ReportsConsolidatedAccountPage from './ReportsConsolidatedAccountPage';
import { useTranslation } from 'react-i18next';
import { tCommon } from 'constants/appConstants';
import { CommonColumn, CommonFilter } from 'types/common/tableLayout.types';
import { formatDate } from 'utils/helpers/dateHelper';
import { ReportTransaction } from 'types/pages/reports.types';
import { AuthContext } from 'context/auth.context';
import { formatNumber } from 'utils/helpers/commonHelper';

const ReportsConsolidatedAccountPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('reports');
    const tBase = 'reports_tickets_page_column_';

    const { customerCode } = React.useContext(AuthContext);

    console.log(customerCode);

    const columns: CommonColumn<ReportTransaction>[] = [
        {
            id: 'agreementDate',
            name: t(`${tBase}concertation_date`),
            selector: ({ agreementDate }) => (agreementDate ? formatDate(agreementDate) : '-'),
            exportOption: row => row.agreementDate ?? '-',
        },
        {
            id: 'liquidationDate',
            name: t(`${tBase}liquidation_date`),
            selector: ({ liquidationDate }) =>
                liquidationDate ? formatDate(liquidationDate) : '-',
            exportOption: row => row.liquidationDate ?? '-',
        },
        {
            id: 'instrumentCode',
            name: t(`${tBase}instrument_code`),
            selector: row => row.instrumentCode ?? '-',
            exportOption: row => row.instrumentCode ?? '-',
        },
        {
            id: 'instrument',
            name: t(`${tBase}instrument`),
            selector: row => row.instrument ?? '-',
            exportOption: row => row.instrument ?? '-',
        },
        {
            id: 'detail',
            name: t(`${tBase}detail`),
            selector: row => row.detail ?? '-',
            exportOption: row => row.detail ?? '-',
        },
        {
            id: 'quantity',
            name: t(`${tBase}quantity`),
            selector: row => (row.quantity != null ? formatNumber(row.quantity) : '-'),
            exportOption: row => String(row.quantity ?? '-'),
        },
        {
            id: 'gross',
            name: t(`${tBase}import_gross`),
            selector: row => (row.gross != null ? formatNumber(row.gross) : '-'),
            exportOption: row => String(row.gross ?? '-'),
        },
        {
            id: 'price',
            name: t('price', tCommon),
            selector: row => (row.price != null ? formatNumber(row.price) : '-'),
            exportOption: row => String(row.price ?? '-'),
        },
        {
            id: 'net',
            name: t(`${tBase}import_net`),
            selector: row => (row.net != null ? formatNumber(row.net) : '-'),
            exportOption: row => String(row.net ?? '-'),
        },
        {
            id: 'balance',
            name: t(`${tBase}balance`),
            selector: row => (row.balance != null ? formatNumber(row.balance) : '-'),
            exportOption: row => String(row.balance ?? '-'),
        },
        {
            id: 'currencyCode',
            name: t(`${tBase}currency_code`),
            selector: row => row.currencyCode ?? '-',
            exportOption: row => row.currencyCode ?? '-',
        },
        {
            id: 'marketFee',
            name: t(`${tBase}market_fee`),
            selector: row => (row.marketFee != null ? formatNumber(row.marketFee) : '-'),
            exportOption: row => String(row.marketFee ?? '-'),
        },
        {
            id: 'tariff',
            name: t(`${tBase}tariff`),
            selector: row => (row.tariff != null ? formatNumber(row.tariff) : '-'),
            exportOption: row => String(row.tariff ?? '-'),
        },
        {
            id: 'tariffVat',
            name: t(`${tBase}tariff_vat`),
            selector: row => (row.tariffVat != null ? formatNumber(row.tariffVat) : '-'),
            exportOption: row => String(row.tariffVat ?? '-'),
        },
        {
            id: 'marketFeeVat',
            name: t(`${tBase}market_fee_vat`),
            selector: row => (row.marketFeeVat != null ? formatNumber(row.marketFeeVat) : '-'),
            exportOption: row => String(row.marketFeeVat ?? '-'),
        },
    ];

    const filters: CommonFilter[] = [
        {
            id: 'dateType',
            label: t('reports_tickets_page_date_type'),
            type: 'select',
            query: 'byLiquidation',
            options: [
                { id: 'true', name: t('reports_tickets_page_for_liquidation') },
                { id: 'false', name: t('reports_tickets_page_for_concertation') },
            ],
        },
        {
            id: 'dateFrom',
            label: t('from', tCommon),
            type: 'date',
            query: 'dateFrom',
        },
        {
            id: 'dateTo',
            label: t('to', tCommon),
            type: 'date',
            query: 'dateTo',
        },
        {
            id: 'text',
            label: t('filter_by_detail', tCommon),
            type: 'text',
            query: 'detail',
        },
    ];

    const childProps = {
        ...props,
        columns,
        filters,
        t,
    };

    return <ReportsConsolidatedAccountPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
ReportsConsolidatedAccountPageContainer.propTypes = propTypes;

export default ReportsConsolidatedAccountPageContainer;
