import {
    GetReportTicketsResponse,
    GetReportTransactionResponse,
} from 'types/api/marketAccount.types';
import { ReportTicket, ReportTransaction } from 'types/pages/reports.types';

export const reportTicketAdapter = (data: GetReportTicketsResponse) => {
    return {
        ...data,
        items: data.items.map(ticket => {
            const { id, quantity, price, gross, tariff, fee, currency, net, source, interest } =
                ticket;
            return {
                customerCode: ticket.customer_code,
                id,
                formId: ticket.form_id,
                typeSpecies: ticket.type_species,
                instrumentDescription: ticket.instrument_description,
                tickerId: ticket.ticker_id,
                expirationDate: ticket.expiration_date,
                ticketOperationNumber: ticket.ticket_operation_number,
                agreementDate: ticket.agreement_date,
                liquidationDate: ticket.liquidation_date,
                operationType: ticket.operation_type,
                quantity,
                price,
                gross,
                tariff,
                fee,
                marketFee: ticket.market_fee,
                currency,
                net,
                source,
                tariffCurrency: ticket.tariff_currency,
                feeCurrency: ticket.fee_currency,
                isCanceled: ticket.is_canceled,
                cancellationDate: ticket.cancellation_date,
                interest,
                accruedInterest: ticket.accrued_interest,
                counterpartyTariffVat: ticket.counterparty_tariff_vat,
                tariffVat: ticket.tariff_vat,
                feeVat: ticket.fee_vat,
                executions: ticket.executions.map(el => ({
                    ticketId: el.ticket_id,
                    minuteNumber: el.minute_number,
                    executionNumber: el.execution_number,
                    orderCode: el.order_code,
                    orderNumber: el.order_number,
                    quantity: el.quantity,
                    price: el.price,
                })),
            } as ReportTicket;
        }),
    };
};

export const reportTransactionsAdapter = (data: GetReportTransactionResponse) => {
    return {
        ...data,
        items: data.items.map(transaction => {
            const {
                detail,
                balance,
                quantity,
                price,
                gross,
                tariff,
                net,
                source,
                instrument,
                depositary,
                ndnc,
                timestamp,
            } = transaction;
            return {
                internalCustomerCode: transaction.internal_customer_code,
                internalOperationCode: transaction.internal_customer_code,
                internalMovementCode: transaction.internal_movement_code,
                agreementDate: transaction.agreement_date,
                liquidationDate: transaction.liquidation_date,
                orderDate: transaction.order_date,
                detail,
                isAvailable: transaction.is_available,
                instrument,
                instrumentCode: transaction.instrument_code,
                currencyCode: transaction.currency_code,
                quantity,
                price,
                gross,
                net,
                balance,
                balanceLocalCurrency: transaction.balance_local_currency,
                isPreviousBalance: transaction.is_previous_balance,
                itemType: transaction.item_type,
                formCode: transaction.form_code,
                formId: transaction.form_id,
                checkingAccountValue: transaction.checking_account_value,
                marketFee: transaction.market_fee,
                tariffPercentage: transaction.tariff_percentage,
                tariff,
                tariffVat: transaction.tariff_vat,
                feeExchangePercentage: transaction.fee_exchange_percentage,
                marketFeePercentage: transaction.market_fee_percentage,
                marketFeeVat: transaction.market_fee_vat,
                speciesCode: transaction.species_code,
                isBalance: transaction.is_balance,
                quotedCurrencyCode: transaction.quoted_currency_code,
                source,
                timestamp,
                depositaryAccountNumber: transaction.depositary_account_number,
                depositary,
                depositaryCode: transaction.depositary_code,
                movementCurrencySymbol: transaction.movement_currency_symbol,
                movementCurrencyIso: transaction.movement_currency_iso,
                currencyExchange: transaction.currency_exchange,
                ndnc,
                isCanceled: transaction.is_canceled,
                movementCode: transaction.movement_code,
            } as ReportTransaction;
        }),
    };
};
