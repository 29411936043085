import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import WalletWithdrawalPage from './WalletWithdrawalPage';
import { useTranslation } from 'react-i18next';
import { useGetBankAccounts, useWithdrawal } from 'hooks/api/cahsflow.hooks';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';
import { tCommon, tRequiredFieldError } from 'constants/appConstants';
import {
    CreateWithdrawalRequest,
    GetBankAccountsItem,
    GetBankAccountsResponse,
} from 'types/api/cashflow.types';
import { AuthContext } from 'context/auth.context';
import { useRequestOtp } from 'hooks/api/auth.hooks';
import useSnackBar from 'hooks/common/snackbar.hooks';
import { GridHookParams } from 'types/common/CommonGrid/CommonGridFormModal.types';

const getInitialValues = () => ({
    destinationAccount: '',
    amountToExtract: '',
});

const getValidationSchema = (
    arsAvailable: number | undefined,
    usdAvailable: number | undefined,
    usdcAvailable: number | undefined,
    bankAccounts: GetBankAccountsResponse | undefined,
    t: (key: string) => string,
) =>
    Yup.object().shape({
        destinationAccount: Yup.string().required(tRequiredFieldError),
        amountToExtract: Yup.number()
            .required(tRequiredFieldError)
            .test('amountToExtract', t('error_amount'), function (value) {
                const typeAccount = bankAccounts?.items.find(
                    (item: GetBankAccountsItem) => item.id === this.parent.destinationAccount,
                )?.currency;
                switch (typeAccount) {
                    case 'ARS':
                        if (
                            value !== undefined &&
                            arsAvailable !== undefined &&
                            value < arsAvailable
                        )
                            return true;
                        break;
                    case 'USD':
                        if (
                            value !== undefined &&
                            usdAvailable !== undefined &&
                            value < usdAvailable
                        )
                            return true;
                        break;
                    case 'USDC':
                        if (
                            value !== undefined &&
                            usdcAvailable !== undefined &&
                            value < usdcAvailable
                        )
                            return true;
                        break;
                    default:
                        break;
                }
                return false;
            }),
    });

const WalletWithdrawalPageContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation('wallet');
    const { data: bankAccounts, isLoading } = useGetBankAccounts({} as GridHookParams);
    const { data, refetch } = useGetAccountStatus();
    const { customerCode, userData } = React.useContext(AuthContext);
    const { SnackBar, setSnackBarMessage } = useSnackBar();
    const {
        withdrawal,
        isLoadingWithdrawal,
        error: errorSubmit,
    } = useWithdrawal(setSnackBarMessage);
    const {
        mutate: senEmailtoDisableMfa,
        isLoading: isLoadingRequestOtp,
        error: errorRequestOtp,
    } = useRequestOtp();
    const [open, setOpen] = React.useState<'OTP' | 'SUCCESS' | null>(null);
    const [payload, setPayload] = React.useState<CreateWithdrawalRequest | null>(null);
    const arsAvailable = data?.availableBalance.withdraw.ars;
    const usdAvailable = data?.availableBalance.withdraw.usd;
    const usdcAvailable = data?.availableBalance.withdraw.usdc;

    const handleWithdrawal = (
        body: CreateWithdrawalRequest,
        customerCode: string,
        otp?: string,
    ) => {
        withdrawal(
            { ...body, customerCode, otp },
            {
                onSuccess: () => {
                    setOpen('SUCCESS');
                    refetch();
                },
            },
        );
    };

    const handleSubmit = React.useCallback(
        async (values: FormikValues) => {
            const body: CreateWithdrawalRequest = {
                accountUuid: values.destinationAccount,
                amount: values.amountToExtract,
                entryDate: new Date().toISOString(),
                customerCode,
                withdrawalCondition: [],
                comment: '',
                operableUserUuid: userData?.id,
                validateOtp: false,
            };
            if (userData?.mfaType === 'AUTHENTICATOR' || userData?.mfaType === 'EMAIL') {
                body.validateOtp = true;
                setOpen('OTP');
                setPayload(body);
            } else {
                handleWithdrawal(body, customerCode as string);
            }
        },
        [customerCode, userData?.mfaType],
    );
    const formikInitProps = React.useMemo(
        () => ({
            initialValues: getInitialValues(),
            validateOnChange: false,
            validationSchema: getValidationSchema(
                arsAvailable,
                usdAvailable,
                usdcAvailable,
                bankAccounts,
                t,
            ),
            onSubmit: handleSubmit,
        }),
        [handleSubmit, arsAvailable, usdAvailable, usdcAvailable, bankAccounts, t],
    );

    const formik = useFormik(formikInitProps);

    const handleClose = () => {
        setOpen(null);
    };

    const functionUseEffectDisable = () => {
        senEmailtoDisableMfa(
            {},
            {
                onSuccess: () => {
                    setSnackBarMessage(t('send_email_mfa_success', tCommon), 'success');
                },
            },
        );
    };

    const bankAccountsOptions =
        bankAccounts?.items
            ?.filter(item => {
                return item.status === 'ACTIVE';
            })
            .map(item => ({
                name: item.bankName,
                id: item.id,
                description: item.description,
                currency: item.currency,
            })) || [];

    const childProps = {
        ...props,
        t,
        bankAccounts,
        formik,
        bankAccountsOptions,
        availableExtraction: data?.availableBalance.withdraw,
        SnackBar,
        functionUseEffectDisable,
        open,
        handleClose,
        handleWithdrawal: (otp: string) =>
            handleWithdrawal(payload as CreateWithdrawalRequest, customerCode as string, otp),
        isLoading,
        isLoadingWithdrawal,
        isLoadingRequestOtp,
        errorSubmit: errorSubmit || errorRequestOtp,
    };

    return <WalletWithdrawalPage {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WalletWithdrawalPageContainer.propTypes = propTypes;

export default WalletWithdrawalPageContainer;
