/* eslint-disable react-hooks/exhaustive-deps */
import { PricesContext } from 'context/prices.context';
import {
    useGetInstrumentByTicker,
    useGetInstruments,
    useGetQuotations,
} from 'hooks/api/instruments.hooks';
import { useContext, useEffect, useMemo, useState } from 'react';
import { WSTable } from 'types/context/prices.context.types';
import {
    getInstrumentDetailInfoAdapter,
    getLastTickerQuotationForHome,
    getLastTickerQuotationForTable,
} from 'utils/helpers/pricesHelper';

const getUpdatedValues = (
    prevValues: WSTable[] | undefined,
    updatedValues: WSTable[] | undefined,
): { [ticker: string]: Partial<Record<keyof WSTable, string>> } => {
    if (!prevValues || !updatedValues) {
        return {};
    }
    const prevMap = new Map<string, WSTable>(prevValues.map(item => [item.ticker, item]));
    const updates: { [ticker: string]: Partial<Record<keyof WSTable, string>> } = {};
    updatedValues.forEach(current => {
        const prev = prevMap.get(current.ticker);
        if (prev) {
            const diff: Partial<Record<keyof WSTable, string>> = {};
            Object.keys(current).forEach(key => {
                const valueKey = key as keyof WSTable;
                const currentValue = current[valueKey];
                const prevValue = prev[valueKey];
                if (currentValue !== prevValue) {
                    let diffValue: string;
                    if (currentValue === null || prevValue === null) {
                        diffValue = currentValue === null ? 'null' : currentValue.toString();
                    } else if (typeof currentValue === 'number' && typeof prevValue === 'number') {
                        diffValue = currentValue > prevValue ? '+' : '-';
                    } else {
                        diffValue = currentValue.toString();
                    }
                    diff[valueKey] = diffValue;
                }
            });
            if (Object.keys(diff).length > 0) {
                updates[current.ticker] = diff;
            }
        }
    });

    return updates;
};

export const useGetTableQuotations = ({
    termConn,
    typeInstrument = '',
    currency,
    filteredByType,
    favorite = false,
    ticker = '',
    name,
    liquidity,
    classOfFunds,
}: {
    termConn: 'CI' | '24hs';
    typeInstrument?: string;
    currency?: string;
    filteredByType?: string;
    favorite?: boolean;
    ticker?: string;
    name: string;
    liquidity: string;
    classOfFunds: string;
}) => {
    const {
        data: instruments,
        isLoading,
        totalSize,
        refetch,
    } = useGetInstruments(
        `&ticker=${ticker}&currency=${currency}&type=${typeInstrument}&name=${name}${filteredByType ? `&tags=${filteredByType}` : ''}&favoritesOnly=${favorite}&classOfFunds=${classOfFunds}`,
    );

    const {
        subscribe24hs,
        subscribeCI,
        unsubscribeCI,
        tickerPricesCI,
        unsubscribe24hs,
        tickerPrices24hs,
        isConnected24hs,
        isConnectedCI,
    } = useContext(PricesContext);

    const isConnected = termConn === 'CI' ? isConnectedCI : isConnected24hs;
    const tickerPrices = termConn === 'CI' ? tickerPricesCI : tickerPrices24hs;
    const subscribe = termConn === 'CI' ? subscribeCI : subscribe24hs;
    const unsubscribe = termConn === 'CI' ? unsubscribeCI : unsubscribe24hs;
    const [prevValues, setPrevValues] = useState<WSTable[] | undefined>();
    const [changeValues, setChangeValues] = useState<{
        [ticker: string]: Partial<Record<keyof WSTable, string>>;
    }>({});

    const tableValuesUpdated = useMemo(() => {
        if (!instruments || !tickerPrices) return undefined;
        return getLastTickerQuotationForTable(instruments, tickerPrices);
    }, [tickerPrices, instruments]);

    useEffect(() => {
        if (isLoading || !instruments || !isConnected) return;

        const subscriptionInstruments = instruments.flatMap(instrument =>
            instrument.tickers.filter(ticker => ticker.currency === currency).map(el => el.ticker),
        );

        subscribe(subscriptionInstruments);

        return () => {
            unsubscribe(subscriptionInstruments);
        };
    }, [isLoading, isConnected, termConn]);

    useEffect(() => {
        if (!tableValuesUpdated) return;
        const updateValues = getUpdatedValues(prevValues, tableValuesUpdated);
        if (Object.keys(updateValues).length > 0) setChangeValues(updateValues);
        setPrevValues(tableValuesUpdated);
    }, [tableValuesUpdated, prevValues]);

    return {
        tableData: tableValuesUpdated,
        isLoading,
        changeValues,
        totalSize: totalSize || 0,
        refetch,
    };
};

export const useGetHomeQuotations = () => {
    const { data: quotations, isLoading } = useGetQuotations();

    const {
        subscribeToPricesWS,
        unsubscribeToPricesWS,
        tickerPricesCI,
        tickerPrices24hs,
        isConnectedCI,
        isConnected24hs,
    } = useContext(PricesContext);

    const homeQuotations = useMemo(() => {
        if (!quotations || !tickerPrices24hs || !tickerPricesCI) return;
        return getLastTickerQuotationForHome(quotations, tickerPrices24hs, tickerPricesCI);
    }, [tickerPrices24hs, tickerPricesCI]);

    useEffect(() => {
        if (isLoading || !quotations || !isConnectedCI || !isConnected24hs) return;

        const subscriptionTickers = quotations.flatMap(quotation =>
            quotation.instrument.tickers.map(ticker => ticker.ticker),
        );

        subscribeToPricesWS(subscriptionTickers);

        return () => {
            unsubscribeToPricesWS(subscriptionTickers);
        };
    }, [quotations, isLoading, isConnectedCI, isConnected24hs]);

    return {
        quotations,
        isLoadingQuotations: isLoading,
        homeQuotations,
    };
};

export const useGetInstrumentDetail = (termConn: 'CI' | '24hs', ticker: string) => {
    const { instrument, rules, isLoading } = useGetInstrumentByTicker(ticker);

    const {
        subscribe24hs,
        subscribeCI,
        unsubscribeCI,
        tickerPricesCI,
        unsubscribe24hs,
        tickerPrices24hs,
        isConnected24hs,
        isConnectedCI,
    } = useContext(PricesContext);

    const isConnected = termConn === 'CI' ? isConnectedCI : isConnected24hs;
    const tickerPrices = termConn === 'CI' ? tickerPricesCI : tickerPrices24hs;
    const subscribe = termConn === 'CI' ? subscribeCI : subscribe24hs;
    const unsubscribe = termConn === 'CI' ? unsubscribeCI : unsubscribe24hs;

    const tickerPriceInfo = useMemo(() => {
        if (!instrument || !tickerPrices) return;
        return getInstrumentDetailInfoAdapter(tickerPrices);
    }, [tickerPrices, instrument]);

    useEffect(() => {
        if (!isConnected) return;
        if (ticker) {
            subscribe([ticker]);
        }
        return () => {
            unsubscribe([ticker]);
        };
    }, [ticker, isConnected, termConn]);

    const tickerInfo = useMemo(() => {
        if (!instrument) return null;
        return instrument.tickers.find(t => t.ticker === ticker) ?? null;
    }, [ticker, instrument]);

    const isBond = useMemo(() => {
        if (!instrument) return false;
        return instrument?.type === 'BOND';
    }, [instrument]);

    const currencies = useMemo(() => {
        if (!instrument) return null;
        return instrument.tickers.map((t, index) => {
            return { id: index + 1, name: t.currency };
        });
    }, [instrument]);

    return {
        tickerPriceInfo,
        instrument: instrument || null,
        rules: rules || null,
        isLoading,
        currency: tickerInfo?.currency ?? 'ARS',
        favorite: tickerInfo?.favorite ?? false,
        requiresDeclaration: tickerInfo?.requiresDeclaration ?? null,
        isBond,
        currencies,
    };
};
