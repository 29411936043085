import * as React from 'react';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import WalletInstrument from './WalletInstrument';
import { useGetAccountStatus } from 'hooks/api/marketAccount.hooks';
import { InstrumentPrice, GetAccountStatusResponse } from 'types/api/marketAccount.types';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils/helpers/commonHelper';
import {
    BondsFormatted,
    CurrencyFormatted,
    InstrumentInfo,
    InstrumentType,
    OthersInstrumentsFormatted,
    ProcessedData,
} from 'types/api/instruments.types';

const WalletInstrumentContainer = (props: Props) => {
    const { selectedInstrumentType } = props;

    const { data, isLoading } = useGetAccountStatus();
    const { t } = useTranslation('home');

    const [openInstrumentModal, setOpenInstrumentModal] = React.useState<boolean>(false);
    const [selectedInstrument, setSelectedInstrument] = React.useState<any>(null);
    const [processedData, setProcessedData] = React.useState<ProcessedData[]>([]);

    const handleInstrumentClick = (instrument: any) => {
        setSelectedInstrument(instrument);
        setOpenInstrumentModal(true);
    };

    const instrumentsAmount =
        data?.instruments.reduce((total, instrumentType) => {
            return total + (instrumentType.instruments?.length || 0);
        }, 0) || 0;

    const formatBondsDetails = (data: InstrumentInfo[]): BondsFormatted[] => {
        const newValues = data.map(bond => {
            const price = bond.price?.value;
            const valuation = bond.value;
            const profitability = bond.performance ? bond.performance.value : undefined;
            const profitabilityPercentage = bond.performance
                ? bond.performance.percentage.toFixed(2)
                : undefined;
            const ppc = bond.pppc ? bond.pppc.value : undefined;
            const ppcPercentage = bond.pppc ? bond.pppc.percentage.toFixed(2) : undefined;
            const currencyPrice = bond.price?.currency;
            const formattedPrice = formatNumber(price);

            const priceInstrument: InstrumentPrice = {
                value: formattedPrice,
                currency: currencyPrice,
            };

            return {
                description: bond.description,
                ticker: bond.ticker,
                price: priceInstrument,
                quantity: formatNumber(bond.quantity),
                available: formatNumber(bond.quantityAvailable),
                notAvailable: formatNumber(bond.quantityNotAvailable),
                holdingPercentage: bond.percentageInWallet.toFixed(2),
                valuation: formatNumber(valuation),
                profitability: profitability ? formatNumber(profitability) : undefined,
                profitabilityPercentage: profitabilityPercentage || undefined,
                ppc: ppc ? formatNumber(ppc) : undefined,
                ppcPercentage: ppcPercentage ? formatNumber(Number(ppcPercentage)) : undefined,
            };
        });

        return newValues;
    };

    const formatCurrenciesDetails = (data: InstrumentInfo[]): CurrencyFormatted[] => {
        const newValues = data.map(currency => {
            const currencyKey = currency.price?.currency.toUpperCase();

            const mappedTitle: Record<string, string> = {
                USD: t('instrument_usd'),
                ARS: t('instrument_pesos'),
                'USD 7000': t('instrument_usd_7000'),
                'USD cable': t('instrument_usd_cable'),
            };

            return {
                title: mappedTitle[currencyKey],
                currency: currencyKey,
                quantity: formatNumber(currency.quantity),
                available: formatNumber(currency.quantityAvailable),
                notAvailable: formatNumber(currency.quantityNotAvailable),
                holdingPercentage: currency.percentageInWallet.toFixed(2),
                valuation: formatNumber(currency.value),
            };
        });

        return newValues;
    };

    const formatOtherInstrumentsDetails = (
        data: InstrumentInfo[],
        instrumentType: InstrumentType,
    ): OthersInstrumentsFormatted[] => {
        const newValues = data.map(instrument => {
            const price = instrument.price?.value;
            const valuation = instrument.value;
            const profitability = instrument.performance ? instrument.performance.value : undefined;
            const profitabilityPercentage = instrument.performance
                ? instrument.performance.percentage.toFixed(2)
                : undefined;
            const ppc = instrument.pppc ? instrument.pppc.value : undefined;
            const ppcPercentage = instrument.pppc
                ? instrument.pppc.percentage.toFixed(2)
                : undefined;
            const result = instrument.returns ? instrument.returns : undefined;
            const tricker = instrument.ticker;

            const currencyPrice = instrument.price?.currency;
            const formattedPrice = formatNumber(price);

            const priceInstrument: InstrumentPrice = {
                value: formattedPrice,
                currency: currencyPrice,
            };

            return {
                description: instrument.description,
                ticker: tricker,
                price: priceInstrument,
                quantity: formatNumber(instrument.quantity),
                available: formatNumber(instrument.quantityAvailable),
                notAvailable: formatNumber(instrument.quantityNotAvailable),
                holdingPercentage: instrument.percentageInWallet.toFixed(2),
                valuation: formatNumber(valuation),
                profitability: profitability ? formatNumber(profitability) : undefined,
                profitabilityPercentage: profitabilityPercentage
                    ? formatNumber(Number(profitabilityPercentage))
                    : undefined,
                ppc: ppc ? formatNumber(ppc) : undefined,
                ppcPercentage: ppcPercentage ? formatNumber(Number(ppcPercentage)) : undefined,
                result: result ? formatNumber(result) : undefined,
            };
        });

        return newValues;
    };

    const processInstrumentsData = (
        data: GetAccountStatusResponse | undefined,
        selectedInstrumentType: string,
    ) => {
        return (
            data?.instruments
                .filter(instrument =>
                    selectedInstrumentType !== ''
                        ? instrument.instrumentType === selectedInstrumentType
                        : instrument,
                )
                .map(instrumentCategory => {
                    const instruments = instrumentCategory.instruments;

                    const infoFormatted =
                        instrumentCategory.instrumentType === 'BOND'
                            ? formatBondsDetails(instruments)
                            : instrumentCategory.instrumentType === 'CURRENCY'
                              ? formatCurrenciesDetails(instruments)
                              : formatOtherInstrumentsDetails(
                                    instruments,
                                    instrumentCategory.instrumentType,
                                );

                    return {
                        type: instrumentCategory.instrumentType,
                        quantity: instrumentCategory.instruments.length,
                        info: infoFormatted,
                    };
                }) || []
        );
    };

    React.useEffect(() => {
        setProcessedData(processInstrumentsData(data, selectedInstrumentType));
    }, [selectedInstrumentType]);

    React.useEffect(() => {
        if (!isLoading && data)
            setProcessedData(processInstrumentsData(data, selectedInstrumentType));
    }, [isLoading]);

    const childProps = {
        ...props,
        t,
        instrumentsAmount,
        openInstrumentModal,
        setOpenInstrumentModal,
        selectedInstrument,
        handleInstrumentClick,
        processedData,
    };

    return <WalletInstrument {...childProps} />;
};

const propTypes = {};

interface extraProps {
    selectedInstrumentType: string;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
WalletInstrumentContainer.propTypes = propTypes;

export default WalletInstrumentContainer;
