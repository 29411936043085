import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import CompanyModal from './CompanyModal';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { tRequiredFieldError } from 'constants/appConstants';
import React from 'react';
import { FormikConfig, FormikValues, useFormik } from 'formik';

const getInitialValues = () => ({
    adviserNum: '',
});

const getValidationSchema = () =>
    Yup.lazy(() =>
        Yup.object().shape({
            adviserNum: Yup.string().required(tRequiredFieldError),
        }),
    );

const CompanyModalContainer = (props: Props) => {
    const { t, setOpenModal } = props;

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleSubmit = React.useCallback(async (values: FormikValues) => {
        // endpoints no disponibles aun
        handleClose();
    }, []);
    const formikInitProps = React.useMemo(
        () =>
            ({
                initialValues: getInitialValues(),
                validateOnChange: false,
                validateOnBlur: false,
                validateOnMount: false,
                validationSchema: getValidationSchema(),
                enableReinitialize: true,
                onSubmit: handleSubmit,
            }) as FormikConfig<FormikValues>,
        [handleSubmit],
    );

    const formik = useFormik(formikInitProps);

    const childProps = { ...props, t, handleClose, setOpenModal, formik };

    return <CompanyModal {...childProps} />;
};

const propTypes = {
    t: PropTypes.func.isRequired,
};

interface extraProps {
    openModal: boolean;
    setOpenModal: (state: boolean) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
CompanyModalContainer.prototype = propTypes;

export default CompanyModalContainer;
