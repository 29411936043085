import * as React from 'react';
// import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import UserInfo from './UserInfo';
import { useTranslation } from 'react-i18next';
import { OnboardingContext } from 'context/onboarding.context';
import { AuthContext } from 'context/auth.context';
import { useNavigate } from 'react-router-dom';

const UserInfoContainer = (props: Props) => {
    // const {} = props;
    const { t } = useTranslation();
    const { legalData } = React.useContext(OnboardingContext);
    const { userData, userType, customerCode } = React.useContext(AuthContext);
    const navigate = useNavigate();

    const userFullName = React.useMemo(() => {
        if (userData?.onboardingPending) {
            if (!legalData) return '-';
            return `${legalData?.name} ${legalData?.lastName}`;
        }
        return `${userData?.name} ${userData?.lastName}`;
    }, [legalData, userData]);

    const childProps = {
        ...props,
        t,
        userFullName,
        isUF:
            !userData?.onboardingPending &&
            (userType === 'APP_LEGAL' || userType === 'APP_PHYSICAL'),
        customerCode,
        navigate,
    };

    return <UserInfo {...childProps} />;
};

const propTypes = {};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
UserInfoContainer.propTypes = propTypes;

export default UserInfoContainer;
