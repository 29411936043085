import * as React from 'react';
import PropTypes from 'prop-types';
import { InferPropsExtended } from 'utils/helpers/proptypesHelper';
import MainLayout from './MainLayout';
import { AuthContext } from 'context/auth.context';
import { mainRoutes } from 'router/subRoutes/mainLayout';
import { channelRoutes } from 'router/subRoutes/channelLayout';
import useScreenWidth from 'hooks/common/screenWidth';

const MainLayoutContainer = (props: Props) => {
    // const {} = props;
    const { isAuthenticated, isTermsAccepted } = React.useContext(AuthContext);
    const { isChannel } = props;
    const { isTestExpired, forceMFA } = React.useContext(AuthContext);
    const screenWidth = useScreenWidth();

    const childProps = {
        ...props,
        isAuthenticated,
        isTermsAccepted,
        routes: isChannel ? channelRoutes : mainRoutes,
        isTestExpired,
        isChannel,
        isMobile: !(screenWidth > (isChannel ? 1330 : 1050)),
        forceMFA,
    };

    return <MainLayout {...childProps} />;
};

const propTypes = {
    isChannel: PropTypes.bool,
};

interface extraProps {}

interface Props extends InferPropsExtended<typeof propTypes, extraProps> {}
MainLayoutContainer.propTypes = propTypes;

export default MainLayoutContainer;
